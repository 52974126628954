/*
 ██████╗██╗      █████╗ ███████╗███████╗
██╔════╝██║     ██╔══██╗██╔════╝██╔════╝
██║     ██║     ███████║███████╗███████╗
██║     ██║     ██╔══██║╚════██║╚════██║
╚██████╗███████╗██║  ██║███████║███████║
 ╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝ team single
*/

import vert from '~/assets/js/shader/vert/animals.vert';
import volumeLightFrag from '~/assets/js/shader/frag/volumeLight.frag';
import volumeLightVert from '~/assets/js/shader/vert/volumeLight.vert';

export class SCENE_TEAM_SINGLE {

	constructor( width, height, ratio, scale, key, rotation, position, uniform, lightGroup, rescale ){

		//
		this.rescale = rescale;

		//
		this.transform = {
			translate : {
				x : 0,
				y : 0,
				z : 0
			},
			rotation : {
				x : 0,
				y : 0,
				z : 0
			}
		}

		//
		this.anim = {
			enter : { tweens : [], count : 0, power : 0 },
			leave : { tweens : [], count : 0 }
		}

		//
		this.key = key;
		this.size = RESOURCE.animals[ this.key ].scale;

		//
		this.lightGroup = lightGroup;

		//
		this.zoom = 1;

		//
		this.rotation = rotation;
		this.position = position;

		//
		this.ratio = ratio;
		this.scale = scale;

		//
		this.width  = width;
		this.height = height;

		//
		this.ratioWidth  = BACKGL.width * this.ratio.w * this.scale.x;
		this.ratioHeight = BACKGL.width * this.ratio.h * this.scale.y;

		//
		this._RES = HI_RES;

		//
		this.fbo = new THREE.WebGLRenderTarget(
			this.ratioWidth  * this._RES,
			this.ratioHeight * this._RES,
			{ minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter }
		);

		//
		this.scene = new THREE.Scene();
		this.scene.background = COLOR_ORIGIN.black4.three
		this.scene.fog = false;

		//
		this.uniform = uniform;

		//
		this.initMesh();
		this.initLight();
		// this.initParticle();
		this.resize();

	}

	initMesh(){

		//
		const target = RESOURCE.animals[ this.key ];


		// if( !target.material ){

			// マテリアル未登録
			const material = new THREE.MeshPhysicalMaterial({
				// side               : THREE.DoubleSide,
				color              : this.uniform.color.three,
				emissive           : this.uniform.emissive.three,
				roughness          : this.uniform.roughness,
				metalness          : this.uniform.metalness,
				clearcoat          : this.uniform.clearcoat,
				clearcoatRoughness : this.uniform.clearcoatRoughness,
				reflectivity       : this.uniform.reflectivity,
				envMap             : null,
				map                : target.map
			});
			material.uniformsNeedUpdate = true;
			material.needsUpdate = true;
			material.onBeforeCompile = (shader) => {
				shader.uniforms.twistPower  = { value : this.uniform.twistPower };
				shader.uniforms.twistHeight = { value : this.uniform.twistHeight };
				shader.uniforms.twistAngle  = { value : this.uniform.twistAngle };
				shader.uniforms.wavyPower   = { value : this.uniform.wavyPower };
				shader.uniforms.wavyRadius  = { value : this.uniform.wavyRadius };
				shader.uniforms.wavyAngle   = { value : this.uniform.wavyAngle };
				shader.vertexShader = vert;
				material.userData.shader = shader;
			};
			
			// target.material = material;

		// } else {

		// 	// マテリアル登録済み
		// 	var material = target.material.clone();
		// 	material.side = THREE.DoubleSide;
		// 	material.emissive = this.uniform.emissive.three;
		// 	material.roughness = this.uniform.roughness;
		// 	material.metalness = this.uniform.metalness;
		// 	material.clearcoat = this.uniform.clearcoat;
		// 	material.clearcoatRoughness = this.uniform.clearcoatRoughness;
		// 	material.reflectivity = this.uniform.reflectivity;

		// }

		//
		this.mesh = new THREE.Mesh( target.geometry, material );
		this.scene.add( this.mesh );

	}

	initLight(){

		//===
		this.volumeLight = {
			type : 'volume',
			color: {
				three : COLOR_ORIGIN.brown.three,
			},
			position: { x: -0.5, y: 2, z: 0 },
			mesh : null,
			intensity: 1.0,
			distance: 2.5,
			decay: 1,
			volume : {
				mesh : null,
				top  : 0,
				bottom : 1.5,
				anglePower : 15
			}
		};

		//===
		// this.volumeLight.mesh = new THREE.PointLight( this.volumeLight.color.three );
		// this.volumeLight.mesh.position.x = this.volumeLight.position.x * this.width;
		// this.volumeLight.mesh.position.y = this.volumeLight.position.y * this.width;
		// this.volumeLight.mesh.position.z = this.volumeLight.position.z * this.width;
		// this.volumeLight.mesh.intensity  = 0;
		// this.volumeLight.mesh.distance   = this.volumeLight.distance * this.width;
		// this.volumeLight.mesh.decay      = this.volumeLight.decay;
		// this.lightGroup.add( this.volumeLight.mesh );

		//===
		const geometry = new THREE.CylinderBufferGeometry(
			( this.volumeLight.volume.top * this.width ) / RESCALE.r,
			( this.volumeLight.volume.bottom * this.width ) / RESCALE.r,
			( this.volumeLight.distance * this.width * 2 ) / RESCALE.r,
			256
		);
		geometry.applyMatrix4( new THREE.Matrix4().makeTranslation( 0, -geometry.parameters.height/2, 0 ) );
		geometry.applyMatrix4( new THREE.Matrix4().makeRotationX( -Math.PI / 2 ) );
		const material = new THREE.ShaderMaterial({
			uniforms: {
				attenuation  : { type: "f", value: 5.0 },
				anglePower   : { type: "f",  value: 1.2 },
				spotPosition : { type: "v3", value: new THREE.Vector3(0, 0, 0) },
				lightColor   : { type: "c", value: this.volumeLight.color.three },
				alpha        : { value: 0 },
			},
			vertexShader: volumeLightVert,
			fragmentShader: volumeLightFrag,
			transparent: true,
			depthWrite: false,
		});
		material.alphaTest = 0.5;
		this.volumeLight.volume.mesh = new THREE.Mesh( geometry, material );
		this.volumeLight.volume.mesh.visible = false;
		this.volumeLight.volume.mesh.position.x = this.volumeLight.position.x * this.width * 2 / RESCALE.r;
		this.volumeLight.volume.mesh.position.y = this.volumeLight.position.y * this.width * 2 / RESCALE.r;
		this.volumeLight.volume.mesh.position.z = this.volumeLight.position.z * this.width * 2 / RESCALE.r;
		this.volumeLight.volume.mesh.lookAt( new THREE.Vector3(0,0,0) );
		this.volumeLight.volume.mesh.material.uniforms.attenuation.value = this.volumeLight.distance * this.width * 2 / RESCALE.r;
		this.volumeLight.volume.mesh.material.uniforms.anglePower.value  = this.volumeLight.volume.anglePower;
		this.volumeLight.volume.mesh.material.uniforms.spotPosition.value.x = this.volumeLight.volume.mesh.position.x;
		this.volumeLight.volume.mesh.material.uniforms.spotPosition.value.y = this.volumeLight.volume.mesh.position.y;
		this.volumeLight.volume.mesh.material.uniforms.spotPosition.value.z = this.volumeLight.volume.mesh.position.z;
		this.volumeLight.volume.mesh.renderOrder = 1;
		this.lightGroup.add( this.volumeLight.volume.mesh );

		//===
		this.scene.add( this.lightGroup );

	}

	mouseenter( isRight, Ratio = 1 ){

		//
		const _this = this;

		//
		if( isRight ){
			this.anim.enter.count -= Ratio;
		} else {
			this.anim.enter.count += Ratio;
		}

		//
		killTweens( this.anim.leave.tweens );

		//
		if( this.volumeLight ){
			this.volumeLight.volume.mesh.visible = true;
			gsap.to( this.volumeLight.mesh, {
				duration : 1,
				intensity : 1
			});
			gsap.to( this.volumeLight.volume.mesh.material.uniforms.alpha, {
				duration : 1,
				value : 1
			});
		}

		//
		gsap.to( this.mesh.material, {
			duration : 1,
			envMapIntensity : .2
		});

		//
		gsap.to( this.anim.enter, {
			duration : 1,
			power : 1
		});

		//
		gsap.to( this.transform.rotation, {
			duration : 2,
			ease : 'power3.out',
			z : this.anim.enter.count/Ratio * 360 * ( Math.PI / 180 )
		});

	}

	mouseleave(){
		const _this = this;
		if( this.volumeLight ){
			const tl1 = gsap.to( this.volumeLight.mesh, {
				duration : 1,
				intensity : 0,
				onComplete(){
					_this.volumeLight.volume.mesh.visible = false;
				}
			});
			this.anim.leave.tweens.push(tl1);
			const tl2 = gsap.to( this.volumeLight.volume.mesh.material.uniforms.alpha, {
				duration : 1,
				value : 0
			});
			this.anim.leave.tweens.push(tl2);
		}	

		const tl3 = gsap.to( this.mesh.material, {
			duration : 1,
			envMapIntensity : 0
		});
		this.anim.leave.tweens.push(tl3);

		const tl4 = gsap.to( this.anim.enter, {
			duration : 1,
			power : 0
		});
		this.anim.leave.tweens.push(tl4);

	}

	initEnvMap(){
		if( !this.mesh.material.envMap ){
			this.mesh.material.envMap = RESOURCE.envMaps[ this.uniform.envMap ].tex;
			this.mesh.material.envMapIntensity = 0;
		}
	}

	resize( width, height ){

		//
		this.width  = width;
		this.height = height;

		//
		this.ratioWidth  = BACKGL.width * this.ratio.w * this.scale.x;
		this.ratioHeight = BACKGL.width * this.ratio.h * this.scale.y;

		// //
		// for (let i = 0; i < this.lightGroup.children.length; i++) {
		// 	const v = this.lightGroup.children[i];
		// 	switch( v.type ){
		// 		case 'PointLight':
		// 			console.log( v );
		// 			v.position.x = v.position.x * this.width / RESCALE.r;
		// 			v.position.y = v.position.y * this.width / RESCALE.r;
		// 			v.position.z = v.position.z * this.width / RESCALE.r;
		// 		break;
		// 	}
		// }

	}

	render(){

		//
		// this.width  = BACKGL.width * this.ratio.w;
		// this.height = BACKGL.width * this.ratio.h;
		// this.ratioWidth  = BACKGL.width * this.ratio.w * this.scale.x;
		// this.ratioHeight = BACKGL.width * this.ratio.h * this.scale.y;

		//
		this.mesh.position.x = this.position.x + this.transform.translate.x / RESCALE.r;
		this.mesh.position.y = this.position.y + this.transform.translate.y / RESCALE.r;
		this.mesh.position.z = this.position.z + this.transform.translate.z / RESCALE.r;

		//
		this.mesh.rotation.x = this.rotation.x * ( Math.PI / 180 ) + this.transform.rotation.x;
		this.mesh.rotation.y = this.rotation.y * ( Math.PI / 180 ) + this.transform.rotation.y;
		this.mesh.rotation.z = this.rotation.z * ( Math.PI / 180 ) + this.transform.rotation.z;

		//
		this.mesh.scale.x = this.size * 1/(this.rescale.x*2) * this.zoom;
		this.mesh.scale.y = this.size * 1/(this.rescale.y*2) * this.zoom;
		this.mesh.scale.z = this.size * 1/(this.rescale.z*2) * this.zoom;

		// //
		// if( this.particle ){
		// 	this.updateParticle();
		// }

		//
		const shader = this.mesh.material.userData.shader;
		if( shader ){
			shader.uniforms.wavyAngle.value = -BACKGL.rad.fast.val;
		}

	}

	// updateParticle(){

	// 	//
	// 	// this.particle.mesh.rotation.x = this.rotation.x * ( Math.PI / 180 ) + this.transform.rotation.x;
	// 	// this.particle.mesh.rotation.y = this.rotation.y * ( Math.PI / 180 ) + this.transform.rotation.y;
	// 	// this.particle.mesh.rotation.z = this.rotation.z * ( Math.PI / 180 ) + this.transform.rotation.z;

	// 	//
	// 	const p = this.particle.mesh.geometry.attributes.position.array;
	// 	const v = this.particle.attr.vector;
	// 	this.particle.mesh.geometry.attributes.position.needsUpdate = true;

	// 	for (let i = 0; i < p.length; i += 3 ) {

	// 		//=====================
	// 		// position Y
	// 		//=====================
	// 		if( p[i] < -this.width/2 - this.particle.padding.x ){
	// 			p[i] = this.width/2 + this.particle.padding.x;
	// 		} else if( p[i] > this.width/2 + this.particle.padding.x ){
	// 			p[i] = -this.width/2 - this.particle.padding.x ;
	// 		} else {
	// 			p[i] += v[i];
	// 		}

	// 		//=====================
	// 		// position Y
	// 		//=====================
	// 		if( p[i+1] < -this.height/2 - this.particle.padding.y ){
	// 			p[i+1] = this.height/2 + this.particle.padding.y;
	// 		} else if( p[i+1] > this.height/2 + this.particle.padding.y ){
	// 			p[i+1] = -this.height/2 - this.particle.padding.y;
	// 		} else {
	// 			p[i+1] -= v[i+1];
	// 		}
	// 	}

	// 	this.particle.mesh.material.uniforms.time.value += 0.01;

	// }

	inview( visible ){

		this.scene.visible = visible;
		this.mesh.visible = visible;
		this.lightGroup.visible = visible;

	}


}





























