/*
██████╗  █████╗  ██████╗██╗  ██╗ ██████╗ ██╗     
██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔════╝ ██║     
██████╔╝███████║██║     █████╔╝ ██║  ███╗██║     
██╔══██╗██╔══██║██║     ██╔═██╗ ██║   ██║██║     
██████╔╝██║  ██║╚██████╗██║  ██╗╚██████╔╝███████╗
╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝ archive
*/


import archiveThumbFrag from '~/assets/js/shader/frag/archiveThumb.frag';
BACKGL.scenes.dom.onInitArchive = function( dom ){

	//
	const geometry = new THREE.PlaneBufferGeometry( 1, 1 );
	const material = new THREE.ShaderMaterial({
		vertexShader : `
			varying vec2 vUv;
			void main(){
				vUv = uv;
				gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
			}
		`,
		fragmentShader : archiveThumbFrag,
		// fragmentShader : `
		// 	uniform sampler2D tDiffuse;
		// 	varying vec2 vUv;
		// 	void main() {
		// 		vec4 _tDiffuse = texture2D( tDiffuse, vUv );
		// 		gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
		// 	}
		// `,
		uniforms: {
			scale : { value: { x: 1.3, y: 1.3 } },
			offset : { value: { x: 0, y: 0 } },
			borderRadius: { value : 0.125/2 },
			resolution: { value: { x: 0, y: 0 } },
			tDiffuse : { value: null },
			tPoster : { value: null },
			tTone : { value: COLOR_ORIGIN.lightBrown.three },
			alpha: { value : .9 },
			tone : { value : .8 },
			mixPreload : { value : 0 },
			mixRatio : { value : 0 }
		},
		transparent: true,
	});
	material.alphaTest = 0.5;
	dom.mesh = new THREE.Mesh( geometry, material );
	dom.mesh.origin = {
		uniforms : {
			scale : { value: { x: 1.3, y: 1.3 } }
		}
	};
	BACKGL.sceneFrontSide.add( dom.mesh );

}


//
BACKGL.scenes.dom.onReszieArchive = function( dom ){
	if( dom.mesh ){
		dom.mesh.scale.x = dom.rect.width;
		dom.mesh.scale.y = dom.rect.height;
		dom.mesh.material.uniforms.resolution.value.x = dom.mesh.scale.x;
		dom.mesh.material.uniforms.resolution.value.y = dom.mesh.scale.y;
		dom.mesh.material.uniforms.borderRadius.value = __WW__ / dom.mesh.scale.x * BORDER_RADIUS;
	}
}




//
BACKGL.scenes.dom.onUpdateArchive = function( uniqueId, x, y, visible ){

	for (let i = 0; i < BACKGL.domElements.length; i++) {

		const dom = BACKGL.domElements[i];

		if( dom.uniqueId === uniqueId && dom.mesh && dom.mesh.visible ){

			//
			const tx = ( -__WW__/2 + dom.rect.width/2 );
			const ty = (  __WH__/2 - dom.rect.height/2 );
			dom.mesh.position.x =  x + tx;
			dom.mesh.position.y = -y + ty;
			// dom.mesh.material.uniforms.offset.value.x = dom.mesh.position.x * 1/window.innerWidth  * 1 * styleArchiveScroll.zoom.scale;
			// dom.mesh.material.uniforms.offset.value.y = dom.mesh.position.y * 1/window.innerHeight * 1 * styleArchiveScroll.zoom.scale;

			//
			if( !IS_REDUCED ){
				const cx = dom.mesh.position.x/((window.innerWidth+dom.mesh.scale.x)/2);
				const cy = dom.mesh.position.y/((window.innerHeight+dom.mesh.scale.y)/2);
				if( DETECT.device.any ){
					dom.mesh.material.uniforms.offset.value.x = cx * -0.75 * (1-dom.mesh.material.uniforms.scale.value.x);
					dom.mesh.material.uniforms.offset.value.y = cy * -0.75 * (1-dom.mesh.material.uniforms.scale.value.y);
				} else {
					dom.mesh.material.uniforms.offset.value.x = cx * -1 * (1-dom.mesh.material.uniforms.scale.value.x);
					dom.mesh.material.uniforms.offset.value.y = cy * -1 * (1-dom.mesh.material.uniforms.scale.value.y);
				}
			}

			// //
			// if( uniqueId === 13 ){
			// 	// console.log( dom.mesh.position.x * 1/window.innerWidth * 2 );
			// }
		
			//
			// if( !DETECT.device.any ){
			// 	if( styleArchiveScroll.selected === uniqueId ){
			// 		gsap.to( dom.mesh.material.uniforms.alpha, {
			// 			duration : 1,
			// 			value : 1			
			// 		});
			// 		gsap.to( dom.mesh.material.uniforms.tone, {
			// 			duration : 1,
			// 			value : 1
			// 		});
			// 	} else {
			// 		gsap.to( dom.mesh.material.uniforms.alpha, {
			// 			duration : 1,
			// 			value : 1
			// 		});
			// 		gsap.to( dom.mesh.material.uniforms.tone, {
			// 			duration : 1,
			// 			value : 0.4
			// 		});
			// 	}
			// }

		}

	}

}



//
BACKGL.scenes.dom.onInviewArchive = function( dom ){

	dom.mesh.visible = dom.visible;

	//================================================
	// 画面内にあり、かつまだTextureが用意されていない場合
	//================================================
	if( dom.mesh && dom.mesh.visible && !dom.poster.tex && !dom.tex && !dom.loader ){
		onLoadDomGLTexture( dom, CDN + PATH.archive );
	}

	//
	if( dom.video.el ){
		if( dom.mesh.visible ){
			onPlayVideo( dom.video.el );
		} else {
			dom.video.el.pause();
		}
	}


}

















