export const history = state => state.history;
export const page = state => state.page;
export const archive = state => state.archive;
export const archive_origin_len = state => state.archive_origin_len;
export const projects = state => state.projects;
export const project_selected_slug = state => state.project_selected_slug;
// export const profile = state => state.profile;
export const ui = state => state.ui;
export const team = state => state.team;
export const awards = state => state.awards;
export const time = state => state.time;