// import Vue from 'vue';
// import api from "~/api/index";

// export const getProjects = ({ commit, state }, options ) => {
// 	return new Promise((resolve, reject) => {
// 		api.getProjects(options).then(
// 			response => {
// 				commit("UPDATE_PROJECTS", response );
// 				resolve(response);
// 			},
// 			response => {
// 				reject(response);
// 			}
// 		);
// 	});
// };


export const setProjects = ({ commit, state }, response ) => {

	for (let i = 0; i < response.length; i++) {
		const v = response[i];
		v.index = (i+1);
		let words = []
		for (let n = 0; n < v.titles.length; n++) {
			const line = Array(v.titles[n]);
			for (let l = 0; l < line.length; l++) {
				const w = line[l].split(" ");
				words.push( w );
			}
		}
		v.titles = words;
	}

	commit("UPDATE_PROJECTS", response );

};


// export const getArchive = ({ commit, state }, options ) => {
// 	return new Promise((resolve, reject) => {
// 		api.getArchive(options).then(
// 			response => {
// 				commit("UPDATE_ARCHIVE", response );
// 				resolve(response);
// 			},
// 			response => {
// 				reject(response);
// 			}
// 		);
// 	});
// };