/*
██████╗  █████╗  ██████╗██╗  ██╗ ██████╗ ██╗
██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔════╝ ██║
██████╔╝███████║██║     █████╔╝ ██║  ███╗██║
██╔══██╗██╔══██║██║     ██╔═██╗ ██║   ██║██║
██████╔╝██║  ██║╚██████╗██║  ██╗╚██████╔╝███████╗
╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝ ground
*/


import { SCENE_GROUND } from '/assets/js/webgl/class/c-ground.js';
import ground2D from '~/assets/js/shader/frag/ground2D.frag';

BACKGL.scenes.gound = {

	visible : false,
	ready : false,

	setZIndex( visible ){

		if( visible ){
			this.mesh.position.z =  10;
		} else {
			this.mesh.position.z = -10;
		}

	},

	// uniforms : {
	// 	blurPower : {
	// 		x : 5,
	// 		y : 5,
	// 	},
	// 	stepPower : 0.48,
	// 	fogColor  : '#ebafcc',
	// },

	onLoaded(){

		this.world = new SCENE_GROUND( false, true );
		const geometry = new THREE.PlaneBufferGeometry(1, 1, 1);
		const material = new THREE.ShaderMaterial({
			vertexShader   : `
				varying vec2 vUv;
				void main(){
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
				}
			`,
			fragmentShader : ground2D,
			uniforms: {
				borderRadius: { value : 0.05 },
				LT: { value : 1 },
				RT: { value : 1 },
				RB: { value : 0 },
				LB: { value : 0 },
				resolution: { value: { x: 0, y: 0 } },
				scale: { value: { x: 1, y: 1 } },
				tColor : { value: COLOR_ORIGIN.black.three },
				tDiffuse : { value: this.world.fbo.texture },

				// //
				// blurPower  : { value: this.uniforms.blurPower },
				// fogColor   : { value: new THREE.Color( this.uniforms.fogColor ) },
				// stepPower  : { value: this.uniforms.stepPower }

			},
			transparent: true,
		});
		material.alphaTest = 0.5;
		// material.blending = THREE.CustomBlending;
		// material.blendSrc = THREE.OneFactor;
		// material.blendDst = THREE.OneMinusSrcAlphaFactor;
		// material.blendEquation = THREE.AddEquation;

		//
		this.mesh = new THREE.Mesh(geometry, material);
		switch( ROUTE.current.type ){
			case 'single':
			case 'notfound':
				this.mesh.visible = false;		
			break;
			default:
				this.mesh.visible = true;
			break;
		}

		this.mesh.position.z = 0;
		BACKGL.sceneFrontSide.add( this.mesh );

		//
		this.onResize();
		this.ready = true;

	},

	// onInitGui(){

	// 	const _this = this;

	// 	this.folder = pane.addFolder({
	// 		title: 'Ground',
	// 	});

	// 	this.folder.addInput( this.uniforms.blurPower, 'x', {
	// 		min: 0.0,
	// 		max: 10.0,
	// 	});
	// 	this.folder.addInput( this.uniforms.blurPower, 'y', {
	// 		min: 0.0,
	// 		max: 10.0,
	// 	});

	// 	this.folder.addInput( this.uniforms, 'stepPower', {
	// 		min: 0.000,
	// 		max: 1.000,
	// 	});

	// 	this.folder.addInput( this.uniforms, 'fogColor', {
	// 		view: 'color'
	// 	});

	// 	this.folder.on('change', function(ev) {

	// 		_this.mesh.material.uniforms.blurPower.value.x = _this.uniforms.blurPower.x;
	// 		_this.mesh.material.uniforms.blurPower.value.y = _this.uniforms.blurPower.y;
	// 		_this.mesh.material.uniforms.stepPower.value = _this.uniforms.stepPower;

	// 		//
	// 		const fogColor = new THREE.Color( _this.uniforms.fogColor );
	// 		_this.mesh.material.uniforms.fogColor.value = fogColor;

	// 	});

	// },

	onResize(){

		//
		this.mesh.scale.x = window.innerWidth;
		this.mesh.scale.y = window.innerHeight;
		this.mesh.material.uniforms.resolution.value.x = window.innerWidth;
		this.mesh.material.uniforms.resolution.value.y = window.innerHeight;
		this.mesh.material.uniforms.borderRadius.value = __WW__/this.mesh.scale.x * BORDER_RADIUS;
		this.world.resize();

	},

	onIniview( dom, visibleAny ){
		if( dom ){

			//
			this.visible = visibleAny;
			this.mesh.visible = visibleAny;
			this.mesh.material.visible = visibleAny;
			this.world.inview( dom.visible, dom.name, visibleAny );

			//
			if( dom.visible ){
				this.world.useSetting( dom.name );
			}

			//
			this.setZIndex( visibleAny );

		}
	},

	currentElement : {
		rect : { top:0, left:0 },
	},

	onReset(){
		this.visible = false;
		this.mesh.visible = false;
		if( this.world ) this.world.reset();
	},

	// onMouseEnter( name ){
	// 	if( this.world && !DETECT.device.any ) this.world.mouseenter( name );
	// },
	// onMouseLeave( name ){
	// 	if( this.world && !DETECT.device.any ) this.world.mouseleave( name );
	// },

	onRender(){

		BACKGL.renderer.bg.render.setRenderTarget( this.world.fbo );
		BACKGL.renderer.bg.render.render( this.world.scene, this.world.camera );

	},

	onScroll(){

		this.mesh.position.y = -this.currentElement.rect.top + stylePageScroll.body.y;
		this.world.render( this.mesh.position.y/__WH__ );

	}

}










