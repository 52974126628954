

import { mapState, mapGetters } from 'vuex';
const environment = process.env.NODE_ENV || 'development';
const envSet = require(`~/api/env.${environment}.js`)

export default {

	name: 'default',

	head() {
		let _title = 'Garden Eight';
		let _description = this.$t.bind(this)("home.description");
		return {
			htmlAttrs: {
				lang: this.$i18n.locale
			},
			meta: [
				{ hid: 'og:title', property: 'og:title', content: _title },
				{ hid: 'og:description', property: 'og:description', content: _description },
				{ hid: 'twitter:title', name: 'twitter:title', content: _title },
				{ hid: 'twitter:description', name: 'twitter:description', content: _description }
			]
		};
	},

	data(){
		return {
			page : this.onRouted( this.$route, this.$route ),
		}
	},

	watch : {
	    $route(to, from) {
	    	this.page = this.onRouted(to,from);
			this.$store.commit( 'UPDATE_THUMB', 'none' );
			this.$store.commit( 'UPDATE_CURSOR', 'none' );
	    }
	},

	computed: {
		...mapGetters([
			'ui',
			'projects',
			'project_selected_slug',
			'archive',
			'archive_origin_len'
		]),
	},

	methods :{

		getType( name ){

			if( !name ){

				return 'notfound';

			} else {

				let ja     = name.replace(/___ja/g, '');
				let rename = ja.replace(/___en/g, '');

				switch( rename ){
					case 'index':
						return 'home';
					break;
					case 'home':
						return 'home';
					break;
					case 'about':
						return 'about';
					break;
					case 'cases':
						return 'cases';
					break;
					case 'archives':
						return 'archives';
					break;
					case 'contact':
						return 'contact';
					break;
					case 'cases-slug':
						return 'single';
					break;
					case '404':
						return 'notfound';
					break;
					default:
						return 'single';
					break;
				}

			}

		},

		onRouted(to,from){
			return {
				current : {
					slug : to.params.slug,
					name : this.getType(to.name)
				},
				prev :{
					slug : from.params.slug,
					name : this.getType(from.name)
				} 
			}
		}

	},

	mounted(){

		// window.onload = ()=>{
		// 	console.log('[load - 1]');
		// }

		//===
		this.$nextTick(() => {

			console.log('[nextTick]');

			//
			document.documentElement.style.setProperty( '--vh', window.innerHeight/100 + 'px');

			//
			const _this = this;

			//
			window.CDN = envSet.cdnUrl;

			//
			_this.onInitTimeUpdate();

			//
			window.__WT__  = 0;
			window.__WH__  = window.innerHeight;
			window.__WW__  = window.innerWidth;
			window.__SW__  = window.screen.width > window.outerWidth ? window.screen.width : window.outerWidth;
			window.__SH__  = window.screen.height > window.outerHeight ? window.screen.height : window.outerHeight;

			//
			window.$app  = document.querySelector('#__app');
			window.$html = document.querySelector('html');
			window.$body = document.querySelector('body');
			window.$splash   = document.querySelectorAll('.js-sp');
			window.$window = document.querySelector('.Sw');


			//=============================================================================
			// ██████╗ ███████╗████████╗███████╗ ██████╗████████╗
			// ██╔══██╗██╔════╝╚══██╔══╝██╔════╝██╔════╝╚══██╔══╝
			// ██║  ██║█████╗     ██║   █████╗  ██║        ██║   
			// ██║  ██║██╔══╝     ██║   ██╔══╝  ██║        ██║   
			// ██████╔╝███████╗   ██║   ███████╗╚██████╗   ██║   
			// ╚═════╝ ╚══════╝   ╚═╝   ╚══════╝ ╚═════╝   ╚═╝   
			//=============================================================================
			window.DETECT = {

				spec : {
					str   : null,
					score : 0,
				},

				webp    : false,
				touch   : false,
				retina  : false,
				webgl   : true,
				browser : null,
				legacy  : false,
				lowspec : false,

				os : {
					win     : _this.$device.isWindows,
					mac     : _this.$device.isMacOS,
					android : _this.$device.isAndroid,
					ios     : _this.$device.isIos,
					unknown : false,
				},

				device : {
					mobile  : false,
					tablet  : false,
					ipadpro : false,
					desktop : true,
					laptop  : false,
					any     : false, // tablet or mobile
				},

				ua     : window.navigator.userAgent.toLowerCase(),
				ua_ver : window.navigator.appVersion.toLowerCase(),

				once(){

					//
					if (window.ontouchstart === null) {
						this.touch = true;
					}

					//
					if( window.devicePixelRatio > 1.5 ){
						this.retina = true;
					}

					//
					if ( window.navigator.platform.indexOf("Win") != -1 ) {
						this.os.win = true;
						$html.classList.add('is-win');
					}

					//
					this.canvas = document.createElement("canvas");

					//
					this.detectSpec();
					this.detectDevide();
					this.detectBrows();
					// this.detectWebGL();
					this.detectWebp();

				},

				getUA(str) {
					return window.navigator.userAgent.toLowerCase().indexOf(str) != -1;
				},

				detectDevide(){

					if( _this.$device.isMobile || __WW__ < 640 ){

						this.device.any = true;
						this.device.mobile = true;
						this.device.tablet = false;
						this.device.desktop = false;
						$html.classList.add('is-mobile','is-any');

					} else if( _this.$device.isTablet || __WW__ < 1024 || ( !this.getUA('ipad') && ( this.getUA('macintosh') && 'ontouchend' in document )) ){

						this.device.any = true;
						this.device.mobile = false;
						this.device.tablet = true;
						this.device.desktop = false;
						$html.classList.add('is-tablet','is-any');
						if( __WW__ > 1024 ){
							this.device.ipadpro = true;
							$html.classList.add('is-large-tablet');
						}

					} else {

						this.device.any = false;
						this.device.mobile = false;
						this.device.tablet = false;
						this.device.desktop = true;
						$html.classList.add('is-desktop');
						if( __WW__ <= 1366 ){
							this.laptop = true;
						}

					}

				},

				// detectWebGL() {
				// 	var gl = this.canvas.getContext("webgl") || this.canvas.getContext("experimental-webgl");
				// 	if( gl && gl instanceof WebGLRenderingContext) {
				// 	this.webgl = true;
				// 	} else {
				// 	this.webgl = false;
				// 	}
				// },

				detectSpec(){

					this.core = window.navigator.hardwareConcurrency ? window.navigator.hardwareConcurrency : 4;

					if( this.retina ){

						// Laptop
						this.spec.str = 'Laptop';
						this.spec.score = 7;

						// Laptop HiEnd Corei7
						if( __SW__ <= 1680 && this.core >= 8 ){
							this.spec.str  = 'Laptop HiEnd, Corei7 or higher';
							this.spec.score = 8;
						}

						if( ( ( __WW__ === __SW__ ) && __SW__ <= 1680 ) && this.core >= 6 ){
							this.spec.str  = 'Laptop MidEnd, 15 Fullscreen, Corei5 or higher';
							this.spec.score = 7;
						}

						if( ( __SW__ <= 1440 ) && this.core >= 8 ){
							this.spec.str  = 'Laptop MidEnd, 13 Fullscreen, Corei7 or higher';
							this.spec.score = 8;
						}

						// Laptop HiEnd
						if( this.core >= 10 ){
							this.spec.str  = 'Laptop HiEnd';
							this.spec.score = 8;
						}

						// Desktop Corei3
						if( __SW__ > 1680 && this.core >= 4 ){
							this.spec.str  = 'Desktop MidEnd, Corei5 or higher';
							this.spec.score = 8;
						}

						// Desktop Corei5
						if( __SW__ > 1680 && this.core >= 6 ){
							this.spec.str  = 'Desktop MidEnd, Corei5 or higher';
							this.spec.score = 9;
						}

						// Desktop Corei7
						if( __SW__ > 1680 && this.core >= 8 ){
							this.spec.str  = 'Desktop HiEnd, Corei7 or higher';
							this.spec.score = 10;
						}

					} else {

						// 1x
						this.spec.str = 'Other';
						this.spec.score = 9;

					}

					//
					if( this.device.phone ){
						this.spec.str = 'Mobile';
					} else if( this.device.tablet ){
						this.spec.str = 'Tablet';
					}

					console.table( this.spec );

					$html.dataset.score = this.spec.score;

				},

				detectBrows(){

					if (this.ua.indexOf('edge') !== -1){
						this.browser = "edge";
						this.legacy  = true;
					} else if (this.ua.indexOf("iemobile") !== -1){
						this.browser = "other";
						this.legacy  = true;
						this.lowspec = true;
					} else if (this.ua.indexOf('trident/7') !== -1){
						this.browser = "other";
						this.legacy  = true;
						this.lowspec = true;
					} else if (this.ua.indexOf("msie") !== -1 && this.ua.indexOf('opera') === -1) {
						this.browser = "msie";
						this.legacy  = true;
						this.lowspec = true;
					} else if (this.ua.indexOf('chrome')  !== -1 && this.ua.indexOf('edge') === -1){
						this.browser = "chrome";
					}else if (this.ua.indexOf('safari')  !== -1 && this.ua.indexOf('chrome') === -1){
						this.browser = "safari";
					}else if (this.ua.indexOf('opera')   !== -1){
						this.browser = "opera";
					}else if (this.ua.indexOf('firefox') !== -1){
						this.browser = "firefox";
					}else if (this.ua.indexOf(' UCBrowser/') >= 0 ){
						this.browser = "other";
					}else{
						this.browser = "other";
					}

					//
					if( !this.device.any ){
						$html.dataset.brows = this.browser;
					}

				},

				detectWebp(){
					if( this.canvas.toDataURL('image/webp').indexOf('data:image/webp') == 0 ){
						this.webp = true;
					} else {
						this.webp = false;
					}
				},

			}

			//
			// window.IS_LOW_SPEC = false;
			// window.IS_LOW_RES  = false;
			window.IS_LOW_SPEC = false;
			window.IS_SUPER_LOW_SPEC = false;


			//=====
			if( __WW__ === 0 ){ location.reload(); } else { DETECT.once(); }

			//=====
			let maxContentsPixelRatio = 2;
			let maxHiPixelRatio       = 2;
			let maxMidPixelRatio      = 2;
			window.CONTENTS_RES = Math.min( window.devicePixelRatio, maxContentsPixelRatio );
			window.HI_RES       = Math.min( window.devicePixelRatio, maxHiPixelRatio );
			window.MID_RES      = Math.min( window.devicePixelRatio, maxMidPixelRatio );
			if( DETECT.device.mobile ){

				maxContentsPixelRatio = 1.75;
				maxHiPixelRatio       = 1.75;
				maxMidPixelRatio      = 1.75;

			} else if( DETECT.device.tablet ){

				maxContentsPixelRatio = 1.5;
				maxHiPixelRatio       = 1.5;
				maxMidPixelRatio      = 1.5;

			} else {

				if( window.devicePixelRatio > 1.5 ){

					if( DETECT.spec.score >= 9 ){

						if( __WW__ >= 2560 ){
							maxContentsPixelRatio = 1.25;
							maxHiPixelRatio       = 1.25;
							maxMidPixelRatio      = 1.25;
						} else if( __WW__ >= 1920 ){
							maxContentsPixelRatio = 1.5;
							maxHiPixelRatio       = 1.5;
							maxMidPixelRatio      = 1.5;
						} else if( __WW__ > 1680 ){
							maxContentsPixelRatio = 1.5;
							maxHiPixelRatio       = 1.5;
							maxMidPixelRatio      = 1.5;
						} else {
							maxContentsPixelRatio = 1.5;
							maxHiPixelRatio       = 1.5;
							maxMidPixelRatio      = 1.5;
						}

					} else if( DETECT.spec.score === 8 ){

						maxContentsPixelRatio = 1.5;
						maxHiPixelRatio       = 1.5;
						maxMidPixelRatio      = 1.5;
						IS_LOW_SPEC = true;

					} else if( DETECT.spec.score <= 7 ){

						// maxContentsPixelRatio = 1;
						// maxHiPixelRatio       = 1;
						// maxMidPixelRatio      = 1;
						if( __WW__ >= 1440 ){
							maxContentsPixelRatio = 1;
							maxHiPixelRatio       = 1;
							maxMidPixelRatio      = 1;
						} else {
							maxContentsPixelRatio = 1.25;
							maxHiPixelRatio       = 1.25;
							maxMidPixelRatio      = 1.25;
						}
						IS_LOW_SPEC = true;

					}

					CONTENTS_RES = Math.min( window.devicePixelRatio, maxContentsPixelRatio );
					HI_RES       = Math.min( window.devicePixelRatio, maxHiPixelRatio );
					MID_RES      = Math.min( window.devicePixelRatio, maxMidPixelRatio );

				} else {

					if( DETECT.spec.score >= 9 ){
						maxContentsPixelRatio = 1;
						maxHiPixelRatio       = 1.5;
						maxMidPixelRatio      = 1.5;
					} else if( DETECT.spec.score === 8 ){
						maxContentsPixelRatio = 1;
						maxHiPixelRatio       = 1.5;
						maxMidPixelRatio      = 1.5;
					} else if( DETECT.spec.score <= 7 ){
						maxContentsPixelRatio = 1;
						maxHiPixelRatio       = 1;
						maxMidPixelRatio      = 1;
						IS_LOW_SPEC = true;
					}

					CONTENTS_RES = Math.min( window.devicePixelRatio, maxContentsPixelRatio );
					HI_RES       = Math.min( window.devicePixelRatio, maxHiPixelRatio );
					MID_RES      = Math.min( window.devicePixelRatio, maxMidPixelRatio );

				}

			}

			console.log( CONTENTS_RES, HI_RES, MID_RES );

			//=====
			if( !DETECT.device.any ){
				$window = document.querySelector('.Sw');
			} else {
				$window = document.querySelector('#__app');
			}

			//=====
			window.IS_REDUCED = false;
			const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
			if( !mediaQuery || mediaQuery.matches || getParam('motion') === 'reduced' ) {
				IS_REDUCED = true;
			}
			// IS_REDUCED = true;

			//=====
			if( IS_LOW_SPEC ) $html.classList.add('is-low');
			if( IS_REDUCED ){
				$html.classList.add('is-reduced');
			} else {
				$html.classList.add('is-normal');				
			}

			//=====
			once();

		});


	}
}

