import Vue from 'vue';
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);


const mouseHoverWait = 200;
let casesEnterTimer = null;
let casesLeaveTimer = null;
let casesWrapEnterTimer = null;
let casesWrapLeaveTimer = null;

Vue.mixin({
	methods: {

		onToggleMenu(){
			styleMenu.onToggle();
		},

		//========================
		// TIMEUPDATE
		//========================
		onTimeUpdate( type, val ){
			const deg = type === 'sec' ? Number(val)/60*360 : ( type === 'hour' ? Number(val)/24*360 : Number(val)/60*360 );
			return {
				transform : 'rotate('+ deg +'deg)',
			}
		},
		onInitTimeUpdate(){
			const _this = this;
			setInterval(() => {
				let tz_tokyo = dayjs().tz('Asia/Tokyo');
				let tz_copen = dayjs().tz('Europe/Copenhagen');
				this.$store.commit( 'UPDATE_TIME', {
					tokyo : {
						h : tz_tokyo.$H,
						m : tz_tokyo.$m,
						s : tz_tokyo.$s
					},
					copen : {
						h : tz_copen.$H,
						m : tz_copen.$m,
						s : tz_copen.$s
					},
				});
			},1000);
		},

		//========================
		// CLICK
		//========================
		onMailTo( name ){
			const mail = name + '@garden-eight.com';
			location.href = 'mailto:' + mail;
		},
		onMailToClipBoard( e ){
			e.preventDefault();
			const el = e.target;
			if( el.dataset.name && el.dataset.domain ){
				const name = el.dataset.name;
				const domain = el.dataset.domain;
				const mail = name + '@' + domain;
				this.$copyText( mail );
				el.classList.add('copied');
				setTimeout(function(){
					el.classList.remove('copied');
				},2000);
			}
		},


		// //========================
		// // SLIDER
		// //========================
		// onPartySliderTo( e ){
		// 	const el = e.target;
		// 	if( el.dataset.to ){
		// 		const index = Number( el.dataset.to );
		// 		if( BACKGL ){
		// 			if( BACKGL.scenes.party ){
		// 				BACKGL.scenes.party.onSlideTo( index );
		// 			}
		// 		}
		// 	}
		// },

		//========================
		// TEAM
		//========================
		onMouseEnterTeam( e, name, item ){
			if( BACKGL.scenes && e.target ){
				const rect = e.target.getBoundingClientRect();
				const isRight = (rect.left + rect.width/2) < e.clientX;
				// this.$store.commit( 'UPDATE_TEAM_COUNT', { name : name, isRight : isRight });
				// var count = 0;
				// for (let i = 0; i < this.$store.getters.team.length; i++) {
				// 	if( this.$store.getters.team[i].animal === name ){
				// 		count = this.$store.getters.team[i].count;
				// 	}
				// }
				BACKGL.scenes.teamSingle.onMouseEnter( e, name, isRight );
			}
		},
		onMouseMoveTeam( e, name ){
			if( BACKGL.scenes ){
				BACKGL.scenes.teamSingle.onMouseMove( e, name );
			}
		},
		onMouseLeaveTeam( e, name ){
			if( BACKGL.scenes ){
				BACKGL.scenes.teamSingle.onMouseLeave( e, name );
			}
		},


		//========================
		// GROUND
		//========================
		onMouseEnterGround(name){
			// if( BACKGL.scenes ){
				// BACKGL.scenes.gound.onMouseEnter( name );
				this.onMouseEnterCursor( 'view' );
			// }
		},
		onMouseLeaveGround(name){
			// if( BACKGL.scenes ){
				// BACKGL.scenes.gound.onMouseLeave( name );
				this.onMouseLeaveCursor( 'view' );
			// }
		},


		//========================
		// ROUTE
		//========================
		onStaicRouteTo(e){
			const el = e.target;
			const path = el.getAttribute('href');
			const to = el.dataset.to;
			if( path ){
				ROUTE.onStaicRouteTo( path, this.$router, to );
			}
		},


		//========================
		// CASES
		//========================
		// onStaicRouteToCase(e){
		// 	// e.preventDefault();
		// 	// const path = e.target.getAttribute('href');
		// 	// console.log( path, ACTION.any );
		// 	// if( path && ACTION.any ){
		// 	// 	ROUTE.onStaicRouteToCase( path, this.$router );
		// 	// }
		// },
		onMouseDownCases(e,project){
			this.$store.commit( 'UPDATE_CASES_MOUSEDOWN', project );
		},
		onMouseUpCases(e,project){
			const path = e.target.getAttribute('href');
			this.$store.commit( 'UPDATE_CASES_MOUSEUP', project );
			if( project.action.mouseup - project.action.mousedown < 150 ){
				if( path ){
					this.$store.commit( 'UPDATE_THUMB', 'none' );
					ROUTE.onStaicRouteTo( path, this.$router, 'cases' );
				}
			}
		},
		onMouseEnterCases( e, slug, type ){
			const _this = this;

			const time = IS_REDUCED ? 0 : mouseHoverWait;

			//===
			clearTimeout( casesEnterTimer );
			casesEnterTimer = setTimeout(function(){

				// if( styleThumb.ready ) styleThumb.onMouseEnter( slug );

				//===
				const $a = document.querySelectorAll('.cases-a,.pickup-a');
				for (let i = 0; i < $a.length; i++) {
					$a[i].classList.remove('selected');
				}
				e.target.classList.add('selected');

				//===
				_this.$store.commit( 'UPDATE_THUMB', slug );
				_this.onMouseEnterCursor( type );

			}, time);

		},
		onMouseLeaveCases( e, slug, type ){
			const _this = this;

			const time = IS_REDUCED ? 0 : mouseHoverWait;

			clearTimeout( casesEnterTimer );
			clearTimeout( casesLeaveTimer );
			casesLeaveTimer = setTimeout(function(){

				//===
				const $a = document.querySelectorAll('.pickup-a');
				for (let i = 0; i < $a.length; i++) {
					$a[i].classList.remove('selected');
				}

				//===
				_this.$store.commit( 'UPDATE_THUMB', 'none' );
				_this.onMouseLeaveCursor();

			}, time);

			// if( styleThumb.ready ) styleThumb.onMouseLeave( slug );


		},
		onMouseEnterCasesWrap( e ){
			clearTimeout( casesWrapEnterTimer );
			clearTimeout( casesWrapLeaveTimer );
			const time = IS_REDUCED ? 0 : mouseHoverWait/2;
			casesWrapEnterTimer = setTimeout(function(){
				e.target.classList.add('entered');
			}, time);
		},
		onMouseLeaveCasesWrap( e ){
			clearTimeout( casesWrapEnterTimer );
			clearTimeout( casesWrapLeaveTimer );
			const time = IS_REDUCED ? 0 : mouseHoverWait/2;
			casesWrapLeaveTimer = setTimeout(function(){
				e.target.classList.remove('entered');
			}, time);
		},


		//========================
		// UI
		//========================
		onMouseEnterCursor( type ){
			this.$store.commit( 'UPDATE_CURSOR', type );
			// mouseEvent.onEnterCursor(type);
		},
		onMouseLeaveCursor(){
			this.$store.commit( 'UPDATE_CURSOR', 'none' );
			// mouseEvent.onLeaveCursor('none');
		},

		//
		onMouseEnterA(e){
			styleUI.onMouseEnterA(e);
		},
		onMouseLeaveA(e){
			styleUI.onMouseLeaveA(e);
		},

		// onMouseEnterScroll(e){
		// },
		// //
		// onMouseEnterLogo(e){
		// 	styleLogo.onMouseEnter(e);
		// },
		// onMouseLeaveLogo(e){
		// 	styleLogo.onMouseLeave(e);
		// },

		//
		onAnchorToTop(e){
			stylePageScroll.onAnchorToTop(e);
		},
		onScrollTo(e){
			styleCasesScroll.onScrollTo(e);
			stylePageScroll.onScrollTo(e);
		},

		//
		onPlayVideo(e){
			const video = e.target.querySelector('video');
			if( video ){
				if( video.paused ){
					onPlayVideo( video );
					e.target.classList.add('is-playing');
					e.target.classList.remove('is-paused');
				} else {
					video.pause();
					e.target.classList.remove('is-playing');
					e.target.classList.add('is-paused');
				}
			}
		}


		// onMouseEnterFooterLink(){
		// 	if( __WEBGL__ && !__DETECT__.device.any ){
		// 		if( !__WEBGL__.hand.anim.splashing ){
		// 			__WEBGL__.hand.toPoint(2,.1,'back.out(1.5)');
		// 		}
		// 	}
		// },
		// onMouseLeaveFooterLink(){
		// 	if( __WEBGL__ && !__DETECT__.device.any ){
		// 		if( !__WEBGL__.hand.anim.splashing ){
		// 			__WEBGL__.hand.toIdle(3.5,.2,'back.inOut(1.2)');
		// 		}
		// 	}
		// },
		// onMouseEnterHeaderLink(){
		// 	if( __WEBGL__ && !__DETECT__.device.any ){
		// 		if( !__WEBGL__.hand.anim.splashing ){
		// 			__WEBGL__.hand.toOpen(2,.1,'back.out(1.5)');
		// 		}
		// 	}
		// },
		// onMouseLeaveHeaderLink(){
		// 	if( __WEBGL__ && !__DETECT__.device.any ){
		// 		if( !__WEBGL__.hand.anim.splashing ){
		// 			__WEBGL__.hand.toIdle(3.5,.2,'back.inOut(1.2)');
		// 		}
		// 	}
		// },
	}
});
