/*
██████╗  █████╗  ██████╗██╗  ██╗ ██████╗ ██╗     
██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔════╝ ██║     
██████╔╝███████║██║     █████╔╝ ██║  ███╗██║     
██╔══██╗██╔══██║██║     ██╔═██╗ ██║   ██║██║     
██████╔╝██║  ██║╚██████╗██║  ██╗╚██████╔╝███████╗
╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝ init
*/

//
import screenFrag from '~/assets/js/shader/frag/screen_v2.frag';

//
window.BACKGL = {

	fov : 30,

	scenes : {},

	keys : [],

	renderer : {
		bg : {
			canvas : null,
			render : null,
			w : 1,
			h : 1,
		},
		// teamAll : {
		// 	canvas : null,
		// 	render : null,
		// 	w : 6/14,
		// 	h : 1,
		// }
	},

	onMouseDown(){
		if( this.scenes.partyHome.ready ) this.scenes.partyHome.onMouseDown();
	},

	onMouseUp(){
		if( this.scenes.partyHome.ready ) this.scenes.partyHome.onMouseUp();
	},

	setZIndex(){

		// if( this.backSide )  this.backSide.position.z = 2;
		if( this.frontSide ) this.frontSide.position.z = 100;
		if( this.background ) this.background.position.z = 0;

	},
	
	once(){

		//
		this._RES = CONTENTS_RES;

		//
		console.log( this._RES );

		//
		this.keys = Object.keys( this.renderer );

		//
		this.width  = __WW__;
		this.height = __WH__;

		//
		this.renderer.bg.canvas = document.getElementById('js-back');
		this.renderer.bg.render = new THREE.WebGLRenderer({
			'canvas'  : this.renderer.bg.canvas,
			alpha     : false,
			antialias : false
		});
		this.renderer.bg.render.setPixelRatio( this._RES );

		//
		this.renderer.bg.render.toneMapping = THREE.ACESFilmicToneMapping;
		this.renderer.bg.render.toneMappingExposure = 1.5;
		this.renderer.bg.render.outputEncoding = THREE.RGBDEncoding;
		this.renderer.bg.render.shadowMap.enabled = false;
		// this.renderer.bg.render.shadowMap.enabled = true;

		//
		this.scene = new THREE.Scene();
		this.scene.background = COLOR.background.three;

		//
		this.fboFrontSide = new THREE.WebGLRenderTarget(
			this.width  * this._RES,
			this.height * this._RES,
			{
				depthBuffer : false,
				minFilter: THREE.LinearFilter,
				magFilter: THREE.LinearFilter
			}
		);
		this.sceneFrontSide = new THREE.Scene();
		// this.sceneFrontSide.background = COLOR.background.three;

		// //
		// this.fboBackSide = new THREE.WebGLRenderTarget(
		// 	this.width  * this._RES,
		// 	this.height * this._RES,
		// 	{ minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter }
		// );
		// this.sceneBackSide = new THREE.Scene();

		//
		this.far = -( this.height / 2) / Math.tan(( this.fov * Math.PI / 180) / 2) * 1/RESCALE.r;
		this.p_camera = new THREE.PerspectiveCamera(this.fov, this.width / this.height, 0.1, this.far - this.width/RESCALE.r );
		this.p_camera.position.set( 0, 0, -this.far );

		//
		// this.controls = new OrbitControls( this.p_camera, this.renderer.bg.render.domElement );

		//
		this.aspect = this.width/this.height;
		this.o_camera = new THREE.OrthographicCamera( -this.height * this.aspect/2, this.height * this.aspect/2, this.height/2, -this.height/2, -this.width*2, this.width*2 );
		this.o_camera.position.z = 1;
		this.scene.add( this.o_camera );

		//
		// this.onInitFilter();

		//
		this.scenes.teamSingle.once();


	},

	onLoadedSecond(){

		console.log('onLoadedSecond');

		//
		this.scenes.partyHome.onLoaded();
		this.scenes.partyHome.onInit();
		this.scenes.partySingle.onInit(); // need elem

		//
		this.scenes.teamAll.onLoaded();
		if( !DETECT.device.any ) this.scenes.teamSingle.onLoaded();

		//
		if( !DETECT.device.any ) this.scenes.gound.onLoaded();

		//
		if( !DETECT.device.any ) this.scenes.teamSingle.onLoadedEnvMap();
		this.scenes.teamAll.onLoadedEnvMap();
		this.scenes.teamAll.onInit();

		//
		this.onInitDom();
		this.onResize();

		//
		this.scenes.dom.onInit();
		if( !IS_REDUCED && !DETECT.device.any ) this.scenes.item.onInit();

		//
		this.onInitScreen();
		
		//
		if( DETECT.device.any ){
			renderForMobile();
		} else {
			renderForDesktop();
		}

		//		
		this.setZIndex();

	},

	onInitScreen(){

		//
		const geometry1 = new THREE.PlaneBufferGeometry(1, 1, 1);
		const material1 = new THREE.ShaderMaterial({
			vertexShader   : `
				varying vec2 vUv;
				void main(){
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
				}
			`,
			fragmentShader : screenFrag,
			uniforms: {

				//
				scale: { value: { x: 1, y: 1 } },

				//
				resolution : { value: { x: 0, y: 0 } },

				//
				scrollPower : { value: { x: 0, y: 0 } },

				//
				fxaaPower : { value: this._RES },

				//
				tDiffuse : { value: this.fboFrontSide.texture },

				//
				alpha : { value: 1 },

				//
				twistPower  : { value : { x : 1.1, y : 1.1 } },
				twistRadius : { value : 1 },
				twistAngle  : { value : 0 },
				twistCenter : { value : { x : 0.5, y : 0.5 } },

				//
				// noizePower : { value : 1024 * 1/window.devicePixelRatio },
				// noizeAlpha : { value : 0.015 * window.devicePixelRatio },
				noizePower : { value : 1024 * 1/this._RES },
				noizeAlpha : { value : this.uniforms.noizeAlpha * 0.1 * this._RES }, // 0.03

				//
				mouse       : { value : { x : 0.5, y : 0.5 } },
				mousePower  : { value : this.uniforms.mousePower * 0.1 },
				mouseRadius : { value : this.uniforms.mouseRadius },

			},
			transparent: true
		});
		material1.alphaTest = 0.5

		// 0.03 console.log( 0.015 * window.devicePixelRatio );
		this.frontSide = new THREE.Mesh( geometry1, material1 );
		this.scene.add( this.frontSide );

		//
		if( DETECT.device.any ){
			material1.uniforms.noizeAlpha.value  = 0;
			material1.uniforms.mousePower.value  = 0;
			material1.uniforms.mouseRadius.value = 0;
		}

		//
		const geometry2 = new THREE.PlaneBufferGeometry(1, 1, 1);
		const material2 = new THREE.ShaderMaterial({
			vertexShader   : `
				varying vec2 vUv;
				void main(){
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
				}
			`,
			fragmentShader : `
				varying vec2 vUv;
				uniform vec3 color;
				void main() {
					gl_FragColor = vec4( color.rgb, 1.0 );
				}
			`,
			uniforms: {
				color : { value: COLOR.background.three }
			},
			transparent: false
		});
		this.background = new THREE.Mesh( geometry2, material2 );
		this.sceneFrontSide.add( this.background );

	},

	uniforms : {

		//
		noizeAlpha : 0.18,

		//
		mousePower  : 0.45,
		mouseRadius : 0.25

	},

	// onInitGui(){

	// 	const _this = this;

	// 	// //
	// 	// this.folder = pane.addFolder({
	// 	// 	title: 'All',
	// 	// });

	// 	// //
	// 	// this.folder.addInput( this.uniforms, 'noizeAlpha', {
	// 	// 	min: 0.000,
	// 	// 	max: 1.000,
	// 	// });

	// 	// //
	// 	// this.folder.addInput( this.uniforms, 'mousePower', {
	// 	// 	min: 0.000,
	// 	// 	max: 2.000,
	// 	// });

	// 	// this.folder.on('change', function(ev) {
	// 	// 	_this.frontSide.material.uniforms.noizeAlpha.value = _this.uniforms.noizeAlpha * 0.1 * _this._RES;
	// 	// 	_this.frontSide.material.uniforms.mousePower.value = _this.uniforms.mousePower * 0.1;
	// 	// });

	// 	// //
	// 	// this.scenes.partyHome.onInitGui();
	// 	// this.scenes.partySingle.onInitGui();
	// 	this.scenes.gound.onInitGui();

	// },

	onLoadedThird(){

		if( !IS_REDUCED ) this.scenes.office.onLoaded();

		ROUTE.onEnterAnim( 0, .5 );

		if( !IS_REDUCED ) this.onSplash();

	},

	onSplash(){

		if( ROUTE.current.type === 'about' || ROUTE.current.type === 'archive' ){
			this.frontSide.material.uniforms.twistAngle.value = -0.25 * this.anim.ratio;
		} else {
			this.frontSide.material.uniforms.twistAngle.value = -1 * this.anim.ratio;
		}
		const tl = gsap.to( this.frontSide.material.uniforms.twistAngle, {
			value    : 0,
			duration : 10,
			ease     : this.anim.ease,
		});
		this.anim.tween.enter.push( tl );

	},

	anim : {
		ratio : 0.5,
		ease  : 'elastic.out( 1, 0.45 )',
		tween : {
			enter : [],
			leave : []
		}
	},

	onRouteLeave(){

		killTweens( this.anim.tween.leave );
		killTweens( this.anim.tween.enter );
		// let twistAngle = .025 * this.anim.ratio;
		// if( ROUTE.current.type === 'about' || ROUTE.current.type === 'archive' ){
		// 	twistAngle = twistAngle * 0.125;
		// }
		// const tl = gsap.to( this.frontSide.material.uniforms.twistAngle, {
		// 	value    : twistAngle,
		// 	duration : ANIM.dom.leave.s,
		// 	ease     : ANIM.dom.leave.e,
		// });
		// this.anim.tween.leave.push( tl );

	},

	onRouteEnter(){

		killTweens( this.anim.tween.leave );
		killTweens( this.anim.tween.enter );
		if( ROUTE.current.type === 'about' || ROUTE.current.type === 'archive' ){
			this.frontSide.material.uniforms.twistAngle.value = -0.125 * this.anim.ratio * Math.random();
		} else {
			this.frontSide.material.uniforms.twistAngle.value = -0.25 * this.anim.ratio * Math.random();
		}
		const tl = gsap.to( this.frontSide.material.uniforms.twistAngle, {
			value    : 0,
			duration : 5,
			ease     : this.anim.ease,
		});
		this.anim.tween.enter.push( tl );

	},

	onResize(){

		console.log('[BACKGL.onResize]');

		//
		this.width  = window.innerWidth;
		this.height = window.innerHeight;

		//
		this.aspect = this.width/this.height;

		//
		if( this.screen ){
			this.screen.scale.x = this.width;
			this.screen.scale.y = this.height;
		}

		//
		this.scenes.partyHome.onResize();

		if( this.scenes.partySingle.ready ) this.scenes.partySingle.onResize();

		if( this.scenes.teamSingle.ready ) this.scenes.teamSingle.onResize();

		if( this.scenes.teamAll.ready ) this.scenes.teamAll.onResize();

		this.scenes.dom.onResize();

		if( this.scenes.item.ready ) this.scenes.item.onResize();

		if( this.scenes.gound.ready ) this.scenes.gound.onResize();

		if( this.scenes.office.ready ) this.scenes.office.onResize();

		//
		Object.keys( this.renderer ).forEach( (key) => {
			const v = this.renderer[ key ];
			if( v.render ){
				if( !Array.isArray(v.render) ){
					v.render.setSize( this.width * v.w, this.height * v.h );
				}
			}
		});

		//
		if( this.frontSide ){
			this.frontSide.scale.x = this.width;
			this.frontSide.scale.y = this.height;
			this.frontSide.material.uniforms.resolution.value.x = this.frontSide.scale.x;
			this.frontSide.material.uniforms.resolution.value.y = this.frontSide.scale.y;
		}

		if( this.background ){
			this.background.scale.x = this.width;
			this.background.scale.y = this.height;
		}

		// if( this.backSide ){
		// 	this.backSide.scale.x = this.width;
		// 	this.backSide.scale.y = this.height;
		// 	this.backSide.material.uniforms.resolution.value.x = this.backSide.scale.x;
		// 	this.backSide.material.uniforms.resolution.value.y = this.backSide.scale.y;
		// }

		//
		if( this.p_camera ){
			this.far = -( this.height / 2) / Math.tan(( this.fov * Math.PI / 180) / 2) * ( 1 / RESCALE.r );
			this.p_camera.aspect = this.aspect;
			this.p_camera.position.z = -this.far;
			this.p_camera.far = this.far - this.width / RESCALE.r;
			this.p_camera.updateProjectionMatrix();
		}

		//
		if( this.o_camera ){
			this.o_camera.left   = -this.width  / 2;
			this.o_camera.right  =  this.width  / 2;
			this.o_camera.top    =  this.height / 2;
			this.o_camera.bottom = -this.height / 2;
			this.o_camera.updateProjectionMatrix();
		}

		// //
		// this.onResizeDom();

	},


	//=======================================
	// DOM INVIEW
	//=======================================
	domElements : [],
	body : {el : null },
	onInitDom( elem = document ){

		//
		if( elem ){ this.body.el = elem } else { this.body.el = document.querySelector('.Sc') }
		this.$dom = this.body.el.querySelectorAll('.js-gl-dom');

		//
		for (let i = 0; i < this.$dom.length; i++) {

			//
			const el = this.$dom[i];

			// if( !el.classList.contains('js-gl-low') ){

			//
			el.dataset.index = i;

			//
			let rect = el.getBoundingClientRect();
			let pivot = el.querySelector('.js-gl-pivot');
			if( pivot ){
				rect = pivot.getBoundingClientRect();
			}

			//
			let glType = el.dataset.glType;
			let glName = el.dataset.glName;


			//==================================================================================================
			// ██╗      ██████╗ ██╗    ██╗███████╗██████╗ ███████╗ ██████╗
			// ██║     ██╔═══██╗██║    ██║██╔════╝██╔══██╗██╔════╝██╔════╝
			// ██║     ██║   ██║██║ █╗ ██║███████╗██████╔╝█████╗  ██║     
			// ██║     ██║   ██║██║███╗██║╚════██║██╔═══╝ ██╔══╝  ██║     
			// ███████╗╚██████╔╝╚███╔███╔╝███████║██║     ███████╗╚██████╗
			// ╚══════╝ ╚═════╝  ╚══╝╚══╝ ╚══════╝╚═╝     ╚══════╝ ╚═════╝
			// IS_LOW_SPEC時はGround系およびOfficeをDomGLに変換
			//==================================================================================================
			// if( IS_LOW_SPEC ){
			// 	if( glName === 'homeFooterGroup' || glName === 'about8Group' || glName === 'aboutFooterGroup' || glName === 'office' ){
			// 		glName = el.dataset.mediaType;
			// 		glType = 'dom';
			// 	}
			// }
			//
			const bgImg = el.querySelector('.bg-img');
			let imgD1x = el.dataset.d1x;
			let imgD2x = el.dataset.d2x;
			let imgMob = el.dataset.mob;
			// if( IS_LOW_SPEC && bgImg ){
			// 	imgD1x = bgImg.dataset.bgD1x ? bgImg.dataset.bgD1x : bgImg.dataset.d1x;
			// 	imgD2x = bgImg.dataset.bgD2x ? bgImg.dataset.bgD2x : bgImg.dataset.d2x;
			// 	imgMob = bgImg.dataset.bgMob ? bgImg.dataset.bgMob : bgImg.dataset.mob;
			// }

			//
			this.domElements.push({

				//===
				index  : i,
				el     : el,
				pivot  : pivot,

				//===
				type  : glType,
				name  : glName,
				visible : 0,
				mesh : null,
				text : {},
				rect : rect,
				clone : Boolean( el.dataset.clone ),

				//===
				uniqueId : Number( el.dataset.uniqueId ),
				slug : el.dataset.slug,
				tag : el.dataset.tag,

				//===
				bgColor : null,

				//===
				rand : 0.5 + Math.random() * 0.5,

				//===
				timer : null,
				wait  : 500,
				borderRadius  : Number( el.dataset.borderRadius ),
				mediaType  : el.dataset.mediaType,
				mediaRatio : {
					x : Number( el.dataset.w ),
					y : Number( el.dataset.h )
				},
				loader : null,
				tex : null,
				img : {
					el  : null,
					d1x : imgD1x,
					d2x : imgD2x,
					mob : imgMob
				},
				video : {
					el : null,
					src : el.dataset.video
				},

				tweens : [],

				poster : {
					src    : el.dataset.poster,
					loader : null,
					tex    : null
				},

				//===
				model : {
					src : el.dataset.src,
					type : el.dataset.modelType,
					name : el.dataset.modelName,
					origin : {
						rescale : 1,
						scale : Number(el.dataset.scale),
						position : { x:0, y:0, z:0 },
						rotation : { x:0, y:0, z:0 }
					}
				},

				//===
				events : {
					mouseenter : { func : null, timer : null },
					mouseleave : { func : null, timer : null },
				}

			});

			// }

		}

		//
		this.targets = [ ...this.$dom ];
		if( this.targets.length ){
			this.options = {
				threshold: 0
			};
			this.observer = new IntersectionObserver( this.listerner, this.options );
			this.targets.forEach( (e,i) => {
				this.observer.observe(e);
			});
		}

	},

	onResizeDom(){
		for (let i = 0; i < this.domElements.length; i++) {
			const v = this.domElements[i];
			// switch( v.type ){
			// 	case 'ground':
			// 	break;
			// 	case 'dom':
			// 	break;
			// 	case 'team':
			// 		if( this.scenes.teamSingle.ready ) this.scenes.teamSingle.onResizeDom( v );
			// 	break;
			// 	case 'teamall':
			// 	break;
			// 	case 'item':
			// 	break;
			// 	case 'office':
			// 	break;
			// }
			switch( v.name ){
				case 'cases':
					if( v.pivot ){
						v.rect = v.pivot.getBoundingClientRect();
					} else {
						v.rect = v.el.getBoundingClientRect();
					}
				break;
				default:
					if( v.pivot ){
						v.rect = v.pivot.getBoundingClientRect();
					} else {
						v.rect = v.el.getBoundingClientRect();
					}
				break;
			}
		}

	},

	
	listerner (changes) {

		const _this = this;
		changes.forEach( (e,i) => {

			const el = e.target;
			const isIntersecting = e.isIntersecting;

			if ( isIntersecting ) {
				el.dataset.visible = 1;
			} else {
				el.dataset.visible = 0;
			}

			BACKGL.onInview();

		});

	},

	onInview(){

		//
		let isGroundVisible = 0;
		for (let i = 0; i < this.domElements.length; i++) {
			const dom = this.domElements[i];
			dom.visible = Boolean( Number( dom.el.dataset.visible ) );
			switch( dom.type ){
				case 'ground':
					if( dom.visible ){
						if( this.scenes.gound.ready ) this.scenes.gound.currentElement.rect = dom.rect;
						isGroundVisible++;
					}
				break;
			}
		}

		//
		this.isGroundVisible = Boolean( isGroundVisible );
		if( !DETECT.device.any ) this.scenes.partyHome.onInview( !this.isGroundVisible );

		//
		for (let i = 0; i < this.domElements.length; i++) {
			const dom = this.domElements[i];
			switch( dom.type ){
				case 'ground':
					if( this.scenes.gound.ready ) this.scenes.gound.onIniview( dom, this.isGroundVisible );
				break;
				case 'dom':
					this.scenes.dom.onIniview( dom );
				break;
				case 'team':
					if( this.scenes.teamSingle.ready ) this.scenes.teamSingle.onIniview( dom );
				break;
				case 'teamall':
					if( this.scenes.teamAll.ready ) this.scenes.teamAll.onIniview( dom );
				break;
				case 'item':
					if( this.scenes.item.ready ) this.scenes.item.onIniview( dom );
				break;
				case 'office':
					if( this.scenes.office.ready ) this.scenes.office.onIniview( dom );
				break;
			}
		}

	},

	// onInitFilter(){

	// 	// this.composer = new EffectComposer( this.renderer.bg.render );
	// 	// var renderPass = new RenderPass( this.scene, this.o_camera );
	// 	// this.composer.setSize( this.width, this.height );

	// 	// // //
	// 	// // var renderTargetParameters = {
	// 	// // 	minFilter: THREE.LinearFilter,
	// 	// // 	magFilter: THREE.LinearFilter,
	// 	// // 	stencilBuffer: false
	// 	// // };
	// 	// // var savePass = new SavePass( new THREE.WebGLRenderTarget( this.width, this.height, renderTargetParameters ) );

	// 	// // //
	// 	// // var blendPass = new ShaderPass( BlendShader, 'tDiffuse1' );
	// 	// // blendPass.uniforms[ 'tDiffuse2' ].value = savePass.renderTarget.texture;
	// 	// // blendPass.uniforms[ 'mixRatio' ].value = 0.65;

	// 	// // //
	// 	// // var outputPass = new ShaderPass( CopyShader );
	// 	// // outputPass.renderToScreen = true;

	// 	// //================
	// 	// // FXAA
	// 	// //================
	// 	// var fxaaPass = new ShaderPass( FXAAShader );
	// 	// fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / ( this.width  * this._RES );
	// 	// fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / ( this.height * this._RES );


	// 	// //================
	// 	// // ADD
	// 	// //================
	// 	// this.composer.addPass( renderPass );
	// 	// this.composer.addPass( fxaaPass );
	// 	// // this.composer.addPass( blendPass );
	// 	// // this.composer.addPass( savePass );
	// 	// // this.composer.addPass( outputPass );

	// 	// // var copyPass = new ShaderPass( CopyShader );
	// 	// // this.composer.addPass( renderPass );
	// 	// // this.composer.addPass( copyPass );
	// 	// // this.glitchPass = new GlitchPass();
	// 	// // this.composer.addPass( this.glitchPass );

	// },

	scrollPower : {},

	rad : {
		keys : ['fast','normal','slow'],
		fast : {
			val : 0,
			speed : 1,
			deg : 0,
			sin : 0
		},
		normal : {
			val : 0,
			speed : 0.5,
			deg : 0,
			sin : 0
		},
		slow : {
			val : 0,
			speed : 0.1,
			deg : 0,
			sin : 0
		},
	},

	onRenderForDesktop(){

		//===
		this.renderer.bg.render.setRenderTarget( null );
		this.renderer.bg.render.clear();
		this.renderer.bg.render.render( this.scene, this.o_camera );
		this.renderer.bg.render.setRenderTarget( this.fboFrontSide );
		this.renderer.bg.render.render( this.sceneFrontSide, this.o_camera );

		//===
		if( this.scenes.partyHome.ready ) this.scenes.partyHome.onRender();
		if( this.scenes.partySingle.ready ) this.scenes.partySingle.onRender();
		if( this.scenes.gound.ready ) this.scenes.gound.onRender();
		if( this.scenes.item.ready ) this.scenes.item.onRender();
		if( this.scenes.teamAll.ready ) this.scenes.teamAll.onRender();
		if( this.scenes.teamSingle.ready ) this.scenes.teamSingle.onRender();
		if( this.scenes.office.ready ) this.scenes.office.onRender();
		this.frontSide.material.uniforms.alpha.value = ROUTE.anim.alpha;

	},

	onScrollForDesktop(){

		//
		for (let i = 0; i < this.rad.keys.length; i++) {
			const key = this.rad.keys[i];
			const v = this.rad[key];
			v.deg = ( v.deg <= 360 ) ? v.deg += v.speed : 0;
			v.val = v.deg * (Math.PI / 180);
			v.sin = Math.sin( v.val );
		}

		//
		this.scenes.dom.onScroll();

		//
		this.frontSide.material.uniforms.mouse.value.x = mouseEvent.anims.normal.sx;
		this.frontSide.material.uniforms.mouse.value.y = mouseEvent.anims.normal.sy;

		//
		if( this.scenes.partyHome.ready ) this.scenes.partyHome.onScroll();
		if( this.scenes.partySingle.ready ) this.scenes.partySingle.onScroll();
		if( this.scenes.gound.ready ) this.scenes.gound.onScroll();
		if( this.scenes.item.ready ) this.scenes.item.onScroll();
		if( this.scenes.teamAll.ready ) this.scenes.teamAll.onScroll();
		if( this.scenes.teamSingle.ready ) this.scenes.teamSingle.onScroll();
		if( this.scenes.office.ready ) this.scenes.office.onScroll();

	},

	onRenderForMobile(){

		//
		for (let i = 0; i < this.rad.keys.length; i++) {
			const key = this.rad.keys[i];
			const v = this.rad[key];
			v.deg = ( v.deg <= 360 ) ? v.deg += v.speed : 0;
			v.val = v.deg * (Math.PI / 180);
			v.sin = Math.sin( v.val );
		}

		//
		this.renderer.bg.render.setRenderTarget( null );
		this.renderer.bg.render.clear();
		this.renderer.bg.render.render( this.scene, this.o_camera );

		//
		if( this.sceneFrontSide ){
			this.renderer.bg.render.setRenderTarget( this.fboFrontSide );
			this.renderer.bg.render.render( this.sceneFrontSide, this.o_camera );
		}

		//=======================================
		// bg canvas
		//=======================================
		if( this.scenes.partyHome.ready ){
			this.scenes.partyHome.onRender();
			this.scenes.partyHome.onScroll();
		}
		if( this.scenes.partySingle.ready ){
			this.scenes.partySingle.onRender();
			this.scenes.partySingle.onScroll();
		}
		this.scenes.dom.onScroll();

		//=======================================
		// teamAll canvas
		//=======================================
		if( this.scenes.teamAll.ready ){
			this.scenes.teamAll.onRender();
			this.scenes.teamAll.onScroll();
		}

		//=======================================
		// JUMP!!!!!!!!1
		//=======================================
		this.frontSide.material.uniforms.alpha.value = ROUTE.anim.alpha;


	},

	onScroll( type, speed ){

		//
		switch( type ){

			case 'archive':

				//
				const px1 = speed.pow1.x / speed.max;
				const py1 = speed.pow1.y / speed.max;
				const dist = Math.sqrt( px1 * px1 + py1 * py1 );

				//
				const dir = styleArchiveScroll.zoom.dir === 'in' ? 1:-1; 
				// this.frontSide.material.uniforms.scrollPower.value.x =   Math.sqrt(py1*py1) * 0.03 + dir * styleArchiveScroll.zoom.power*0.125;
				// this.frontSide.material.uniforms.scrollPower.value.y = ( Math.sqrt(px1*px1) * 0.03 + dir * styleArchiveScroll.zoom.power*0.125 ) * this.aspect;
				// this.frontSide.scale.x = (this.width  + this.width  * ( dist * 0.3 + styleArchiveScroll.zoom.power * 0.05 ) ) * ROUTE.anim.scale;
				// this.frontSide.scale.y = (this.height + this.height * ( dist * 0.3 + styleArchiveScroll.zoom.power * 0.05 ) ) * ROUTE.anim.scale;
				// this.frontSide.material.uniforms.resolution.value.x = this.frontSide.scale.x;
				// this.frontSide.material.uniforms.resolution.value.y = this.frontSide.scale.y;

				//===========================
				// 控えめに 2022/11/10
				//===========================
				this.frontSide.material.uniforms.scrollPower.value.x =   Math.sqrt(py1*py1) * 0.015 + dir * styleArchiveScroll.zoom.power*0.125;
				this.frontSide.material.uniforms.scrollPower.value.y = ( Math.sqrt(px1*px1) * 0.015 + dir * styleArchiveScroll.zoom.power*0.125 ) * this.aspect;
				this.frontSide.scale.x = (this.width  + this.width  * ( dist * 0.15 + styleArchiveScroll.zoom.power * 0.025 ) ) * ROUTE.anim.scale;
				this.frontSide.scale.y = (this.height + this.height * ( dist * 0.15 + styleArchiveScroll.zoom.power * 0.025 ) ) * ROUTE.anim.scale;
				this.frontSide.material.uniforms.resolution.value.x = this.frontSide.scale.x;
				this.frontSide.material.uniforms.resolution.value.y = this.frontSide.scale.y;

				//
				this.frontSide.rotation.z = px1 * -0.025 + ROUTE.anim.rotation.z * ( Math.PI / 180 );

			break;
			default:

				const py2 = speed.pow1/speed.max;
				// this.frontSide.material.uniforms.scrollPower.value.x = Math.abs( py2 ) * 0.05;
				// this.frontSide.material.uniforms.scrollPower.value.y = 0;
				// this.frontSide.scale.x = ( this.width  + this.width  * Math.sqrt( py2 * py2 ) * 0.25 ) * ROUTE.anim.scale;
				// this.frontSide.scale.y = ( this.height + this.height * Math.sqrt( py2 * py2 ) * 0.25 ) * ROUTE.anim.scale;
				// this.frontSide.material.uniforms.resolution.value.x = this.frontSide.scale.x;
				// this.frontSide.material.uniforms.resolution.value.y = this.frontSide.scale.y;

				//===========================
				// 控えめに 2022/11/10
				//===========================
				this.frontSide.material.uniforms.scrollPower.value.x = Math.abs( py2 ) * 0.025;
				this.frontSide.material.uniforms.scrollPower.value.y = 0;
				this.frontSide.scale.x = ( this.width  + this.width  * Math.sqrt( py2 * py2 ) * 0.125 ) * ROUTE.anim.scale;
				this.frontSide.scale.y = ( this.height + this.height * Math.sqrt( py2 * py2 ) * 0.125 ) * ROUTE.anim.scale;
				this.frontSide.material.uniforms.resolution.value.x = this.frontSide.scale.x;
				this.frontSide.material.uniforms.resolution.value.y = this.frontSide.scale.y;

				//
				// this.frontSide.material.uniforms.scrollPower.value.x = 0;
				// this.frontSide.material.uniforms.scrollPower.value.y = 0;
				// this.frontSide.scale.x = ( this.width  + this.width  * Math.sqrt( py2 * py2 ) * 0.25 ) * ROUTE.anim.scale;
				// this.frontSide.scale.y = ( this.height + this.height * Math.sqrt( py2 * py2 ) * 0.25 ) * ROUTE.anim.scale;
				// this.frontSide.material.uniforms.resolution.value.x = this.frontSide.scale.x;
				// this.frontSide.material.uniforms.resolution.value.y = this.frontSide.scale.y;

				//
				this.frontSide.rotation.z = ROUTE.anim.rotation.z * ( Math.PI / 180 );

				//
				// this.frontSide.material.uniforms.twistAngle.value = Math.abs( py2 ) * 0.1;


			break;
		}

	},

	onReset : function(){

		const _this = this;

		//
		if( this.observer ){
			this.targets.forEach( (e,i) => {
				_this.observer.disconnect(e);
			})
			this.targets  = null;
			this.observer = null;
		}
		this.$dom = null;

		//
		for (let i = 0; i < this.domElements.length; i++) {
			killTweens( this.domElements[i].tweens );
		}

		//
		this.domElements = [];

	}

}
















































