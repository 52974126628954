/*
██████╗  ██████╗ ███╗   ███╗
██╔══██╗██╔═══██╗████╗ ████║
██║  ██║██║   ██║██╔████╔██║
██║  ██║██║   ██║██║╚██╔╝██║
██████╔╝╚██████╔╝██║ ╚═╝ ██║
╚═════╝  ╚═════╝ ╚═╝     ╚═╝ archive scroll
*/

window.styleArchiveScroll = {

	ready : false,

	array : [],

	body : {},

	wait   : 30,

	zoom  : {
		power : 0,
		scale : 1,
		dir : 'in'
	},

	scroll : {
		delta : {
			x : 0,
			y : 0,
		},
		progress : 0,
		x : 0,
		y : 0,
		top : 0,
		left : 0,
		ease : 0.075,
		ratio : 100,
	},

	speed  : {
		delta : {
			x : 0,
			y : 0,
		},
		hitory : [],
		max    : 4,
		tween  : null,
		pow0   : {
			x : 0,
			y : 0,
		},
		pow1   : {
			x : 0,
			y : 0,
		},
		dir : {
			x : 0,
			y : 0,
		},
		duration : 2
	},

	grid : {
		total : {
			w : 0,
			h : 0
		},
		padding : {
			w : 0,
			h : 0,
			ratio : {
				x : 1/48,
				y : 1/48
			}
		},
		inner : {
			w : 0,
			h : 0,
			ratio : {
				x : 10/48,
				y : 10/48
			}
		},
		box : {
			w : 0,
			h : 0,
			ratio : {
				x : 12/48,
				y : 12/48
			}
		},
		split : {
			x : 5,
			y : 5
		},
		center : {
			x : 0,
			y : 0
		}
	},

	mouse : {
		event : null,
		ratio : {
			r : 4,
			x : 1,
			y : 1
		},
		dir : {
			x : 0,
			y : 0
		},
		start : {
			delta : {
				x : 0,
				y : 0
			},
		},
		buffer : {
			x : 0,
			y : 0
		}
	},

	touch : {
		event : null,
		ratio : {
			x : 2,
			y : 2
		},
		dir : {
			x : 0,
			y : 0
		},
		start : {
			delta : {
				x : 0,
				y : 0
			},
		},
		buffer : {
			x : 0,
			y : 0
		}
	},

	onInit( elem ){

		//
		this.scroll.x = 0;
		this.scroll.y = 0;
		this.scroll.delta.x = 0;
		this.scroll.delta.y = 0;
		this.scroll.left = 0;
		this.scroll.top  = 0;

		//===
		if( elem ){ this.body.el = elem.querySelector('.Pc') } else { this.body.el = document.querySelector('.Pc'); }

		//===
		if( this.body.el.dataset.scrollType != 'archive' ) return false;

		//===
		if( DETECT.os.win ){
			this.scroll.ease  = 0.125;
			this.scroll.ratio = 120;
		}
		if( DETECT.touch ){
			this.scroll.ease  = 0.125/2;
		}

		// if( DETECT.device.tablet ){
		// 	this.touch.ratio.x = 1;
		// 	this.touch.ratio.y = 1;
		// } else if( DETECT.device.mobile ){
		// 	this.touch.ratio.x = 1.5;
		// 	this.touch.ratio.y = 1.5;
		// }

		//===
		this.wrap = this.body.el.querySelector('.archive-ul');

		//===
		this.li = this.body.el.querySelectorAll('.archive-li');
		for (let i = 0; i < this.li.length; i++) {
			this.array.push({
				el    : this.li[i],
				label : this.li[i].querySelector('.archive-label'),
				link  : this.li[i].querySelector('.archive-link'),
				webgl : this.li[i].querySelector('.js-gl-dom'),
				inner : this.li[i].querySelector('.archive-a'),
				uniqueId : 0,
				position : {
					x : 0,
					y : 0,
				},
				hasSingle : Boolean(this.li[i].querySelector('.archive-a').dataset.hasSingle),
				events : {
					el : this.li[i].querySelector('.archive-a'),
					timer : null,
					click : {
						timestamp : 0,
						func : null,
					},
					mouseenter : {
						func : null,
					},
					mouseleave : {
						func : null,
					},
					mousedown : {
						timestamp : 0,
						func : null,
					},
					mousemove : {
						func : null,
					},
					mouseup : {
						timestamp : 0,
						func : null,
					}
				}
			});
		}

		//===
		this.zoom.power = 0;
		this.zoom.scale = Number( this.body.el.dataset.zoomScale );

		//===
		this.addEvnets();
		this.onResize();
		this.setPosition();
		this.ready = true;

		//===
		if( DETECT.device.mobile ) this.setZoomIn(1.5);

		//===
		if( !DETECT.device.any ){
			window.removeEventListener('wheel',onWheel );
			window.addEventListener('wheel',onWheel,{ passive: false });
		}

	},

	position : {
		x : 0,
		y : 0,
	},

	setPosition(){

		let index = 0;
		for (let x = 0; x < this.grid.split.x; x++) {
			for (let y = 0; y < this.grid.split.y; y++) {

				//
				const v = this.array[index];
				v.position.x = x;
				v.position.y = y;
				v.webgl.dataset.x = x;
				v.webgl.dataset.y = y;
				v.webgl.dataset.uniqueId = index;
				v.uniqueId = index;
				if( v.label ) v.label.innerText = '('+x+','+y+')';

				//
				// v.el.style.width  = this.grid.box.w +"px";
				// v.el.style.height = this.grid.box.h +"px";

				//
				index++;

			}
		}

	},

	selected : 12,

	addEvnets(){

		const _this = this;

		this.array.forEach( (v) =>{

			if( !v.hasSingle ){

				v.events.click.func = function(e){
					if( v.events.mouseup.timestamp - v.events.mousedown.timestamp < 150 ){
						const href = v.link.getAttribute('href');
						if( href ){
							window.open( href, '_blank', 'noreferrer')
						}
					}
				}

				v.events.mousedown.func = function(e){
					v.events.mousedown.timestamp = Date.now();
				}

				v.events.mouseup.func = function(e){
					v.events.mouseup.timestamp = Date.now();
				}

				v.events.mouseenter.func = function(e){
					_this.selected = v.uniqueId;
				}

				v.events.mouseleave.func = function(e){
				}

				//
				v.events.el.addEventListener('click',v.events.click.func);
				v.events.el.addEventListener('mouseenter',v.events.mouseenter.func);
				v.events.el.addEventListener('mouseleave',v.events.mouseleave.func);

				//
				if( DETECT.device.any ){
					v.events.el.addEventListener('touchstart',v.events.mousedown.func, {passive: true});
					v.events.el.addEventListener('touchend',v.events.mouseup.func, {passive: true});
				} else {
					v.events.el.addEventListener('mousedown',v.events.mousedown.func);
					v.events.el.addEventListener('mouseup',v.events.mouseup.func);
				}

			}

		});

	},


	lastTime : 0,
	onScroll(){

		//===
		const currentTime = performance.now() * .001;
		const deltaTime   = currentTime - this.lastTime;
		const alpha = Math.exp( -this.scroll.ease * 85 * deltaTime );
		this.scroll.x = lerp( this.scroll.x, this.scroll.delta.x, alpha );
		this.scroll.y = lerp( this.scroll.y, this.scroll.delta.y, alpha );
		this.lastTime = currentTime;

		//===
		// this.scroll.x += ( this.scroll.delta.x - this.scroll.x ) * this.scroll.ease;
		// this.scroll.y += ( this.scroll.delta.y - this.scroll.y ) * this.scroll.ease;

		//===
		this.scroll.left = this.scroll.x % (this.grid.total.w);
		this.scroll.top  = this.scroll.y % (this.grid.total.h);

	},



	onResize( anim = false ){

		//
		this.aspect = window.innerHeight/window.innerWidth;

		//===
		if( DETECT.device.any ){
			if( this.aspect > 1 ){
				// 縦向き
				this.grid.padding.ratio.x = 2/48;
				this.grid.padding.ratio.y = 2/48;
				this.grid.inner.ratio.x   = 19/48;
				this.grid.inner.ratio.y   = 19/48;
				this.grid.box.ratio.x     = 21/48;
				this.grid.box.ratio.y     = 21/48;
			} else {
				this.grid.padding.ratio.x = 1.25/48;
				this.grid.padding.ratio.y = 1.25/48;
				this.grid.inner.ratio.x   = 11/48;
				this.grid.inner.ratio.y   = 11/48;
				this.grid.box.ratio.x     = 14/48;
				this.grid.box.ratio.y     = 14/48;
			}
		}

		//
		if( !anim ){
			this.scroll.x = 0;
			this.scroll.y = 0;
			this.scroll.delta.x = 0;
			this.scroll.delta.y = 0;
			this.scroll.left = 0;
			this.scroll.top  = 0;
		}

		//
		this.grid.inner.w = window.innerWidth * this.grid.inner.ratio.x * this.zoom.scale;
		this.grid.inner.h = window.innerWidth * this.grid.inner.ratio.y * this.zoom.scale;

		//
		this.grid.padding.w = window.innerWidth * this.grid.padding.ratio.x * this.zoom.scale;
		this.grid.padding.h = window.innerWidth * this.grid.padding.ratio.y * this.zoom.scale;

		//
		this.grid.box.w = window.innerWidth * this.grid.box.ratio.x * this.zoom.scale;
		this.grid.box.h = window.innerWidth * this.grid.box.ratio.y * this.zoom.scale;

		//
		this.grid.total.w = this.grid.box.w * this.grid.split.x;
		this.grid.total.h = this.grid.box.h * this.grid.split.y;

		//
		this.grid.center.x = -this.grid.total.w/2 + window.innerWidth/2;
		this.grid.center.y = -this.grid.total.h/2 + window.innerHeight/2;

		//
		let index = 0;
		for (let x = 0; x < this.grid.split.x; x++) {
			for (let y = 0; y < this.grid.split.y; y++) {
				const v = this.array[index];
				v.el.style.width      = this.grid.box.w + "px";
				v.el.style.height     = this.grid.box.h + "px";
				v.inner.style.padding = this.grid.padding.w + "px";
				index++;
			}
		}

	},

	setZoomIn( scale = 1 ){

		this.scroll.delta.x = 0;
		this.scroll.delta.y = 0;
		this.speed.delta.x = 0;
		this.speed.delta.y = 0;
		this.scroll.x = 0;
		this.scroll.y = 0;
		this.zoom.scale = scale;
		this.onResize( true );
		BACKGL.onResizeDom();
		BACKGL.scenes.dom.onResize();
	
	},

	onZoomInOut( scale ){

		const _this = this;
		const _s = 1;
		const _e = 'power3.out';

		//
		if( this.zoom.scale === scale ) return false;

		//
		if( this.zoom.scale < scale ){
			this.zoom.dir = 'in';
		} else {
			this.zoom.dir = 'out';
		}

		//
		gsap.to( this.scroll.delta, {
			x : 0,
			y : 0,
			duration : _s,
			ease : _e,
		});
		gsap.to( this.speed.delta, {
			x : 0,
			y : 0,
			duration : _s,
			ease : _e,
		});
		gsap.to( this.scroll, {
			x : 0,
			y : 0,
			left : 0,
			top : 0,
			duration : _s,
			ease : _e,
		});

		//
		gsap.to( this.zoom, {
			power : 1,
			duration : _s * 0.4,
			ease : _e,
			onComplete(){
				gsap.to( _this.zoom, {
					power : 0,
					duration : _s * 1.3,
					ease : _e,
				});
			}
		});

		//
		gsap.to( this.zoom, {
			scale : scale,
			duration : _s,
			ease : _e,
			onUpdate(){
				_this.onResize( true );
				BACKGL.onResizeDom();
				BACKGL.scenes.dom.onResize();
			}
		});

	},

	onUpdate(){

		//
		BACKGL.onScroll( 'archive', this.speed );

		//
		let index = 0;
		for (let x = 0; x < this.grid.split.x; x++) {
			for (let y = 0; y < this.grid.split.y; y++) {

				//
				const v = this.array[index];

				//
				let _x = ( x * this.grid.box.w ) + this.grid.center.x - this.scroll.left;
				let _y = ( y * this.grid.box.h ) + this.grid.center.y - this.scroll.top;

				// 下リピート
				// if( _y + this.grid.box.h < 0 ){
				// 	_y = _y + ( this.grid.total.h - this.grid.box.h );
				// }
				if( _y + this.grid.box.h < 0 ){
					_y = _y + this.grid.total.h;
				}

				// // 上リピート
				// if( _y - window.innerHeight > 0 ){
				// 	_y = _y - ( this.grid.total.h - this.grid.box.h );
				// }
				if( _y - window.innerHeight > 0 ){
					_y = _y - this.grid.total.h;
				}

				// 中央
				if( x === 2 ){
					// console.log( ( _y + window.innerHeight - this.grid.h/2) > 0 );
					// console.log( ( _x - window.innerWidth ) < 0 );
					// if( _y + this.grid.box.h < 0 ){
					// 	_y = _y + ( this.grid.total.h - this.grid.box.h );
					// }
				}



				// 左リピート
				if( _x - window.innerWidth > 0 ){
					_x = _x - this.grid.total.w;
				}

				// 右リピート
				if( _x + this.grid.box.w < 0 ){
					_x = _x + this.grid.total.w;
				}

				//
				const inview_x = ( _x - window.innerWidth ) < 0;
				const inview_y = ( _y + window.innerHeight - this.grid.box.h/2) > 0;

				//
				// v.el.style.transform = "translate3d("+ _x +"px, "+ _y +"px, 0)";
				if( inview_y && inview_x ){
					v.el.style.transform = "translate3d("+ _x +"px, "+ _y +"px, 0)";
					v.el.dataset.enable = true;
					BACKGL.scenes.dom.onUpdateArchive( index, _x+this.grid.padding.h, _y+this.grid.padding.w, true );
				} else {
					v.el.dataset.enable = false;
					BACKGL.scenes.dom.onUpdateArchive( index, _x+this.grid.padding.h, _y+this.grid.padding.w, false );
				}

				//
				index++;

			}
		}

		//
		this.onScroll();

	},

	onWheel(e){

		const _this = this;
		this.scroll.delta.x += -getWheelDistXY(e).x * this.scroll.ratio * 1/this.aspect;
		this.speed.delta.x  += -getWheelDistXY(e).x * 1/this.aspect;
		this.scroll.delta.y += -getWheelDistXY(e).y * this.scroll.ratio;
		this.speed.delta.y  +=  getWheelDistXY(e).y;
		this.getSpeed(10);

		//===
		this.active = true;

		//===
		clearTimeout( this.timer );
		this.timer = setTimeout( ()=>{
			_this.onComplete();
		}, this.wait );

	},

	getSpeed( ratio = 1 ){

		//===
		if( this.speed.hitory.length > 2 ){
			this.speed.hitory.shift();
			this.speed.hitory.push({
				x : this.speed.delta.x,
				y : this.speed.delta.y
			});
		} else {
			this.speed.hitory.push({
				x : this.speed.delta.x,
				y : this.speed.delta.y
			});
		}

		//
		if( this.speed.hitory.length > 2 ){
			this.speed.pow0.x = Math.min( Math.abs(( this.speed.hitory[0].x - this.speed.hitory[2].x ) * ratio ) , this.speed.max );
			this.speed.pow0.y = Math.min( Math.abs(( this.speed.hitory[0].y - this.speed.hitory[2].y ) * ratio ) , this.speed.max );
			this.speed.dir.x  = ( this.speed.hitory[0].x - this.speed.hitory[2].x ) < 0 ? -1 : 1;
			this.speed.dir.y  = ( this.speed.hitory[0].y - this.speed.hitory[2].y ) < 0 ? -1 : 1;
			this.speed.tween = gsap.to( this.speed.pow1, {
				duration : this.speed.duration,
				x : this.speed.pow0.x * this.speed.dir.x,
				y : this.speed.pow0.y * this.speed.dir.y,
			});
		}
	},

	onResetTweens(){
		if( this.speed.tween ){
			this.speed.tween.kill();
		}
	},

	onComplete(){
		this.onResetTweens();
		this.speed.tween = gsap.to( this.speed.pow1, {
			duration : this.speed.duration,
			x : 0,
			y : 0
		});
		this.active = false;
	},

	onDestroy(){

		//
		clearTimeout( this.timer );
		if( this.touch.event ){ this.touch.event.kill(); }
		if( this.mouse.event ){ this.mouse.event.kill(); }
		this.onResetTweens();
		this.speed.hitory = [];
		this.speed.pow0.x = 0;
		this.speed.pow0.y = 0;
		this.speed.pow1.x = 0;
		this.speed.pow1.y = 0;

		//
		this.speed.delta.x  = 0;
		this.speed.delta.y  = 0;
		this.scroll.x = 0;
		this.scroll.y = 0;
		this.scroll.delta.x = 0;
		this.scroll.delta.y = 0;
		this.scroll.left = 0;
		this.scroll.top  = 0;

		//
		this.body.el = null;
		this.ready = false;
		this.active = false;

		//
		this.array.forEach( (v) =>{
			v.events.el.removeEventListener('click',v.events.click.func);
			v.events.el.removeEventListener('mouseenter',v.events.mouseenter.func);
			v.events.el.removeEventListener('mouseleave',v.events.mouseleave.func);
			if( DETECT.device.any ){
				v.events.el.removeEventListener('touchstart',v.events.mousedown.func, {passive: true});
				v.events.el.removeEventListener('touchend',v.events.mouseup.func, {passive: true});
			} else {
				v.events.el.removeEventListener('mousedown',v.events.mousedown.func);
				v.events.el.removeEventListener('mouseup',v.events.mouseup.func);
			}
		});
		this.array = [];

		//
		this.wrap = null;
		this.li = null;

	},

/*
███╗   ███╗ ██████╗ ██╗   ██╗███████╗███████╗    ███████╗██╗   ██╗███████╗███╗   ██╗████████╗
████╗ ████║██╔═══██╗██║   ██║██╔════╝██╔════╝    ██╔════╝██║   ██║██╔════╝████╗  ██║╚══██╔══╝
██╔████╔██║██║   ██║██║   ██║███████╗█████╗      █████╗  ██║   ██║█████╗  ██╔██╗ ██║   ██║
██║╚██╔╝██║██║   ██║██║   ██║╚════██║██╔══╝      ██╔══╝  ╚██╗ ██╔╝██╔══╝  ██║╚██╗██║   ██║
██║ ╚═╝ ██║╚██████╔╝╚██████╔╝███████║███████╗    ███████╗ ╚████╔╝ ███████╗██║ ╚████║   ██║
╚═╝     ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝    ╚══════╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝   ╚═╝
*/

	onMouseStart(){

		if( this.body.el.dataset.scrollType != 'archive' ) return false;

		//
		$html.classList.add('is-dragging');

		//
		this.mouse.ratio.x = this.mouse.ratio.r;
		this.mouse.ratio.x = this.mouse.ratio.r * window.innerHeight/window.innerWidth;

		//
		if( this.touch.event ){ this.touch.event.kill(); }
		if( this.mouse.event ){ this.mouse.event.kill(); }
		this.mouse.start.delta.x = this.scroll.delta.x;
		this.mouse.start.delta.y = this.scroll.delta.y;
		this.speed.pow0.x  = 0;
		this.speed.pow0.y  = 0;
		this.speed.delta.x = 0;
		this.speed.delta.y = 0;

	},

	onMouseMove(){

		const _this = this;

		//===
		this.scroll.delta.x = ( mouseEvent.dist.x * this.mouse.ratio.x + this.mouse.start.delta.x );
		this.scroll.delta.y = ( mouseEvent.dist.y * this.mouse.ratio.y + this.mouse.start.delta.y );

		//===
		this.speed.delta.x += mouseEvent.dist.x * this.mouse.ratio.x;
		this.speed.delta.y += mouseEvent.dist.y * this.mouse.ratio.y;

		//===
		this.mouse.dir.x = mouseEvent.dist.x > 0 ? 1:-1;
		this.mouse.dir.y = mouseEvent.dist.y > 0 ? 1:-1;

		//===
		this.mouse.buffer.x = window.innerWidth * 0.2;
		this.mouse.buffer.y = window.innerHeight * 0.2;

		//===
		this.getSpeed( 0.001 );

		//===
		this.active = true;


	},

	onMouseEnd(){

		const _this = this;

		$html.classList.remove('is-dragging');

		const powX = this.mouse.buffer.x * this.speed.pow1.x * this.mouse.dir.x;
		const powY = this.mouse.buffer.y * this.speed.pow1.y * this.mouse.dir.y;
		this.speed.pow0.x = 0;
		this.speed.pow0.y = 0;
		this.mouse.event = gsap.to( this.scroll.delta, {
			duration : 1,
			ease  : 'power2.out',
			x : '+='+powX,
			y : '+='+powY
		});

		//
		clearTimeout( this.timer );
		this.timer = setTimeout( ()=>{
			_this.onComplete();
		}, this.wait );

	},




/*
████████╗ ██████╗ ██╗   ██╗ ██████╗██╗  ██╗    ███████╗██╗   ██╗███████╗███╗   ██╗████████╗
╚══██╔══╝██╔═══██╗██║   ██║██╔════╝██║  ██║    ██╔════╝██║   ██║██╔════╝████╗  ██║╚══██╔══╝
   ██║   ██║   ██║██║   ██║██║     ███████║    █████╗  ██║   ██║█████╗  ██╔██╗ ██║   ██║
   ██║   ██║   ██║██║   ██║██║     ██╔══██║    ██╔══╝  ╚██╗ ██╔╝██╔══╝  ██║╚██╗██║   ██║
   ██║   ╚██████╔╝╚██████╔╝╚██████╗██║  ██║    ███████╗ ╚████╔╝ ███████╗██║ ╚████║   ██║
   ╚═╝    ╚═════╝  ╚═════╝  ╚═════╝╚═╝  ╚═╝    ╚══════╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝   ╚═╝
*/


	onTouchStart(){
		// this.touch.ratio.x = this.touch.ratio.r;
		// this.touch.ratio.y = this.touch.ratio.r * window.innerHeight/window.innerWidth;
		if( this.touch.event ){ this.touch.event.kill(); }
		if( this.mouse.event ){ this.mouse.event.kill(); }
		this.touch.start.delta.x = this.scroll.delta.x;
		this.touch.start.delta.y = this.scroll.delta.y;
		this.speed.delta.x = 0;
		this.speed.delta.y = 0;
		this.speed.pow0.x = 0;
		this.speed.pow0.y = 0;
	},
	onTouchMove(){

		const _this = this;

		//===
		const deltaY = touchEvent.dist.y * this.touch.ratio.y * Math.abs( 1 + this.speed.pow0.y ) + this.touch.start.delta.y;
		const deltaX = touchEvent.dist.x * this.touch.ratio.x * Math.abs( 1 + this.speed.pow0.x ) + this.touch.start.delta.x;

		//===
		this.speed.delta.x += touchEvent.dist.x * this.touch.ratio.x;
		this.speed.delta.y += touchEvent.dist.y * this.touch.ratio.y;

		//===
		this.touch.dir.x = touchEvent.dist.x > 0 ? 1:-1;
		this.touch.dir.y = touchEvent.dist.y > 0 ? 1:-1;

		//===
		this.touch.buffer.x = window.innerWidth * 1.5;
		this.touch.buffer.y = window.innerHeight * 1.5;

		//===
		// this.getSpeed( 0.001 );

		//===
		this.active = true;

		//===
		this.scroll.delta.x = deltaX;
		this.scroll.delta.y = deltaY;

	},
	onTouchEnd(){

		const _this = this;

		//===
		// const powX = this.touch.buffer.x * this.speed.pow1.x * this.touch.dir.x;
		// const powY = this.touch.buffer.y * this.speed.pow1.y * this.touch.dir.y;
		// this.touch.event = gsap.to( this.scroll.delta, {
		// 	duration : 1.5,
		// 	ease  : 'power2.out',
		// 	x : '+='+powX,
		// 	y : '+='+powY
		// });

		//===
		clearTimeout( this.timer );
		this.timer = setTimeout( ()=>{
			_this.onComplete();
		}, this.wait );

	},


	onKeyDown( code ){
		switch( code ){
			case 'ArrowDown':
				this.scroll.delta.y += __WW__*0.15;
			break;
			case 'ArrowUp':
				this.scroll.delta.y -= __WW__*0.15;
			break;
			case 'ArrowRight':
				this.scroll.delta.x += __WW__*0.15;
			break;
			case 'ArrowLeft':		
				this.scroll.delta.x -= __WW__*0.15;
			break;
			case 'Space':
				this.scroll.delta.y += __WH__;
			break;
		}
	}


}






























