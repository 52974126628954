/*
 ██████╗██╗      █████╗ ███████╗███████╗
██╔════╝██║     ██╔══██╗██╔════╝██╔════╝
██║     ██║     ███████║███████╗███████╗
██║     ██║     ██╔══██║╚════██║╚════██║
╚██████╗███████╗██║  ██║███████║███████║
 ╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝ office
*/
import vert from '~/assets/js/shader/vert/animals.vert';

const OFFICE_OBJECTS = {

	items: {
		group : new THREE.Group(),

		//
		position: { x: 0, y: -0.32, z: 10, },
		rotation: { x: 0, y: 0, z: 0 },
		scale: 0.4,

		//
		color : { css : '#b2a592', three : new THREE.Color('#b2a592') },
		emissive : { css : '#181615', three : new THREE.Color('#181615') },
		roughness: 1,
		metalness: 0.2,
		reflectivity : 0,
		clearcoat: 1,
		clearcoatRoughness: 0.8,
		opacity: 1,
	},

	// floor: {
	// 	group : new THREE.Group(),

	// 	//
	// 	position: { x: 0, y: -0.8, z: 10, },
	// 	rotation: { x: 0, y: 0, z: 0 },
	// 	scale: 0.1,

	// 	//
	// 	color : { css : '#000000', three : new THREE.Color('#000000') },
	// 	emissive : { css : '#000000', three : new THREE.Color('#000000') },
	// 	roughness: 0.0,
	// 	metalness: 0.1,
	// 	reflectivity : 0.1,
	// 	specularIntensity: 0.1,
	// 	clearcoat: 0.2,
	// 	clearcoatRoughness: 0.0,
	// 	opacity: 1,
	// },

	floor: {
		group : new THREE.Group(),

		//
		position: { x: 0, y: -0.28, z: 10, },
		rotation: { x: 0, y: 0, z: 0 },
		scale: 0.4,

		//
		color : { css : '#ffffff', three : new THREE.Color('#ffffff') },
		emissive : { css : '#000000', three : new THREE.Color('#000000') },
		roughness: 1,
		metalness: 0.1,
		reflectivity : 0.8,
		clearcoat: 0.8,
		clearcoatRoughness: 0.0,
		opacity: 1,
	},

	desk: {
		group : new THREE.Group(),

		//
		position: { x: 0, y: -0.27, z: 10, },
		rotation: { x: 0, y: 0, z: 0 },
		scale: 0.4,

		//
		color : { css : '#ffffff', three : new THREE.Color('#ffffff') },
		emissive : { css : '#000000', three : new THREE.Color('#000000') },
		roughness: 1,
		metalness: 0.3,
		reflectivity : 0.8,
		clearcoat: 0.0,
		clearcoatRoughness: 0.8,
		opacity: 1,
	},

	// chair: {
	// 	group : new THREE.Group(),

	// 	//
	// 	scale: 0.4,
	// 	position: { x: 1.6, y: -0.99, z: 2.2, },
	// 	rotation: { x: 0, y: 10, z: 0 },

	// 	//
	// 	color : { css : '#000000', three : new THREE.Color('#000000') },
	// 	emissive : { css : '#000000', three : new THREE.Color('#000000') },
	// 	roughness: 0.8,
	// 	metalness: 0.4,
	// 	reflectivity : 0.1,
	// 	specularIntensity: 0.1,
	// 	clearcoat: 1.0,
	// 	clearcoatRoughness: 1.0,
	// 	opacity: 1,

	// }

}

const CLONE_OBJECTS = {

	// [ RESOURCE.animals.rabbit ] からcloneしてくる
	// rabbit: {
	// 	mesh : null,
	// 	position: { x: 2.8, y: 0.4, z: 2.2, },
	// 	rotation: { x: -90, y: 0, z:-38 },
	// 	scale: 0.2,

	// 	//
	// 	color : { css : '#f00', three : new THREE.Color('#f00') },
	// 	emissive : { css : '#000000', three : new THREE.Color('#000000') },
	// 	roughness: 0.9,
	// 	metalness: 0.1,
	// 	reflectivity : 0.0,
	// 	specularIntensity: 0.0,
	// 	clearcoat: 1,
	// 	clearcoatRoughness: 0,
	// },

	// [ RESOURCE.items.homeFooterPlants ] からcloneしてくる
	// homeFooterPlants: {
	// 	mesh : null,
	// 	position: { x: -1.5, y: -0.5, z: 3, },
	// 	rotation: { x: -90, y: 0, z: 90 },
	// 	scale: 0.2,

	// 	//
	// 	color : { css : '#6c6c6c', three : new THREE.Color('#6c6c6c') },
	// 	emissive : { css : '#210200', three : new THREE.Color('#210200') },
	// 	roughness: 0.8,
	// 	metalness: 0.1,
	// 	reflectivity : 0.2,
	// 	specularIntensity: 0.2,
	// 	clearcoat: 0.9,
	// 	clearcoatRoughness: 0.0,
	// },

	// [ RESOURCE.items.desktop ] からcloneしてくる
	desktop : [
		// {
		// 	group : new THREE.Group(),
		// 	scale: 0.14,
		// 	position: { x: -2.12, y: 0.23, z: -0.7, },
		// 	rotation: { x: 0, y: 180, z: 0 }
		// },
		// {
		// 	group : new THREE.Group(),
		// 	scale: 0.14,
		// 	position: { x: -2.12, y: 0.23, z: -5.2, },
		// 	rotation: { x: 0, y: 180, z: 0 }
		// },
		// {
		// 	group : new THREE.Group(),
		// 	scale: 0.14,
		// 	position: { x: 2.1, y: 0.23, z: -2.5, },
		// 	rotation: { x: 0, y: 180, z: 0 }
		// },
		// {
		// 	group : new THREE.Group(),
		// 	scale: 0.14,
		// 	position: { x: 2.1, y: 0.23, z: -7, },
		// 	rotation: { x: 0, y: 180, z: 0 }
		// },
		// {
		// 	group : new THREE.Group(),
		// 	scale: 0.14,
		// 	position: { x: 2.1, y: 0.23, z: -10, },
		// 	rotation: { x: 0, y: 180, z: 0 }
		// },
		// {
		// 	group : new THREE.Group(),
		// 	scale: 0.14,
		// 	position: { x: 3, y: 0.23, z: 3.3, },
		// 	rotation: { x: 0, y: -90, z: 0 }
		// }
	]

}

const LIGHTS = [
	{
		type : 'point',
		position : { x: 2, y: 3, z: 6},
		intensity: 10,
		distance: 50,
		penumbra : 1,
		decay: 2,
		mesh : null,
	},
	// {
	// 	type : 'point',
	// 	color: {
	// 		css : '#62bb6f',
	// 		three : new THREE.Color('#62bb6f'),
	// 	},
	// 	position : { x: -0.9, y: -4.9, z: -6.7},
	// 	intensity: 14,
	// 	distance: 10,
	// 	penumbra : 1,
	// 	decay: 2,
	// 	mesh : null,
	// },
	// {
	// 	type : 'point',
	// 	color: {
	// 		css : '#ac7941',
	// 		three : new THREE.Color('#ac7941'),
	// 	},
	// 	position : { x: 0, y: 2, z: -15},
	// 	intensity: 14,
	// 	distance: 10,
	// 	penumbra : 1,
	// 	decay: 2,
	// 	mesh : null,
	// },

	// //
	// {
	// 	type : 'spot',
	// 	color: {
	// 		css : '#ffffff',
	// 		three : new THREE.Color('#ffffff'),
	// 	},
	// 	position : { x: 0, y: 4.1, z: -20},
	// 	intensity: 4,
	// 	distance: 10,
	// 	penumbra : 1,
	// 	decay: 2,
	// 	mesh : null,
	// },

	// {
	// 	type : 'spot',
	// 	color: {
	// 		css : '#ffffff',
	// 		three : new THREE.Color('#ffffff'),
	// 	},
	// 	position : { x: 0, y: 4.1, z: -15},
	// 	intensity: 4,
	// 	distance: 10,
	// 	penumbra : 1,
	// 	decay: 2,
	// 	mesh : null,
	// },
	// {
	// 	type : 'spot',
	// 	color: {
	// 		css : '#ffffff',
	// 		three : new THREE.Color('#ffffff'),
	// 	},
	// 	position : { x: 0, y: 4.1, z: -10},
	// 	intensity: 4,
	// 	distance: 10,
	// 	penumbra : 1,
	// 	decay: 2,
	// 	mesh : null,
	// },
	// {
	// 	type : 'spot',
	// 	color: {
	// 		css : '#ffffff',
	// 		three : new THREE.Color('#ffffff'),
	// 	},
	// 	position : { x: 0, y: 4.1, z: -5},
	// 	intensity: 4,
	// 	distance: 10,
	// 	penumbra : 1,
	// 	decay: 2,
	// 	mesh : null,
	// },
	// {
	// 	type : 'spot',
	// 	color: {
	// 		css : '#ffffff',
	// 		three : new THREE.Color('#ffffff'),
	// 	},
	// 	position : { x: 0, y: 4.1, z: 0},
	// 	intensity: 4,
	// 	distance: 10,
	// 	penumbra : 1,
	// 	decay: 2,
	// 	mesh : null,
	// },
	// {
	// 	type : 'spot',
	// 	color: {
	// 		css : '#ffffff',
	// 		three : new THREE.Color('#ffffff'),
	// 	},
	// 	position : { x: 0, y: 4.1, z: 15},
	// 	intensity: 4,
	// 	distance: 10,
	// 	penumbra : 1,
	// 	decay: 2,
	// 	mesh : null,
	// }
]



const WALLS = [
	{
		mesh : null,
		position : { x : 3.4, y : 1.7, z : 0 },
		rotation : { x : 0, y : -90, z : 0 },
		scale : { x : 40, y : 8 },
	},
	{
		mesh : null,
		position : { x : -3.4, y : 1.7, z : 0 },
		rotation : { x : 0, y : 90, z : 0 },
		scale : { x : 40, y : 8 },
	},
	{
		mesh : null,
		position : { x : 0, y : 5, z : 0 },
		rotation : { x : 90, y : 0, z : 90 },
		scale : { x : 40, y : 8 },
	}
]



export class SCENE_OFFICE {

	constructor( rw, rh ){

		//
		this.scene = new THREE.Scene();
		// this.scene.background = COLOR.red.three;

		this.ratio = rh/rw;

		//
		this.width  = window.innerWidth;
		this.height = window.innerHeight;

		//
		this.ratioWidth  = this.width * 0.5;
		this.ratioHeight = this.ratioWidth * this.ratio;
		this.scale = RESCALE.r * 0.33 * 0.1;

		//
		this.fov = 30;
		this.camera = new THREE.PerspectiveCamera( this.fov, this.width / this.height, 1, this.width );
		this.camera.position.z = 0;
		this.scene.add( this.camera );
		this.scene.fog = new THREE.Fog( COLOR.officeFog.three, 0, this.width );
		this.scene.background = this.scene.fog.color;

		// this.controls = new OrbitControls( this.camera, BACKGL.renderer.bg.render.domElement );

		//
		this._RES = MID_RES;

		//
		this.fbo = new THREE.WebGLRenderTarget(
			this.ratioWidth * this._RES,
			this.ratioHeight * this._RES,
			{ minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter }
		);

		// //
		// this.anim = {
		// 	origin : {
		// 		intensity : 1,
		// 		fog : {
		// 			near : -10,
		// 			far  :  30,
		// 		}
		// 	},
		// 	on : {
		// 		intensity : 1,
		// 		fog : {
		// 			near : -10,
		// 			far  :  30,
		// 		}
		// 	},
		// 	off : {
		// 		intensity : 0.5,
		// 		fog : {
		// 			near : -60,
		// 			far  :  30,
		// 		}
		// 	},
		// }

		//
		this.stageOffice = new THREE.Group();
		this.scene.add( this.stageOffice );
		this.offceObj = { ... OFFICE_OBJECTS };
		this.offceObjKeys = Object.keys( this.offceObj );
		this.cloneObj = { ... CLONE_OBJECTS };
		this.cloneObjKeys = Object.keys( this.cloneObj );

		//
		this.stageLights = new THREE.Group();
		this.scene.add( this.stageLights );
		this.lignts = [ ... LIGHTS ];

		//
		this.stageWalls = new THREE.Group();
		this.scene.add( this.stageWalls );
		this.walls = [ ... WALLS ];

		//
		this.initOffce();
		this.initClone();
		this.initWall();
		this.initLight();

		//
		// this.switchOff();

		//
		this.resize();

	}

	initOffce(){

		for (let i = 0; i < this.offceObjKeys.length; i++) {

			//
			const key = this.offceObjKeys[i];
			const target = RESOURCE.office[ key ];
			const each = this.offceObj[ key ];
			this.stageOffice.add( each.group );

			//
			for (let n = 0; n < target.meshes.length; n++) {
				const mesh = target.meshes[n].mesh;
				each.group.add( mesh );

				//
				mesh.rotation.x = each.rotation.x * ( Math.PI / 180 );
				mesh.rotation.y = each.rotation.y * ( Math.PI / 180 );
				mesh.rotation.z = each.rotation.z * ( Math.PI / 180 );

				//
				if( each.emissive.three ) mesh.material.emissive = each.emissive.three;
				if( each.roughness ) mesh.material.roughness = each.roughness;
				if( each.metalness ) mesh.material.metalness = each.metalness;
				if( each.clearcoat ) mesh.material.clearcoat = each.clearcoat;
				if( each.clearcoatRoughness ) mesh.material.clearcoatRoughness = each.clearcoatRoughness;
				if( each.reflectivity ) mesh.material.reflectivity = each.reflectivity;

			}

		}

	}

	initClone(){

		for (let i = 0; i < this.cloneObjKeys.length; i++) {

			//
			const key = this.cloneObjKeys[i];
			const each = this.cloneObj[ key ];
			let target = null;

			switch( key ){
				case 'rabbit':

					target = RESOURCE.animals[ key ];
					if( target.geometry && target.material ){

						//
						each.mesh = new THREE.Mesh( target.geometry.clone(), target.material.clone() );

						//==========================
						// 20240710
						//==========================
						// each.mesh.castShadow = true;
						each.mesh.castShadow = false;
						each.mesh.receiveShadow = false;

						//
						this.stageOffice.add( each.mesh );

						//
						each.mesh.rotation.x = each.rotation.x * ( Math.PI / 180 );
						each.mesh.rotation.y = each.rotation.y * ( Math.PI / 180 );
						each.mesh.rotation.z = each.rotation.z * ( Math.PI / 180 );

						//
						if( each.color.three ) each.mesh.material.emissive.three = each.color.three;
						if( each.emissive.three ) each.mesh.material.emissive.three = each.emissive.three;
						if( each.roughness ) each.mesh.material.roughness = each.roughness;
						if( each.metalness ) each.mesh.material.metalness = each.metalness;
						if( each.clearcoat ) each.mesh.material.clearcoat = each.clearcoat;
						if( each.clearcoatRoughness ) each.mesh.material.clearcoatRoughness = each.clearcoatRoughness;
						if( each.reflectivity ) each.mesh.material.reflectivity = each.reflectivity;

					}

				break;
				case 'homeFooterPlants':

					target = RESOURCE.items[ key ];
					if( target.geometry && target.material ){

						//
						each.mesh = new THREE.Mesh( target.geometry.clone(), target.material.clone() );
						//==========================
						// 20240710
						//==========================
						// each.mesh.castShadow = true;
						each.mesh.castShadow = false;
						each.mesh.receiveShadow = false;
						this.stageOffice.add( each.mesh );

						//
						each.mesh.rotation.x = each.rotation.x * ( Math.PI / 180 );
						each.mesh.rotation.y = each.rotation.y * ( Math.PI / 180 );
						each.mesh.rotation.z = each.rotation.z * ( Math.PI / 180 );

						//
						if( each.color.three ) each.mesh.material.emissive.three = each.color.three;
						if( each.emissive.three ) each.mesh.material.emissive.three = each.emissive.three;
						if( each.roughness ) each.mesh.material.roughness = each.roughness;
						if( each.metalness ) each.mesh.material.metalness = each.metalness;
						if( each.clearcoat ) each.mesh.material.clearcoat = each.clearcoat;
						if( each.clearcoatRoughness ) each.mesh.material.clearcoatRoughness = each.clearcoatRoughness;
						if( each.reflectivity ) each.mesh.material.reflectivity = each.reflectivity;

					}

				break;
				case 'desktop':

					// var target = RESOURCE.items[ key ];

					const array = each;

					for (let n = 0; n < array.length; n++) {

						target = RESOURCE.items['desktop'];

						for (let m = 0; m < target.meshes.length; m++) {
							const mesh = target.meshes[m].clone();
	
							//==========================
							// 20240710
							//==========================
							// mesh.castShadow = true;
							mesh.castShadow = false;
							mesh.receiveShadow = false;

							array[n].group.add( mesh );

						}
						this.stageOffice.add( array[n].group );

						//
						array[n].group.rotation.x = array[n].rotation.x * ( Math.PI / 180 );
						array[n].group.rotation.y = array[n].rotation.y * ( Math.PI / 180 );
						array[n].group.rotation.z = array[n].rotation.z * ( Math.PI / 180 );

					}


				break;

			}

		}

	}

	initLight(){

		//
		this.amblight = new THREE.AmbientLight( COLOR.officeFog, 1 );
		this.scene.add( this.amblight );

		// 20240710
		this.amblight.castShadow = false;

		//
		for (let i = 0; i < this.lignts.length; i++) {
			const v = this.lignts[i];
			switch( v.type ){
				case 'point':
					v.mesh = new THREE.PointLight( COLOR.officePointLight.three );
					v.mesh.intensity = v.intensity;
					v.mesh.penumbra = v.penumbra;
					v.mesh.decay = v.decay;
					v.mesh.angle = Math.PI/2.5;

					// 20240710
					v.mesh.castShadow = false;

					this.stageLights.add( v.mesh );
				break;
				case 'spot':
					v.mesh = new THREE.SpotLight( COLOR.officePointLight.three );
					v.mesh.intensity = v.intensity;
					v.mesh.penumbra = v.penumbra;
					v.mesh.decay = v.decay;
					v.mesh.angle = Math.PI/2.5;

					// 20240710
					v.mesh.castShadow = false;

					this.stageLights.add( v.mesh );
				break;
			}
		}

	}

	initWall(){
		const material1= new THREE.MeshPhongMaterial({ color: 0xcccccc, emissive: 0x000000, specular: 0x000000 })
		const material2 = new THREE.MeshPhongMaterial({ color: 0x000000, emissive: 0x000000, specular: 0x000000 })
		for (let i = 0; i < this.walls.length; i++) {
			const v = this.walls[i];
			const geometry = new THREE.PlaneBufferGeometry(1,1);
			if(i === 2){
				v.mesh = new THREE.Mesh( geometry, material2 );
			}else{
				v.mesh = new THREE.Mesh( geometry, material1 );
			}
			v.mesh.rotation.x = v.rotation.x * ( Math.PI / 180 );
			v.mesh.rotation.y = v.rotation.y * ( Math.PI / 180 );
			v.mesh.rotation.z = v.rotation.z * ( Math.PI / 180 );
			// v.mesh.receiveShadow = true;
			this.stageWalls.add( v.mesh );
		}
	}

	resize(){

		//
		this.width  = window.innerWidth;
		this.height = window.innerHeight;
		this.ratioWidth  = this.width * 0.5;
		this.ratioHeight = this.ratioWidth * this.ratio;

		//
		this.stageOffice.position.z = -10 * this.scale;
		this.stageLights.position.z = -10 * this.scale;
		this.stageWalls.position.z  = -10 * this.scale;

		//
		if( this.scene.fog ){
			// this.scene.fog.near = this.anim.origin.fog.near * this.scale;
			// this.scene.fog.far  = this.anim.origin.fog.far * this.scale;
			this.scene.fog.near = -10 * this.scale;
			this.scene.fog.far  =  30 * this.scale;
		}

		//
		this.camera.far    = 40 * this.scale + Math.abs(this.stageWalls.position.z);
		this.camera.aspect = this.ratioWidth / this.ratioHeight;
		this.camera.position.z = 0;
		this.camera.updateProjectionMatrix();

		//
		for (let i = 0; i < this.offceObjKeys.length; i++) {

			//
			const key = this.offceObjKeys[i];
			const target = RESOURCE.office[ key ];
			const each = this.offceObj[ key ];

			//
			for (let n = 0; n < target.meshes.length; n++) {
				const mesh = target.meshes[n].mesh;
				mesh.scale.x = this.scale * each.scale;
				mesh.scale.y = this.scale * each.scale;
				mesh.scale.z = this.scale * each.scale;
				mesh.position.x = this.scale * each.position.x;
				mesh.position.y = this.scale * each.position.y;
				mesh.position.z = this.scale * each.position.z;
			}

		}


		//
		for (let i = 0; i < this.cloneObjKeys.length; i++) {

			//
			const key = this.cloneObjKeys[i];
			const each = this.cloneObj[ key ];
			let target = null;

			switch( key ){
				case 'rabbit':
					target = RESOURCE.animals[ key ];
					if( target.geometry && target.material ){
						each.mesh.scale.x = this.scale * each.scale;
						each.mesh.scale.y = this.scale * each.scale;
						each.mesh.scale.z = this.scale * each.scale;
						each.mesh.position.x = this.scale * each.position.x;
						each.mesh.position.y = this.scale * each.position.y;
						each.mesh.position.z = this.scale * each.position.z;
					}
				break;
				case 'homeFooterPlants':
					target = RESOURCE.items[ key ];
					if( target.geometry && target.material ){
						each.mesh.scale.x = this.scale * each.scale;
						each.mesh.scale.y = this.scale * each.scale;
						each.mesh.scale.z = this.scale * each.scale;
						each.mesh.position.x = this.scale * each.position.x;
						each.mesh.position.y = this.scale * each.position.y;
						each.mesh.position.z = this.scale * each.position.z;
					}
				break;
				case 'desktop':
					const array = each;
					for (let n = 0; n < array.length; n++) {
						array[n].group.scale.x = this.scale * array[n].scale;
						array[n].group.scale.y = this.scale * array[n].scale;
						array[n].group.scale.z = this.scale * array[n].scale;
						array[n].group.position.x = this.scale * array[n].position.x;
						array[n].group.position.y = this.scale * array[n].position.y;
						array[n].group.position.z = this.scale * array[n].position.z;
					}
				break;
			}

		}

		//
		for (let i = 0; i < this.lignts.length; i++) {
			const v = this.lignts[i];
			if( v.mesh ){
				switch( v.type ){
					case 'point':
						v.mesh.distance = v.distance * this.scale;
						v.mesh.position.x = v.position.x * this.scale;
						v.mesh.position.y = v.position.y * this.scale;
						v.mesh.position.z = v.position.z * this.scale;
					break;
					case 'spot':
						v.mesh.distance = v.distance * this.scale;
						v.mesh.position.x = v.position.x * this.scale;
						v.mesh.position.y = v.position.y * this.scale;
						v.mesh.position.z = v.position.z * this.scale;
					break;
				}
			}
		}

		//
		for (let i = 0; i < this.walls.length; i++) {
			const v = this.walls[i];
			v.mesh.position.x = v.position.x * this.scale;
			v.mesh.position.y = v.position.y * this.scale;
			v.mesh.position.z = v.position.z * this.scale;
			v.mesh.scale.x    = v.scale.x * this.scale;
			v.mesh.scale.y    = v.scale.y * this.scale;
		}
	}

	inview( visible ){

		for (let i = 0; i < this.offceObjKeys.length; i++) {

			//
			const key = this.offceObjKeys[i];
			const target = RESOURCE.office[ key ];
			const each = this.offceObj[ key ];			
			for (let n = 0; n < target.meshes.length; n++) {
				const mesh = target.meshes[n].mesh;
				mesh.visible = visible;
			}

		}


		//
		for (let i = 0; i < this.cloneObjKeys.length; i++) {

			//
			const key = this.cloneObjKeys[i];
			const each = this.cloneObj[ key ];
			let target = null;

			switch( key ){
				case 'rabbit':
					target = RESOURCE.animals[ key ];
					if( target.geometry && target.material ){
						each.mesh.visible = visible;
					}
				break;
				case 'homeFooterPlants':
					target = RESOURCE.items[ key ];
					if( target.geometry && target.material ){
						each.mesh.visible = visible;
					}
				break;
				case 'desktop':
					const array = each;
					for (let n = 0; n < array.length; n++) {
						array[n].group.visible = visible;
					}
				break;
			}

		}

		//
		for (let i = 0; i < this.lignts.length; i++) {
			const v = this.lignts[i];
			if( v.mesh ){
				switch( v.type ){
					case 'point':
						v.mesh.visible = visible;
					break;
					case 'spot':
						v.mesh.visible = visible;
					break;
				}
			}
		}

		//
		for (let i = 0; i < this.walls.length; i++) {
			const v = this.walls[i];
			v.mesh.visible = visible;
		}

	}

	render( y ){

		//
		this.camera.position.x = -this.scale * mouseEvent.anims.normal.cx * 0.3;
		this.camera.position.z = -y * this.scale * 2;
		this.camera.position.y = 1 * this.scale - y * this.scale;

	}

}
