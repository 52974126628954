/*
██████╗  █████╗  ██████╗██╗  ██╗ ██████╗ ██╗     
██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔════╝ ██║     
██████╔╝███████║██║     █████╔╝ ██║  ███╗██║     
██╔══██╗██╔══██║██║     ██╔═██╗ ██║   ██║██║     
██████╔╝██║  ██║╚██████╗██║  ██╗╚██████╔╝███████╗
╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝ DOM
*/



window.onLoadDomGLTexture = function( dom, path ){

	const _path = path ? path : '';

	//
	if( !dom.poster.tex ){

		const _s = 1.2;
		const _d = ROUTE.current.type === 'archive' ? dom.index * ANIM.contents.fade.d : 0;
		const scale = ROUTE.current.type === 'archive' ? { x : 1.2, y : 1.2 } : { x : 1, y : 1 };

		switch( dom.mediaType ){
			case 'video':
		
				// console.log('---------------------------------------------');
				// console.log( dom.mediaType, _path + dom.poster.src );

				//===
				dom.poster.loader = new THREE.TextureLoader();
				dom.poster.tex = dom.poster.loader.load( _path + dom.poster.src );
				dom.poster.tex.magFilter = THREE.LinearFilter;
				dom.poster.tex.minFilter = THREE.LinearFilter;
				dom.mesh.material.uniforms.tPoster.value = dom.poster.tex;

				//===
				dom.loader = true;
				if( dom.el.querySelector('video') ){

					dom.video.el = dom.el.querySelector('video');
					dom.video.el.preload = "metadata";
					dom.video.el.muted = true;
					dom.video.el.loop = true;
					dom.tex = new THREE.VideoTexture( dom.video.el );
					dom.mesh.material.uniforms.tDiffuse.value = dom.tex;
					// console.log( dom.video.el );

				} else {

					dom.video.el = document.createElement('video');
					const src = getVideoSrc( dom.el.querySelector('.bg-img').dataset );
					dom.video.el.src = _path + src;
					dom.video.el.preload = "metadata";
					dom.video.el.muted = true;
					dom.video.el.loop = true;
					dom.tex = new THREE.VideoTexture( dom.video.el );
					dom.mesh.material.uniforms.tDiffuse.value = dom.tex;

				}

				const tl1 = gsap.to( dom.mesh.material.uniforms.scale.value, {
					x : scale.x,
					y : scale.y,
					delay    : _d,
					duration : ANIM.contents.scale.s,
					ease     : ANIM.contents.scale.e
				});
				dom.tweens.push(tl1);

				const tl2 = gsap.to( dom.mesh.material.uniforms.mixRatio, {
					value : 1,
					delay : _d,
					duration : ANIM.contents.fade.s,
					ease     : ANIM.contents.fade.e
				});
				dom.tweens.push(tl2);

			break;
			case 'image':

				if( dom.poster.src != 'false' ){

					dom.poster.loader = new THREE.TextureLoader();
					dom.poster.loader.load(

						_path + dom.poster.src,

						function ( poster ) {

							// console.log( dom.mediaType, _path + dom.poster.src );

							//===
							dom.poster.tex = poster;
							dom.poster.tex.magFilter = THREE.LinearFilter;
							dom.poster.tex.minFilter = THREE.LinearFilter;
							if( dom.mesh && dom.mesh.material ){

								//===
								dom.mesh.material.uniforms.tPoster.value = dom.poster.tex;
								const tl0 = gsap.to( dom.mesh.material.uniforms.mixPreload, {
									value : 1,
									duration : ANIM.contents.fade.s,
									ease     : ANIM.contents.fade.e
								});
								dom.tweens.push(tl0);

								//===
								clearTimeout( dom.timer );
								dom.timer = setTimeout( function(){
									const src = getImageSrc( _path + dom.img.d1x, _path + dom.img.d2x, _path + dom.img.mob, true );
									dom.loader = new THREE.TextureLoader();
									dom.loader.load(
										src,
										function ( texture ) {
											dom.tex = texture;
											const tl1 = gsap.to( dom.mesh.material.uniforms.scale.value, {
												x : scale.x,
												y : scale.y,
												delay : _d,
												duration : ANIM.contents.scale.s,
												ease     : ANIM.contents.scale.e
											});
											dom.tweens.push(tl1);
											const tl2 = gsap.to( dom.mesh.material.uniforms.mixRatio, {
												value : 1,
												delay : _d,
												duration : ANIM.contents.fade.s,
												ease     : ANIM.contents.fade.e
											});
											dom.tweens.push(tl2);
											dom.mesh.material.uniforms.tDiffuse.value = dom.tex;
										}
									);
								}, 500);

							}

						}
					);

				} else {

					const src = getImageSrc( _path + dom.img.d1x, _path + dom.img.d2x, _path + dom.img.mob, true );
					dom.loader = new THREE.TextureLoader();
					dom.loader.load(
						src,
						function ( texture ) {
							dom.tex = texture;
							const tl1 = gsap.to( dom.mesh.material.uniforms.scale.value, {
								x : scale.x,
								y : scale.y,
								delay : _d,
								duration : ANIM.contents.scale.s,
								ease     : ANIM.contents.scale.e
							});
							dom.tweens.push(tl1);
							dom.mesh.material.uniforms.mixRatio.value = 1;
							dom.mesh.material.uniforms.tDiffuse.value = dom.tex;
						}
					);

				}

			break;
		}

	}

}





// import { CanvasTextTexture } from '/assets/js/webgl/class/c-canvas-text.js';
BACKGL.scenes.dom = {

	visible : false,
	body : { el : null },

	setZIndex(){
		// //
		// if( this.backSide ) this.backSide.position.z = 2; // Partyより前
		// if( this.frontSide ) this.frontSide.position.z = 100;
	},

	onInit(){

		//
		this.width  = window.innerWidth;
		this.height = window.innerHeight;

		//
		this.onInitGl();
		this.onResize();

		//
		// this.onDestroy();

	},

	onIniview( dom ){

		if( dom.mesh ){

			console.log( dom.name );

			switch( dom.name ){
				case 'image':
				case 'video':
				case 'backgroundImage':
					if( !IS_REDUCED && !DETECT.device.any ) this.onInviewContent2D( dom );
				break;
				// case '3d':
				// 	this.onInviewContent3D( dom );
				// break;
				case 'archive':
					this.onInviewArchive( dom );
				break;
				case 'background':
					this.onInviewBackground( dom );
				break;
				// case 'text':
				// 	this.onInviewText( dom );
				// break;
				//
				default:
					dom.mesh.visible = dom.visible;
				break;
			}
		}
	},

	onInitGl(){

		console.log('BACKGL.scenes.dom.onInitGl();');

		// //===
		// for (let i = 0; i < BACKGL.domElements.length; i++) {
		// 	var dom = BACKGL.domElements[i];
		// 	this.removeEvents(dom);
		// }

		//===
		for (let i = 0; i < BACKGL.domElements.length; i++) {

			const dom = BACKGL.domElements[i];

			switch( dom.name ){
				case 'background':
					if( !DETECT.device.any ) this.onInitBackground( dom );
				break;
				case 'image':
				case 'video':
				case 'backgroundImage':
					if( !DETECT.device.any ) this.onInitContent2D( dom );
				break;
				// case '3d':
				// 	this.onInitContent3D( dom );
				// 	is3dModelLen++;
				// break;
				case 'archive':
					this.onInitArchive( dom );
				break;
				// case 'text':
				// 	this.onInitTextBefore( dom );
				// break;
				// case 'pagging':
				// 	this.onInitTextBefore( dom );
				// break;
				// case 'cases':
				// 	this.onInitTextBefore( dom );
				// break;
			}
		}

		//===
		if( this.light ) BACKGL.sceneFrontSide.remove( this.light );

	},

/*
██████╗ ███████╗███████╗██╗███████╗███████╗
██╔══██╗██╔════╝██╔════╝██║╚══███╔╝██╔════╝
██████╔╝█████╗  ███████╗██║  ███╔╝ █████╗  
██╔══██╗██╔══╝  ╚════██║██║ ███╔╝  ██╔══╝  
██║  ██║███████╗███████║██║███████╗███████╗
╚═╝  ╚═╝╚══════╝╚══════╝╚═╝╚══════╝╚══════╝
*/
	onResize(){

		console.log('BACKGL.scenes.dom.onResize');

		//
		this.width  = window.innerWidth;
		this.height = window.innerHeight;

		//
		for (let i = 0; i < BACKGL.domElements.length; i++) {
			const dom = BACKGL.domElements[i];
			if( dom.mesh ){

				switch( dom.name ){
					case 'background':
						if( !DETECT.device.any ) this.onResizeBackground(dom);
					break;
					case 'image':
					case 'video':
					case 'backgroundImage':
						if( !DETECT.device.any ) this.onResizeContent2D( dom );
					break;
					// case '3d':
					// 	this.onResizeContent3D( dom );
					// break
					case 'archive':
						this.onReszieArchive( dom );
					break;
				}
			}
		}

	},

	onScroll(){

		//
		for (let i = 0; i < BACKGL.domElements.length; i++) {
			const dom = BACKGL.domElements[i];
			if( dom.mesh ){

				switch( dom.name ){
					case 'background':
						if( !DETECT.device.any ) this.onRenderBackground( dom );
					break;
					case 'image':
					case 'video':
					case 'backgroundImage':
						if( !DETECT.device.any ) this.onRenderContent2D( dom );
					break;
				}
			}
		}

	},

	onDestroy(){

		for (let i = 0; i < BACKGL.domElements.length; i++) {

			const dom = BACKGL.domElements[i];

			if( dom.mesh ){

				//
				clearTimeout( dom.timer );

				//
				dom.mesh.visible = false;

				//
				killTweens( dom.tweens );

				//
				if( dom.light ){
					BACKGL.sceneFrontSide.remove( dom.light );
					dom.light = null;
				}

				//
				dom.mesh.material.dispose();
				dom.mesh.geometry.dispose();
				dom.mesh.material = null;
				dom.mesh.geometry = null;

				//
				if( dom.tex ){
					dom.tex.dispose();
					dom.tex = null;
				}
				if( dom.loader ) dom.loader = null;

				//
				if( dom.poster.tex ){
					dom.poster.tex.dispose();
					dom.poster.tex = null;
				}
				if( dom.poster.loader ) dom.poster.loader = null;

				//
				BACKGL.sceneFrontSide.remove( dom.mesh );
				dom.mesh = null;

			}

		}

	}

}














