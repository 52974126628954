module.exports = {
	baseUrl : '/',
	staticUrl : 'https://d1oo4riy5et4sg.cloudfront.net/v3',
	globalUrl : 'https://v3.garden-eight.com',
	cdnUrl    : 'https://d1oo4riy5et4sg.cloudfront.net/v3',
	cdnDomain : 'https://d1oo4riy5et4sg.cloudfront.net',
	s3Url     : 'https://gdn8.s3.ap-northeast-1.amazonaws.com/v3',
	s3Domain  : 'https://gdn8.s3.ap-northeast-1.amazonaws.com',
	dist      : 'dist/stg',
	pwa : true
}