/*
██████╗  ██████╗ ███╗   ███╗
██╔══██╗██╔═══██╗████╗ ████║
██║  ██║██║   ██║██╔████╔██║
██║  ██║██║   ██║██║╚██╔╝██║
██████╔╝╚██████╔╝██║ ╚═╝ ██║
╚═════╝  ╚═════╝ ╚═╝     ╚═╝ cases scroll
*/

window.styleCasesScroll = {

	//===
	infinite : true,
	mode     : 'vr',

	//===
	ready  : false,
	active : false,
	dir    : 0,

	//===
	timer  : null,
	wait   : 30,

	//===
	scroll : {

		wrap : {
			el : null,
		},

		num : {
			el : null,
		},

		body : {
			el : null,
			rect : null,
		},

		map : {
			el : null,
		},

		contents : {
			el : null,
			rect : null,
			before : null,
			after  : null,
		},

		//
		firstChild : {
			rect : {
				height : 0
			}
		},

		//
		sections : {
			el : null,
			elems : [],
		},

		//
		delta : 0,
		progress : 0,

		//
		x     : 0,
		y     : 0,
		top   : 0,
		left  : 0,

		//
		start : {
			x : 0,
			y : 0,
		},

		//
		ease  : 0.075,
		ratio : 100,

	},

	body : { el : null },

	onScrollTo(e){
		if( this.ready ){
		}
	},

	onInit( elem ){


		//===
		if( elem ){ this.body.el = elem.querySelector('.Pc') } else { this.body.el = document.querySelector('.Pc'); }

		//
		if( this.body.el.dataset.scrollType != 'case') return false; 

		//
		if( DETECT.os.win ){
			this.scroll.ease  = 0.125;
			this.scroll.ratio = 120;
		}

		if( DETECT.touch ){
			this.scroll.ease  = 0.125/2;
		}

		// if( DETECT.device.any ){
		// 	this.scroll.ratio = __WH__;
		// }

		//
		this.scroll.body.el = this.body.el.querySelector('.cases-scroll');
		// this.scroll.num.el = this.body.el.querySelector('.slide-scroll-progress');
		// this.scroll.map.el = this.body.el.querySelectorAll('.slide-scroll-map-li');

		//
		if( this.scroll.body.el ){

			//
			this.scroll.contents.el = this.body.el.querySelector('.cases-ul');
			this.setIntersect();

			//
			this.scroll.start.y = -__WH__/2 + this.scroll.firstChild.rect.height/2;
			this.scroll.delta = this.scroll.start.y;
			this.scroll.x = 0;
			this.scroll.y = this.scroll.start.y;

			//
			this.onResize();

			//
			this.ready = true;

		} else {

			this.ready = false;

		}

		//===
		if( !DETECT.device.any ){
			window.removeEventListener('wheel',onWheel );
			window.addEventListener('wheel',onWheel,{ passive: false });
		}

	},

	setIntersect(){

		//===
		this.scroll.sections.el = this.body.el.querySelectorAll('.cases-li');
		this.scroll.sections.elems = [];
		for (let i = 0; i < this.scroll.sections.el.length; i++) {

			const el     = this.scroll.sections.el[i];
			const $pivot = el.querySelector('.pivot');
			const $a     = el.querySelector('.cases-a');

			if( i != 0 ) el.classList.add('is-shown');

			//===
			let rect = el.getBoundingClientRect();
			if( $pivot ){
				rect = $pivot.getBoundingClientRect();
			}

			//===
			let powX = Number(el.dataset.powx);
			let powY = Number(el.dataset.powy);
			this.scroll.sections.elems.push({

				index   : Number(el.dataset.index),
				postId  : el.dataset.postId,

				el      : el,
				pivot   : $pivot,

				h1 : el.querySelector('.cases-h1'),

				x       : 0,
				y       : 0,

				visible : false,
				rect    : rect,

				pow     : {
					x   : powX,
					y   : powY,
				},

				thumb : {
					el : el.querySelector('.thumb'),
					rect : el.querySelector('.thumb').getBoundingClientRect(),
					img : null,
				},

				anim : {
					mesh : {
						x   : 0,
						y   : 0,
					},
					offset : {
						x   : 0,
						y   : 0,
					},
					center : {
						x   : 0,
						y   : 0,
					},
					scale : 0,
					deg : 0,
				}

			});
		}

		//===
		this.scroll.firstChild = {
			el : this.scroll.sections.el[0],
			rect : this.scroll.sections.el[0].getBoundingClientRect()
		};

	},

	onDestroy(){


		//
		this.onResetTweens();
		this.speed.tween = gsap.to( this.speed, {
			duration : this.speed.duration,
			pow1 : 0
		});

		//
		this.scroll.start.y = -__WH__/2 + this.scroll.firstChild.rect.height/2;
		this.scroll.delta = this.scroll.start.y;
		this.scroll.x = 0;
		this.scroll.y = this.scroll.start.y;
		// this.scroll.top = this.scroll.contents.rect.height + this.scroll.y % this.scroll.contents.rect.height;
		// this.scroll.progress = ( this.scroll.top % this.scroll.contents.rect.height / this.scroll.contents.rect.height );

		//
		this.ready = false;
		this.scroll.sections.elems = [];
		this.scroll.firstChild = { rect : { height : 0 } };


	},

	onResetScroll(){

		this.scroll.start.y = -__WH__/2 + this.scroll.firstChild.rect.height/2;
		this.scroll.delta = this.scroll.start.y;
		this.scroll.x = 0;
		this.scroll.y = this.scroll.start.y;
		// this.scroll.top = this.scroll.contents.rect.height + this.scroll.y % this.scroll.contents.rect.height;
		// this.scroll.x = 0;
		// this.scroll.y = 0;

	},

	onResize(){

		const _this = this;

		//===
		this.scroll.body.rect     = this.scroll.body.el.getBoundingClientRect();
		this.scroll.contents.rect = this.scroll.contents.el.getBoundingClientRect();

		//===
		this.onResetTweens();
		this.onResetScroll();

		//===
		for (let i = 0; i < this.scroll.sections.elems.length; i++) {

			//
			const v = this.scroll.sections.elems[i];

			v.y  = 0;
			v.el.style.transform = "translate3d(0, 0, 0)";

			//===
			let rect = v.el.getBoundingClientRect();
			const pivot = v.el.querySelectorAll('.pivot');
			if( pivot.length ){
				rect = pivot[0].getBoundingClientRect();
			}

			//===
			v.rect = rect;
			v.x = this.scroll.left;
			v.y = this.scroll.top;

			//===
			v.el.style.transform = "translate3d("+ -v.x +"px, "+ -v.y +"px, 0)";

		}
	},

	//===============================
	// WHEEL
	//===============================
	onWheel(e){

		// $html.classList.add('is-scrolling');

		const _this = this;
		this.scroll.delta += -getWheelDistY(e) * this.scroll.ratio;

		//===
		this.speed.delta += getWheelDistY(e);
		// this.getSpeed(0.75);

		//===========================
		// 控えめに 2022/11/10
		//===========================
		this.getSpeed(0.375);

		//===
		this.active = true;


		// //===
		// for (let i = 0; i < this.scroll.sections.elems.length; i++) {
		// 	clearTimeout( this.scroll.sections.elems[i].events.timer );
		// 	// $html.classList.remove('is-projects-hover');
		// 	// this.scroll.sections.elems[i].el.classList.remove('is-hover');
		// }


		//===
		clearTimeout( this.timer );
		this.timer = setTimeout( ()=>{
			_this.onComplete();
		}, this.wait );

	},

	//===============================
	// KEY
	//===============================
	onKeyDown(){

		gsap.to( this.scroll, {
			duration : .3,
			delta    : '+=' + __WH__,
			ease     : 'power2.inOut',
		});

	},

	//===
	speed  : {
		delta  : 0,
		hitory : [],
		max    : 4,
		tween  : null,
		pow0   : 0,
		pow1   : 0,
		pow2   : 0,
		dir    : 1,
		duration : 2.5
	},


	//===============================
	// SPEED
	//===============================
	getSpeed( ratio = 1 ){

		//===
		if( this.speed.hitory.length > 2 ){
			this.speed.hitory.shift();
			this.speed.hitory.push( this.speed.delta );
		} else {
			this.speed.hitory.push( this.speed.delta );
		}

		//
		if( this.speed.hitory.length > 2 ){
			this.speed.pow0 = Math.min( Math.abs( (this.speed.hitory[0] - this.speed.hitory[2]) * ratio ) , this.speed.max );
			this.speed.dir = (this.speed.hitory[0] - this.speed.hitory[2]) < 0 ? -1 : 1;
			this.speed.tween = gsap.to( this.speed, {
				duration : this.speed.duration,
				pow1 : this.speed.pow0 * this.speed.dir,
			});
		}

	},


	onResetTweens(){
		if( this.speed.tween ){
			this.speed.tween.kill();
		}
	},

	onComplete(){

		//
		// $html.classList.remove('is-scrolling');

		//
		this.onResetTweens();

		//		
		this.speed.tween = gsap.to( this.speed, {
			duration : this.speed.duration,
			pow1 : 0
		});

		//
		this.active = false;

	},


	deg : 15,

	//===============================
	// RENDER
	//===============================
	lastTime : 0,
	onUpdate(){

		//===
		// BACKGL.onScroll( 'cases', this.speed );

		//===
		const currentTime = performance.now() * .001;
		const deltaTime   = currentTime - this.lastTime;
		const alpha = Math.exp( -this.scroll.ease * 85 * deltaTime );
		this.scroll.y = lerp( this.scroll.y, this.scroll.delta, alpha );
		this.lastTime = currentTime;

		//===
		// this.scroll.y += ( this.scroll.delta - this.scroll.y ) * this.scroll.ease;

		//===
		this.scroll.top = this.scroll.y % this.scroll.contents.rect.height;
		this.scroll.progress = ( this.scroll.top % this.scroll.contents.rect.height / this.scroll.contents.rect.height );

		//===
		const progress = this.scroll.y/this.scroll.contents.rect.height;
		const loop = Math.floor( progress );

		//
		if( !IS_LOW_SPEC ) styleUI.onProgress( loop, progress - loop );

		//===
		for (let i = 0; i < this.scroll.sections.elems.length; i++) {

			//
			const v = this.scroll.sections.elems[i];
			// const _visible = parseInt( v.el.dataset.visible );
			// const _min = ( v.rect.top + v.rect.height ) + ( -this.scroll.top );
			// const _max = this.scroll.top + __WH__;

			//
			// if( ( v.rect.top < _max && _min > 0 ) || _visible === 1 ){

				v.y = this.scroll.top*-1;

				//===========================
				// 上リピート
				//===========================
				if( v.y < -v.rect.height - v.rect.top ){
					v.y = v.y + this.scroll.contents.rect.height;
				}

				//===========================
				// 下リピート
				//===========================
				if( v.rect.top - (v.y*-1) - __WH__ > 0 ){
					v.y = v.y - this.scroll.contents.rect.height;
				}

				//
				const _min = v.y + v.rect.top + (v.rect.height) > 0;
				const _max = v.y + v.rect.top - (v.rect.height) < __WH__;
				if( _min && _max ){

					// if( i === 0 ){

						//=================
						// 湾曲
						//=================
						if( !IS_REDUCED && !DETECT.device.any ){

							const y = v.y + v.rect.top;
							const cy = Math.sin( ( ( y + this.scroll.firstChild.rect.height/2 ) / __WH__ - 0.5 ) ); // -1 ~ 0 ~ 1
							const scale   = ( 1 - Math.abs( cy * 0.1 ) ) + Math.abs( this.speed.pow1 * 0.1 );
							const rotate  = (-cy * 60) * Math.abs( this.speed.pow1 );
							const originY = (50 - 50 * cy) * Math.abs( this.speed.pow1 * 0.1 );
							v.h1.style.transform = "translate3d(0%, "+ (-10 * cy) +"%, 0) rotateX("+ rotate +"deg) scale("+ scale +")";
							v.h1.style.transformOrigin = "50% "+ originY*2 +"%";

							// //=======================
							// // THUMBNAIL パララックス
							// //=======================
							// v.thumb.img = v.thumb.el.querySelector('video,img');
							// if( v.thumb.img ){
							// 	var ty = cy * v.thumb.rect.height * 0.7;
							// 	v.thumb.img.style.transform = 'translate3d(0px,'+ ty +'px,0px)';
							// }

							// var originY = 50 - 50 * Math.abs(cy);
							// v.el.style.transform = "translate3d(0px, "+ v.y +"px, 0) rotateX("+ rotate +"deg) scale("+ scale +")";
							// v.el.style.transformOrigin = "50% "+ originY +"%";

						}

						v.el.style.transform = "translate3d(0px, "+ v.y +"px, 0)";


					// } else {
					// 	v.el.style.transform = "translate3d(0px, "+ v.y +"px, 0)";
					// }

					v.visible = true;

				} else {

					if( v.y != -9999 ) v.y = -9999; v.el.style.transform = "translate3d(0px, "+ v.y +"px, 0)";
					v.visible = false;

				}



			// } else {
			// 	v.visible = false;
			// }

		}

	},

	mouse : {
		ratio : 1,
		start : {
			delta : 0,
		}
	},
	onMouseStart(){
		this.mouse.start.delta = this.scroll.delta;
		this.speed.pow0 = 0;
		this.speed.delta = 0;
	},
	onMouseMove(){
		this.scroll.delta = ( mouseEvent.dist.y * this.mouse.ratio + this.mouse.start.delta );
		this.speed.delta += mouseEvent.dist.y * this.mouse.ratio;
		this.active = true;
	},
	onMouseEnd(){
		this.active = false;
	},


	touch : {
		ratio : 2,
		start : {
			delta : 0,
		}
	},
	onTouchStart(){
		this.touch.start.delta = this.scroll.delta;
		this.speed.pow0 = 0;
		this.speed.delta = 0;
	},
	onTouchMove(){
		this.scroll.delta = ( touchEvent.dist.y * this.touch.ratio + this.touch.start.delta );
		this.speed.delta  += touchEvent.dist.y * this.touch.ratio;
		this.active = true;
	},
	onTouchEnd(){
		this.active = false;
	},

	onKeyDown( code ){
		switch( code ){
			case 'ArrowRight':
			case 'ArrowDown':
				this.scroll.delta += 10;
			break;
			case 'ArrowUp':
			case 'ArrowLeft':
				this.scroll.delta -= 10;
			break;
			case 'Space':
				this.scroll.delta += __WH__;
			break;
		}
	}


}
































