/*
 ██████╗██╗      █████╗ ███████╗███████╗
██╔════╝██║     ██╔══██╗██╔════╝██╔════╝
██║     ██║     ███████║███████╗███████╗
██║     ██║     ██╔══██║╚════██║╚════██║
╚██████╗███████╗██║  ██║███████║███████║
 ╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝ ground
*/

// import ground3D from '~/assets/js/shader/frag/ground3D_r137.frag';
import ground3D from '~/assets/js/shader/frag/ground3D_r133_v2.frag';
export class SCENE_GROUND {

	constructor( useScreen = false, useFbo = false ){

		//
		this.useScreen = useScreen;
		this.useFbo = useFbo;

		//
		this.width  = window.innerWidth;
		this.height = window.innerHeight;

		//
		this._RES = MID_RES;

		//
		if( this.useFbo ){
			this.fbo = new THREE.WebGLRenderTarget(
				this.width  * this._RES,
				this.height * this._RES,
				{ minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter }
			);
		}

		//
		this.scene = new THREE.Scene();
		this.fog = { color : COLOR_ORIGIN.black.three, near : 2.5, far  : 20 };
		this.scene.fog = new THREE.Fog( this.fog.color, this.fog.near * RESCALE.r, this.fog.far * RESCALE.r );
		this.scene.background = this.fog.color;

		//
		this.fov = 15;
		this.camera = new THREE.PerspectiveCamera( this.fov, this.width / this.height, this.fog.near * RESCALE.r, this.fog.far * RESCALE.r );
		this.camera.position.z = this.width;
		this.scene.add( this.camera );

		//
		const plantsUniforms = {
			color : {
				css : '#E2B071',
				three : new THREE.Color('#E2B071')
			},
			emissive : {
				css : '#111',
				three : new THREE.Color('#111')
			},
			roughness: 1,
			metalness: 0.9,
			reflectivity: 0,
			clearcoat: 0,
			clearcoatRoughness: 0.3,
			opacity: 1
		};
		const plantsUniforms2 = {
			color : {
				css : '#E0A65E',
				three : new THREE.Color('#E0A65E')
			},
			emissive : {
				css : '#111',
				three : new THREE.Color('#111')
			},
			roughness: 1,
			metalness: 0.9,
			reflectivity: 0,
			clearcoat: 0,
			clearcoatRoughness: 0.3,
			opacity: 1
		};
		const rubberUniforms = {
			color : {
				css : '#aaa',
				three : new THREE.Color('#aaa')
			},
			emissive : {
				css : '#111',
				three : new THREE.Color('#111')
			},
			roughness: 1,
			metalness: 0,
			reflectivity: 0,
			clearcoat: 0,
			clearcoatRoughness: 0,
			opacity: 1
		};
		const deviceUniforms = {
			roughness: 1,
			metalness: 0.2,
			reflectivity: 0,
			clearcoat: 0,
			clearcoatRoughness: 0,
			opacity: 1
		};

		//
		this.groups = {
			about8Group : {
				setting : {
					camera : {
						fov : 30,
						position : {
							x : 3,
							y : 0,
							z : 12
						}
					},
					fog : {
						near : 12,
						far  : 15,
					},
					lights : [
						{ // 上から
							position: { x: -4, y: 40, z: 12 },
							target: { x: 0, y: 0, z: 0 },
							color : {
								css : '#6d6f70',
								three : new THREE.Color('#6d6f70')
							},
							angle : 1.,
							penumbra : 1,
							decay : 0,
							power : 0,
							intensity : 8,
							distance: 50,
							// castShadow : true,
							castShadow : false, // 20240710
						},
						{ // 正面
							position: { x: 12, y: -3, z: 20 },
							target: { x: 0, y: 0, z: 0 },
							color : {
								css : '#E0835B',
								three : new THREE.Color('#E0835B')
							},
							angle : .8,
							penumbra : 1,
							decay : 0.1,
							power : 0,
							intensity : 1,
							distance: 30,
							// castShadow : false,
							castShadow : false, // 20240710
						},
						{ // 左から
							position: { x: -8, y: -3, z: 12 },
							target: { x: 0, y: 0, z: 0 },
							color : {
								css : '#E4955C',
								three : new THREE.Color('#E4955C')
							},
							angle : .8,
							penumbra : 1,
							decay : 0.1,
							power : 0,
							intensity : 2,
							distance: 30,
							// castShadow : false,
							castShadow : false, // 20240710
						}
					]
				},
				keys : [],
				visible : false,

				//
				stage : new THREE.Group(),
				stagePosition: { x: 0, y: 0, z: 0 },
				stageRotation: { x: 10, y: -10, z: 0 },

				//
				ground : null,
				groundTexKey : 'ground_about_eight',

				//
				each : {
					about8Plants : {
						// castShadow : true,
						castShadow : false, // 20240710
						meshes : [],
						position: { x: 0, y: -0.5, z: 0, },
						rotation: { x: -90, y: 0, z: 0 },
						scale: 0.3,
						uniforms : plantsUniforms
					},
					eight : {
						// castShadow : true,
						castShadow : false, // 20240710
						meshes : [],
						position: { x: 0, y: 0.4, z: -1 },
						rotation: { x: -90, y: 0, z: 0 },
						scale: 0.3,
						anim : {
							speed : 0.5,
							deg : 0,
							rad : 0,
							sin : 0,
							position : {
								y : 0.2,
							}
						},
						uniforms : {
							color : {
								css : '#945739',
								three : new THREE.Color('#945739')
							},
							roughness: 0.7,
							metalness: 0.1,
							reflectivity: 0,
							clearcoat: 0.02,
							clearcoatRoughness: 0.2,
							opacity: 1,
						}
					}
				}
			},

			aboutFooterGroup : {
				setting : {
					camera : {
						fov : 30,
						position : {
							x : 0,
							y : 0,
							z : 10.5,
						}
					},
					fog : {
						near : 8,
						far  : 16,
					},
					lights : [
						{ // 上から
							position: { x: -4, y: 24, z: 14 },
							target: { x: 0, y: 0, z: 0 },
							color : {
								css : '#6d6f70',
								three : new THREE.Color('#6d6f70')
							},
							angle : 1.,
							penumbra : 1,
							decay : 0.1,
							power : 0,
							intensity : 9,
							distance: 50,
							// castShadow : true,
							castShadow : false, // 20240710
						},
						{ // 正面
							position: { x: 12, y: -1, z: 20 },
							target: { x: 0, y: 3, z: 0 },
							color : {
								css : '#E0835B',
								three : new THREE.Color('#E0835B')
							},
							angle : .8,
							penumbra : 1,
							decay : 0.2,
							power : 0,
							intensity : 1,
							distance: 30,
							// castShadow : false,
							castShadow : false, // 20240710
						},
						{ // 左から
							position: { x: -8, y: -1, z: 12 },
							target: { x: 0, y: 2, z: 0 },
							color : {
								css : '#E4955C',
								three : new THREE.Color('#E4955C')
							},
							angle : .8,
							penumbra : 1,
							decay : 0.1,
							power : 0,
							intensity : 2,
							distance: 30,
							// castShadow : false,
							castShadow : false, // 20240710
						}
					]
				},
				keys : [],
				visible : false,

				//
				stage : new THREE.Group(),
				stagePosition: { x: 0, y: 0, z: 0 },
				stageRotation: { x: 0, y: 0, z: 0 },

				//
				ground : null,
				groundTexKey : 'ground_about_footer',

				//
				each : {
					aboutFooterPlants : {
						// castShadow : true,
						castShadow : false, // 20240710
						meshes : [],
						position: { x: 0.0, y: -0.6, z: 0, },
						rotation: { x: -90, y: 0, z: 0 },
						scale: 0.3,
						uniforms : plantsUniforms2
					},
					desktop : {
						clone : true,
						// castShadow : true,
						castShadow : false, // 20240710
						meshes : [],
						position: { x: 0, y: -0.3, z: 0.2, },
						rotation: { x: -3, y: -5, z: -10 },
						scale: 0.3,
						anim : {
							speed : 0.4,
							deg : 30,
							rad : 0,
							sin : 0,
							position : {
								y : 0.1,
							}
						},
						uniforms : deviceUniforms
					},
					tablet : {
						clone : true,
						// castShadow : true,
						castShadow : false, // 20240710
						meshes : [],
						position: { x: -2, y: 0.5, z: -0.2, },
						rotation: { x: -15, y: 5, z: 15 },
						scale: 0.3,
						anim : {
							speed : 0.3,
							deg : 180,
							rad : 0,
							sin : 0,
							position : {
								y : 0.2,
							}
						},
						uniforms : deviceUniforms
					},
					phone : {
						clone : true,
						// castShadow : true,
						castShadow : false, // 20240710
						meshes : [],
						position: { x: 1.8, y: 0.0, z: 1.2, },
						rotation: { x: -15, y: 10, z: -20 },
						scale: 0.3,
						anim : {
							speed : 0.2,
							deg : 0,
							rad : 0,
							sin : 0,
							position : {
								y : 0.2,
							}
						},
						uniforms : deviceUniforms
					},
					eyes : {
						// castShadow : false,
						castShadow : false, // 20240710
						meshes : [],
						position: { x: 0.83, y: -0.4, z: 2.5, },
						rotation: { x: 90, y: 0, z: 0 },
						scale: 0.11,
						uniforms : {
							roughness: 1,
							metalness: 0.4,
							reflectivity: 0.5,
							clearcoat: 0.2,
							clearcoatRoughness: 0.2,
							opacity: 1,
						}
					},

				}
			},

			homeFooterGroup : {

				setting : {
					camera : {
						fov : 30,
						position : {
							x : 0,
							y : 0,
							z : 10,
						}
					},
					fog : {
						near : 10.1,
						far  : 12,
					},
					lights : [
						{ // 上から
							position: { x: -3, y: 25, z: 4 },
							target: { x: 0, y: 0, z: 1 },
							color : {
								css : '#6d6f70',
								three : new THREE.Color('#6d6f70')
							},
							angle : 1,
							penumbra : .2,
							decay : .3,
							power : 0,
							intensity : 10,
							distance : 50,
							// castShadow : true,
							castShadow : false, // 20240710
						}
					]
				},

				keys : [],
				visible : false,

				//
				stage : new THREE.Group(),
				stagePosition: { x: 0, y: 0, z: 0 },
				stageRotation: { x: 0, y: 0, z: 0 },

				//
				ground : null,
				groundTexKey : 'ground_home_footer',

				//
				each : {
					homeFooterPlants : {
						// castShadow : true,
						castShadow : false, // 20240710
						meshes : [],
						position: { x: 0.1, y: -0.1, z: -1.7, },
						rotation: { x: -90,  y: 0, z: -15 },
						scale: 0.3,
						uniforms : plantsUniforms
					},
					desktop : {
						clone : true,
						// castShadow : true,
						castShadow : false, // 20240710
						meshes : [],
						position: { x: -0.1, y: -0.5, z: -0.4, },
						rotation: { x: -25, y: -20, z: -40 },
						scale: 0.3,
						anim : {
							speed : 0.3,
							deg : 0,
							rad : 0,
							sin : 0,
							position : {
								y : 0.1,
							}
						},
						uniforms : deviceUniforms
					},
					phone : {
						clone : true,
						// castShadow : true,
						castShadow : false, // 20240710
						meshes : [],
						position: { x: -1.2, y: -0.45, z: 0.8, },
						rotation: { x: -15, y: -16.2, z: -3 },
						scale: 0.3,
						anim : {
							speed : 0.3,
							deg : 120,
							rad : 0,
							sin : 0,
							position : {
								y : 0.07,
							}
						},
						uniforms : deviceUniforms
					},
					frame : {
						// castShadow : true,
						castShadow : false, // 20240710
						meshes : [],
						position: { x: 0.0, y: -1, z: -0.1, },
						rotation: { x: 0, y: -15, z: -0.5 },
						scale: 0.45,
						uniforms : rubberUniforms
					},
					rabbit : {
						category : 'animals',
						// castShadow : true,
						castShadow : false, // 20240710
						meshes : [],
						position: { x: 0.8, y: -0.4, z: 2.7, },
						rotation: { x: -90, y: 0, z: -18 },
						scale: 0.45,
						uniforms : {
							roughness: 1,
							metalness: 0.2,
							reflectivity : 0.1,
							clearcoat: 0.1,
							clearcoatRoughness: 0.2,
							opacity: 1,
						}
					},
					lizard : {
						category : 'animals',
						// castShadow : true,
						castShadow : false, // 20240710
						meshes : [],
						position: { x: -0.7, y: 0.3, z: 0, },
						rotation: { x: -60,  y: 16, z: -240 },
						scale: 0.15,
						uniforms : rubberUniforms
					}


				}
			}

		}
		this.keys = Object.keys( this.groups );

		//
		this.lightGroup = new THREE.Group();
		this.lights = [
			{
				mesh : null,
				type : 'spot'
			},
			{
				mesh : null,
				type : 'spot'
			},
			{
				mesh : null,
				type : 'spot'
			}
		];

		//
		this.initLight();
		this.initGroup();

		//
		this.useSetting( 'aboutFooterGroup' );

		//
		// this.reset();


	}

	reset(){

		//
		if( this.ground ) this.ground.visible = false;

		//
		for (let i = 0; i < this.keys.length; i++) {
			const key = this.keys[i];
			const v = this.groups[ key ];
			v.visible = false;
		}

	}

	initGroup(){
		for (let i = 0; i < this.keys.length; i++) {

			const key = this.keys[i];
			const v = this.groups[ key ];


			//======================
			// EACH
			//======================
			this.addEach( v );

			//======================
			// GROUND
			//======================
			this.addGround( v );

		}
	}

	addEach( v ){

		v.keys = Object.keys( v.each );
		for (let n = 0; n < v.keys.length; n++) {

			const k = v.keys[ n ]; // abcGroup
			const w = v.each[ k ]; // abcGroup.each.abc

			let material = null;
			let target = null;
			let mesh = null;

			//
			if( w.category === 'animals' ){

				target = RESOURCE.animals[ k ];
				material = new THREE.MeshPhysicalMaterial({
					// side : THREE.DoubleSide,
					map  : target.map
				});

				// material.color = w.uniforms.color ? w.uniforms.color.three : target.material.color;
				// material.emissive = w.uniforms.emissive ? w.uniforms.emissive.three : target.material.emissive;

				// マテリアル登録済み
				// var material = target.material.clone();
				// if( w.uniforms.color ) material.color = w.uniforms.color.three;
				// if( w.uniforms.emissive ) material.emissive = w.uniforms.emissive.three;
				if( w.uniforms.roughness ) material.roughness = w.uniforms.roughness;
				if( w.uniforms.metalness ) material.metalness = w.uniforms.metalness;
				if( w.uniforms.clearcoat ) material.clearcoat = w.uniforms.clearcoat;
				if( w.uniforms.clearcoatRoughness ) material.clearcoatRoughness = w.uniforms.clearcoatRoughness;
				if( w.uniforms.reflectivity ) material.reflectivity = w.uniforms.reflectivity;
				mesh = new THREE.Mesh( target.geometry.clone(), material );
				mesh.castShadow = false;

				//==========================
				// 20240710
				//==========================
				mesh.receiveShadow = false;

				w.meshes.push( mesh );
				v.stage.add( mesh );

			} else {

				target = RESOURCE.items[ k ];

				if( target.mergeGeometry ){

					material = new THREE.MeshPhysicalMaterial({
						// side : THREE.DoubleSide
					});
					material.color    = w.uniforms.color ? w.uniforms.color.three : target.material.color;
					material.emissive = w.uniforms.emissive ? w.uniforms.emissive.three : target.material.emissive;
					if( w.uniforms.roughness ) material.roughness = w.uniforms.roughness;
					if( w.uniforms.metalness ) material.metalness = w.uniforms.metalness;
					if( w.uniforms.clearcoat ) material.clearcoat = w.uniforms.clearcoat;
					if( w.uniforms.clearcoatRoughness ) material.clearcoatRoughness = w.uniforms.clearcoatRoughness;
					if( w.uniforms.reflectivity ) material.reflectivity = w.uniforms.reflectivity;
					target.material.dispose();
					mesh = new THREE.Mesh( target.geometry, material );
					mesh.castShadow = false;
	
					//==========================
					// 20240710
					//==========================
					mesh.receiveShadow = false;

					w.meshes.push( mesh );
					v.stage.add( mesh );

				} else {

					for (let m = 0; m < target.meshes.length; m++) {

						mesh = target.meshes[m];

						if( w.clone ){

							let clone = mesh.clone();

							if( clone.name != 'display' ){
								clone.material.color = w.uniforms.color ? w.uniforms.color.three : clone.material.color;
								clone.material.emissive = w.uniforms.emissive ? w.uniforms.emissive.three : clone.material.emissive;
								if( w.uniforms.roughness ) clone.material.roughness = w.uniforms.roughness;
								if( w.uniforms.metalness ) clone.material.metalness = w.uniforms.metalness;
								if( w.uniforms.clearcoat ) clone.material.clearcoat = w.uniforms.clearcoat;
								if( w.uniforms.clearcoatRoughness ) clone.material.clearcoatRoughness = w.uniforms.clearcoatRoughness;
								if( w.uniforms.reflectivity ) clone.material.reflectivity = w.uniforms.reflectivity;
								clone.castShadow = false;
								//==========================
								// 20240710
								//==========================
								clone.receiveShadow = false;
							}

							w.meshes.push( clone );
							v.stage.add( clone );

						} else {

							mesh.material.color = w.uniforms.color ? w.uniforms.color.three : mesh.material.color;
							mesh.material.emissive = w.uniforms.emissive ? w.uniforms.emissive.three : mesh.material.emissive;
							if( w.uniforms.roughness ) mesh.material.roughness = w.uniforms.roughness;
							if( w.uniforms.metalness ) mesh.material.metalness = w.uniforms.metalness;
							if( w.uniforms.clearcoat ) mesh.material.clearcoat = w.uniforms.clearcoat;
							if( w.uniforms.clearcoatRoughness ) mesh.material.clearcoatRoughness = w.uniforms.clearcoatRoughness;
							if( w.uniforms.reflectivity ) mesh.material.reflectivity = w.uniforms.reflectivity;
							mesh.castShadow = false;

							//==========================
							// 20240710
							//==========================
							mesh.receiveShadow = false;

							//
							if( mesh.name === 'eight' ){
								mesh.geometry.center();
							}

							//
							w.meshes.push( mesh );
							v.stage.add( mesh );

						}

					}

				}

			}

		}

	}

	addGround( v ){

		//
		const tex = RESOURCE.textures[ v.groundTexKey ].tex;
		const geometry = new THREE.PlaneBufferGeometry( 1, 1 );
		const material = new THREE.MeshPhysicalMaterial({
			color : COLOR.ground.three,
			map : tex
		});

		//
		material.uniformsNeedUpdate = true;
		material.needsUpdate = true;
		material.onBeforeCompile = (shader) => {
			shader.uniforms.scale = { value : 0.5 };
			shader.uniforms.alphaGround = { value : 0 };
			shader.fragmentShader = ground3D;
			material.userData.shader = shader;
		};

		//
		v.ground = new THREE.Mesh( geometry, material );
		v.ground.rotation.x = - Math.PI / 2;
		v.ground.position.y = RESCALE.r * -1;
		v.ground.receiveShadow = false;
		v.stage.add( v.ground );

		//
		this.scene.add( v.stage );

	}

	initLight(){

		//
		// const ambient = new THREE.AmbientLight( 0xffffff, 0.9 );
		// this.scene.add( ambient );

		//
		for (let i = 0; i < this.lights.length; i++) {
			const v = this.lights[i];
			switch( v.type ){
				case 'spot':
					v.mesh = new THREE.SpotLight( 0xffffff );

					// 20240710
					v.mesh.castShadow = false;

					this.lightGroup.add( v.mesh );
				break;
				case 'point':
					v.mesh = new THREE.PointLight( 0xffffff );

					// 20240710
					v.mesh.castShadow = false;

					this.lightGroup.add( v.mesh );
				break;
			}
		}

		//
		this.scene.add( this.lightGroup );

	}

	useSetting( current ){
		if( this.current != current ){
			this.current = current;
			this.updateSetting();
		}
	}

	updateSetting(){

		//
		this.currentSetting = this.groups[ this.current ].setting;

		//
		if( this.camera ){
			const currentCamera = this.currentSetting.camera;
			this.camera.aspect = this.width / this.height;
			this.camera.fov = currentCamera.fov;
			this.camera.position.z = currentCamera.position.z * RESCALE.r;
			this.camera.updateProjectionMatrix();
		}

		//
		for (let i = 0; i < this.lights.length; i++) {
			const v = this.lights[i];
			const currentLight = this.currentSetting.lights[i];
			if( currentLight ){
				v.mesh.color = currentLight.color.three;
				v.mesh.position.x = currentLight.position.x * RESCALE.r;
				v.mesh.position.y = currentLight.position.y * RESCALE.r;
				v.mesh.position.z = currentLight.position.z * RESCALE.r;
				v.mesh.angle = currentLight.angle;
				v.mesh.penumbra = currentLight.penumbra;
				v.mesh.decay = currentLight.decay;
				v.mesh.power = currentLight.power;
				v.mesh.intensity = currentLight.intensity;
				v.mesh.distance = currentLight.distance * RESCALE.r;
				v.mesh.castShadow = false;
				v.mesh.target.position.x = currentLight.target.x * RESCALE.r;
				v.mesh.target.position.y = currentLight.target.y * RESCALE.r;
				v.mesh.target.position.z = currentLight.target.z * RESCALE.r;
				v.mesh.target.updateMatrixWorld();
			}
		}

		//
		if( this.scene.fog ){
			const currentFog = this.currentSetting.fog;
			this.scene.fog.near = currentFog.near * RESCALE.r;
			this.scene.fog.far  = currentFog.far  * RESCALE.r;
		}

	}

	resize(){

		//
		this.width  = window.innerWidth;
		this.height = window.innerHeight;

		//
		this.updateSetting();

		//
		if( this.screen ){
			this.screen.scale.x = this.width;
			this.screen.scale.y = this.height;
			this.screen.material.uniforms.resolution.value.x = this.width;
			this.screen.material.uniforms.resolution.value.y = this.height;
		}

		//
		for (let i = 0; i < this.keys.length; i++) {
			const key = this.keys[i];
			const v = this.groups[ key ];

			if( v.ground ){
				v.ground.scale.x    = RESCALE.r * 40;
				v.ground.scale.y    = RESCALE.r * 40;
				v.ground.position.y = RESCALE.r * -1;
			}

			if( v.stage ){
				v.stage.position.x = v.stagePosition.x * RESCALE.r;
				v.stage.position.y = v.stagePosition.y * RESCALE.r;
				v.stage.position.z = v.stagePosition.z * RESCALE.r;
				v.stage.rotation.x = v.stageRotation.x * ( Math.PI / 180 );
				v.stage.rotation.y = v.stageRotation.y * ( Math.PI / 180 );
				v.stage.rotation.z = v.stageRotation.z * ( Math.PI / 180 );
			}

			for (let n = 0; n < v.keys.length; n++) {
				const k = v.keys[n]; // abcGroup
				const w = v.each[ k ]; // abcGroup.meshes.abc
				for (let m = 0; m < w.meshes.length; m++) {
					w.meshes[m].scale.x = w.scale * RESCALE.r;
					w.meshes[m].scale.y = w.scale * RESCALE.r;
					w.meshes[m].scale.z = w.scale * RESCALE.r;
					w.meshes[m].position.x = w.position.x * RESCALE.r;
					w.meshes[m].position.y = w.position.y * RESCALE.r;
					w.meshes[m].position.z = w.position.z * RESCALE.r;
					w.meshes[m].rotation.x = w.rotation.x * ( Math.PI / 180 );
					w.meshes[m].rotation.y = w.rotation.y * ( Math.PI / 180 );
					w.meshes[m].rotation.z = w.rotation.z * ( Math.PI / 180 );
				}
			}

		}

	}

	render( y ){

		for (let i = 0; i < this.keys.length; i++) {

			const key = this.keys[i];
			const v = this.groups[ key ];

			for (let n = 0; n < v.keys.length; n++) {

				//
				const k = v.keys[n];
				const w = v.each[ k ];

				//
				for (let m = 0; m < w.meshes.length; m++) {
					w.meshes[m].visible = v.visible;
				}

				// GROUND
				if( v.ground ){
					v.ground.visible = v.visible;
					switch( key ){
						case 'about8Group':
							v.ground.position.x = -mouseEvent.anims.slow.sx * getPxReScale(1440) * 0.5;
						break;
						default:
							v.ground.position.x = -mouseEvent.anims.slow.sx * getPxReScale(1440);
						break;
					}
					if( v.ground.material.userData.shader ) v.ground.material.userData.shader.uniforms.alphaGround.value = 1 - mouseEvent.anims.slow.sx * 0.7;
				}

				//
				if( !IS_REDUCED ){
					let floatY = 0;
					let floatR = 0;
					// MODELS
					switch( this.current ){
						case 'about8Group':
							switch( k ){
								case 'eight':

									//
									w.anim.deg = ( w.anim.deg <= 360 ) ? w.anim.deg += w.anim.speed : 0;
									w.anim.rad = w.anim.deg * (Math.PI / 180);
									w.anim.sin = Math.sin( w.anim.rad );

									//
									floatY = w.anim.sin * RESCALE.r * w.anim.position.y + RESCALE.r * w.anim.position.y /2;
									floatR = w.anim.rad;
									for (let m = 0; m < w.meshes.length; m++) {
										var mesh = w.meshes[m];
										mesh.position.y = w.position.y * RESCALE.r + floatY;
										w.meshes[m].rotation.z = w.rotation.z * ( Math.PI / 180 ) + w.anim.rad

									}
								break;
							}
						break;
						default:
							switch( k ){
								case 'eyes':

									for (let m = 0; m < w.meshes.length; m++) {
										const mesh = w.meshes[m];
										switch( mesh.name ){
											case 'white':
												mesh.position.x = w.position.x * RESCALE.r + ( mesh.scale.x * 0.25 *  mouseEvent.anims.slow.cx);
												mesh.position.y = w.position.y * RESCALE.r + ( mesh.scale.y * 0.25 * -mouseEvent.anims.slow.cy);
											break;
											case 'black':
												mesh.position.x = w.position.x * RESCALE.r + ( mesh.scale.x * 0.5 *  mouseEvent.anims.slow.cx);
												mesh.position.y = w.position.y * RESCALE.r + ( mesh.scale.y * 0.5 * -mouseEvent.anims.slow.cy);
											break;
										}
									}

								break;
								case 'desktop':

									//
									w.anim.deg = ( w.anim.deg <= 360 ) ? w.anim.deg += w.anim.speed : 0;
									w.anim.rad = w.anim.deg * (Math.PI / 180);
									w.anim.sin = Math.sin( w.anim.rad );

									//
									floatY = w.anim.sin * RESCALE.r * w.anim.position.y + RESCALE.r * w.anim.position.y /2;
									floatR = w.anim.rad;
									for (let m = 0; m < w.meshes.length; m++) {
										const mesh = w.meshes[m];
										mesh.position.y = w.position.y * RESCALE.r + floatY;
									}

								break;
								case 'tablet':

									//
									w.anim.deg = ( w.anim.deg <= 360 ) ? w.anim.deg += w.anim.speed : 0;
									w.anim.rad = w.anim.deg * (Math.PI / 180);
									w.anim.sin = Math.sin( w.anim.rad );

									//
									floatY = w.anim.sin * RESCALE.r * w.anim.position.y + RESCALE.r * w.anim.position.y /2;
									floatR = w.anim.rad;
									for (let m = 0; m < w.meshes.length; m++) {
										const mesh = w.meshes[m];
										mesh.position.y = w.position.y * RESCALE.r + floatY;
									}

								break;
								case 'phone':

									//
									w.anim.deg = ( w.anim.deg <= 360 ) ? w.anim.deg += w.anim.speed : 0;
									w.anim.rad = w.anim.deg * (Math.PI / 180);
									w.anim.sin = Math.sin( w.anim.rad );

									//
									floatY = w.anim.sin * RESCALE.r * w.anim.position.y + RESCALE.r * w.anim.position.y /2;
									floatR = w.anim.rad;
									for (let m = 0; m < w.meshes.length; m++) {
										const mesh = w.meshes[m];
										mesh.position.y = w.position.y * RESCALE.r + floatY;
									}

								break;
							}
						break;
					}
				}

			}

		}

		//
		if( this.lightGroup ){
			// console.log( this.lightGroup );
			this.lightGroup.position.x = mouseEvent.anims.slow.cx * getPxScale( this.width * 0.333 );
		}

		//
		if( this.camera ){
			const currentCamera = this.groups[ this.current ].setting.camera;
			if( !IS_REDUCED ){
				this.camera.position.x = currentCamera.position.x * RESCALE.r - __WW__ * mouseEvent.anims.slow.cx * 0.05;
				this.camera.rotation.z = 0.01 * ( Math.PI / 180 ) * mouseEvent.anims.slow.rotaion.z;
				switch( this.current ){
					case 'about8Group':
						this.camera.position.y = currentCamera.position.y * RESCALE.r + ( y * __WH__/2 ) * 0.5;
					break;
					default:
						this.camera.position.y = currentCamera.position.y * RESCALE.r + ( y * __WH__/2 ) * 0.5 * 0.5;
					break;
				}
			} else {
				this.camera.position.x = currentCamera.position.x * RESCALE.r;
			}
		}


	}


	inview( visible, name, visibleAny ){

		// //===
		// for (let i = 0; i < this.lights.length; i++) {
		// 	this.lights[i].mesh.visible = visibleAny;
		// }

		//===
		const v = this.groups[ name ];
		if( v ) v.visible = visible;

		//===
		if( ( name === 'aboutFooterGroup' && ROUTE.current.type === 'about' ) || ( name === 'homeFooterGroup' && ROUTE.current.type === 'index' ) ){
			if( visible ){
				this.playDisplayVideo( name );
			} else {
				this.pauseDisplayVideo( name );
			}
		}

	}

	playDisplayVideo( name ){
		for (let i = 0; i < this.keys.length; i++) {
			const key = this.keys[i];
			if( key === name ){
				const v = this.groups[ key ];
				for (let n = 0; n < v.keys.length; n++) {
					const k = v.keys[n];
					const w = v.each[ k ];
					if( k === 'desktop' || k === 'tablet' || k === 'phone' ){
						for (let m = 0; m < w.meshes.length; m++) {
							if( w.meshes[m].name === 'display' ){

								const mesh = w.meshes[m];
								const key = k +'_'+ ROUTE.current.type +'_default';
								const texture = RESOURCE.textures[ key ];

								if( mesh.material.uniforms.tDefault.value.uuid != texture.tex.uuid ){
									mesh.material.uniforms.tDefault.value = texture.tex;
								}

								if( texture.el.paused ){
									onPlayVideo( texture.el );
								}

							}
						}
					}
				}
			}
		}
	}

	pauseDisplayVideo( name ){
		for (let i = 0; i < this.keys.length; i++) {
			const key = this.keys[i];
			if( key === name ){
				const v = this.groups[ key ];
				for (let n = 0; n < v.keys.length; n++) {
					const k = v.keys[n];
					const w = v.each[ k ];
					if( k === 'desktop' || k === 'tablet' || k === 'phone' ){
						for (let m = 0; m < w.meshes.length; m++) {
							if( w.meshes[m].name === 'display' ){
								const mesh = w.meshes[m];
								const key = k +'_'+ ROUTE.current.type +'_default';
								const texture = RESOURCE.textures[ key ];
								if( !texture.el.paused ){
									texture.el.pause();
								}
							}
						}
					}
				}
			}
		}
	}



}
