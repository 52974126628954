export default {
	UPDATE_PAGE( state, page ) {
		state.page = page;
	},
	UPDATE_PROJECTS( state, data ){
		state.projects = data;
	},
	UPDATE_CASES_MOUSEDOWN( state, project ){
		project.action.mousedown = Date.now();
	},
	UPDATE_CASES_MOUSEUP( state, project ){
		project.action.mouseup = Date.now();
	},
	UPDATE_ARCHIVE( state, data ){
		state.archive = data;
	},
	UPDATE_UI_MENU( state, tf ){
		state.ui.menu.isopened = tf;
	},
	UPDATE_CURSOR( state, data ){
		state.ui.cursor = data;
	},
	UPDATE_THUMB( state, slug ){
		state.project_selected_slug = slug;
	},
	UPDATE_TIME( state, data ){
		state.time = data;
	},
	// UPDATE_TEAM_COUNT( state, data ){
	// 	for (var i = 0; i < state.team.length; i++) {
	// 		if( state.team[i].animal === data.name ){
	// 			if( data.isRight ){
	// 				state.team[i].count++;
	// 			} else {
	// 				state.team[i].count--;
	// 			}
	// 		}
	// 	}
	// },
};