/*
██████╗  █████╗  ██████╗██╗  ██╗ ██████╗ ██╗
██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔════╝ ██║
██████╔╝███████║██║     █████╔╝ ██║  ███╗██║
██╔══██╗██╔══██║██║     ██╔═██╗ ██║   ██║██║
██████╔╝██║  ██║╚██████╗██║  ██╗╚██████╔╝███████╗
╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝ content
*/


/*
██████╗ ██████╗
╚════██╗██╔══██╗
 █████╔╝██║  ██║
██╔═══╝ ██║  ██║
███████╗██████╔╝
╚══════╝╚═════╝
*/
import thumbFrag from '~/assets/js/shader/frag/thumb.frag';

//
BACKGL.scenes.dom.onInitContent2D = function( dom ){

	//
	const geometry = new THREE.PlaneBufferGeometry(1, 1, 1);
	const material = new THREE.ShaderMaterial({
		vertexShader   : `
			varying vec2 vUv;
			void main(){
				vUv = uv;
				gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
			}
		`,
		// fragmentShader   : `
		// 	void main() {
		// 		gl_FragColor = vec4(1.0,0.0,0.0,1.0);
		// 	}
		// `,
		fragmentShader : thumbFrag,
		uniforms: {
			scale : { value: { x: 1.2, y: 1.2 } },
			offset : { value: { x: 0, y: 0 } },
			borderRadius: { value : 0.02 },
			resolution: { value: { x: 0, y: 0 } },
			iResolution: { value: { x: 0, y: 0 } },
			tDiffuse : { value : null },
			tPoster : { value: null },
			tColor : { value: COLOR_ORIGIN.black.three },
			alpha : { value: 1 },
			mixPreload : { value : 0 },
			mixRatio : { value : 0 }
		},
		transparent: true,
	});
	material.alphaTest = 0.5;
	// material.blending = THREE.CustomBlending;
	// material.blendSrc = THREE.OneFactor;
	// material.blendDst = THREE.OneMinusSrcAlphaFactor;
	// material.blendEquation = THREE.AddEquation;

	//
	dom.mesh = new THREE.Mesh( geometry, material );
	dom.mesh.visible = false;

	//
	if( dom.name === 'backgroundImage' ){
		dom.mesh.position.z = 2;
	} else {
		dom.mesh.position.z = 5;
	}

	//
	BACKGL.sceneFrontSide.add( dom.mesh );

}



BACKGL.scenes.dom.onResizeContent2D = function( dom ){

	if( dom.mesh ){

		dom.mesh.scale.x = dom.rect.width;
		dom.mesh.scale.y = dom.rect.height;
		dom.mesh.material.uniforms.resolution.value.x  = dom.mesh.scale.x;
		dom.mesh.material.uniforms.resolution.value.y  = dom.mesh.scale.y;
		dom.mesh.material.uniforms.iResolution.value.x = dom.mediaRatio.x;
		dom.mesh.material.uniforms.iResolution.value.y = dom.mediaRatio.y;

		//
		switch( dom.el.dataset.layout ){
			case 'background':
			case 'fullscreen':
				dom.mesh.material.uniforms.borderRadius.value = 0;
			break;
			// case 'mobile':
			// 	// dom.mesh.material.uniforms.borderRadius.value = __WW__ / dom.mesh.scale.x * BORDER_RADIUS;
			// 	// dom.mesh.material.uniforms.borderRadius.value = 0;
			// 	dom.mesh.material.uniforms.borderRadius.value = __WW__ / dom.mesh.scale.x * BORDER_RADIUS * dom.borderRadius;
			// break;
			default:
				dom.mesh.material.uniforms.borderRadius.value = __WW__ / dom.mesh.scale.x * (BORDER_RADIUS*0.5) * dom.borderRadius;
			break;
		}

		//
		dom.mesh.position.x = ( -__WW__/2 + dom.mesh.scale.x/2 ) + dom.rect.left;

	}

}


BACKGL.scenes.dom.onRenderContent2D = function( dom ){
	dom.mesh.position.y = ( __WH__/2 - dom.mesh.scale.y/2 ) - dom.rect.top + stylePageScroll.body.y;
}


BACKGL.scenes.dom.onInviewContent2D = function( dom ){

	//
	if( !dom.mesh ) return false;

	//
	dom.mesh.visible = dom.visible;

	//================================================
	// 画面内にあり、かつまだTextureが用意されていない場合
	//================================================
	if( dom.mesh && dom.mesh.visible && !dom.tex && !dom.loader && !dom.poster.tex && !dom.poster.loader ){
		onLoadDomGLTexture( dom );
	}

	//
	if( dom.video.el ){
		if( dom.mesh.visible ){
			onPlayVideo( dom.video.el );
		} else {
			dom.video.el.pause();
		}
	}

}