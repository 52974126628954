/*
██████╗  █████╗  ██████╗██╗  ██╗ ██████╗ ██╗
██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔════╝ ██║
██████╔╝███████║██║     █████╔╝ ██║  ███╗██║
██╔══██╗██╔══██║██║     ██╔═██╗ ██║   ██║██║
██████╔╝██║  ██║╚██████╗██║  ██╗╚██████╔╝███████╗
╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝ item
*/


import { SCENE_ITEM } from '/assets/js/webgl/class/c-items.js';
// import FXAA from '~/assets/js/shader/fxaa.frag';

const GROUPS = {

	gekkeijuGroup : {

		world : null,
		screen : false,

		meshes : {

			gekkeiju : {
				visible: true,
				mesh : [],

				position: { x: 0, y: 0, z: 0, },

				rotation: { x: 0, y: 0, z: 0 },

				scale: {
					x : 0.9,
					y : 0.9,
					z : 0.9,
				},

				boundingSphere : {
					radius : 9.08475710129116
				},

				uniforms : {
					color : {
						css : '#F8D6B9',
						three : new THREE.Color('#F8D6B9')
					},
					emissive : {
						css : '#0F0D0D',
						three : new THREE.Color('#0F0D0D')
					},
					roughness: 1,
					metalness: 1,
					reflectivity: 1,
					clearcoat: .02,
					clearcoatRoughness: .25,
					opacity: 1,
				}

			}

		},

		lights : [
			{
				type : 'point',
				color: {
					three : COLOR_ORIGIN.brown.three,
				},
				position : { x : 0, y : 0, z : 1 },
				mesh : null,
				intensity : 8,
				distance : 1.2
			},
			// {
			// 	type : 'dir',
			// 	color: {
			// 		css : '#fff',
			// 		three : new THREE.Color('#fff'),
			// 	},
			// 	position : { x : 0, y : 25, z : 5 },
			// 	intensity : .75,
			// 	mesh : null,
			// }
		]

	},

	particleGroup : {

		world : null,
		screen : false,

		meshes : {
			particle : {

				visible: true,

				mesh : [],

				position: { x: 0, y: 0, z: 0 },

				rotation: { x: 0, y: 0, z: 0 },

				scale: {
					x : 0.7,
					y : 0.7,
					z : 0.7,
				},

				boundingSphere : {
					radius : 3.3361733410046295
				},

				// Materialを上書きしたい場合のみ
				uniforms : {
					color : {
						css : '#FAC393',
						three : new THREE.Color('#FAC393')
					},
					emissive : {
						css : '#0F0D0D',
						three : new THREE.Color('#0F0D0D')
					},
					roughness: 1,
					metalness: 0.4,
					reflectivity : 0.45,
					clearcoat: 0.1,
					clearcoatRoughness: 0.8,
					opacity: 1,
				},

			}
		},

		lights : [
			// {
			// 	type : 'point',
			// 	color: {
			// 		css : '#fff',
			// 		three : new THREE.Color('#fff'),
			// 	},
			// 	position : { x : 1, y : 1, z : 1 },
			// 	mesh      : null,
			// 	intensity : 0.,
			// 	distance  : 2
			// },
			{
				type : 'dir',
				color: {
					three : COLOR_ORIGIN.brown.three,
				},
				position : { x : -10, y : -5, z : 10 },
				mesh      : null,
				intensity : 1,
			}
		]

	},

	// eyeGroup : {

	// 	world : null,
	// 	screen : false,

	// 	meshes : {
	// 		eyes : {

	// 			visible: true,
	// 			mesh : [],
	// 			position: { x: 0, y: 0, z: 0, },
	// 			rotation: { x: 0, y: 0, z: 0 },

	// 			scale: {
	// 				x : 0.12,
	// 				y : 0.06,
	// 				z : 0.12,
	// 			},

	// 			boundingSphere : {
	// 				radius : 1
	// 			},

	// 			// Materialを上書きしたい場合のみ
	// 			uniforms : {
	// 				roughness: 1,
	// 				metalness: 0,
	// 				reflectivity : 1,
	// 				clearcoat: 0,
	// 				clearcoatRoughness: 0,
	// 			},

	// 		}
	// 	},

	// 	lights : [
	// 		{
	// 			type : 'point',
	// 			color: {
	// 				three : COLOR_ORIGIN.brown.three,
	// 			},
	// 			position : { x : 0.24, y : 0, z : 5 },
	// 			mesh      : null,
	// 			intensity : 0.5,
	// 			distance  : 10
	// 		},
	// 		// {
	// 		// 	type : 'dir',
	// 		// 	color: {
	// 		// 		css : '#fff',
	// 		// 		three : new THREE.Color('#fff'),
	// 		// 	},
	// 		// 	position : { x : -1, y : -0.5, z : 1 },
	// 		// 	mesh      : null,
	// 		// 	intensity : 3,
	// 		// }
	// 	]

	// }

}


BACKGL.scenes.item = {

	visible : true,

	ready : false,

	isInited : false,

	keys : null,

	each : { ...GROUPS },

	ratio : {
		w : 2/12,
		h : 2/12,
	},

	setZIndex(){
		for (let i = 0; i < this.keys.length; i++) {
			const key = this.keys[i];
			const v = this.each[key];
			if( v.screen ){
				v.screen.position.z = 299;
			}
		};
	},

	onInit(){


		//
		this.keys = Object.keys( this.each );

		// //
		// for (let i = 0; i < this.keys.length; i++) {
		// 	var v = this.each[ this.keys[i] ];
		// 	if( !v.screen ){
		// 		v.world = new SCENE_ITEM( v.meshes, v.lights, 512, 512, this.keys[i] );
		// 		const geometry = new THREE.PlaneBufferGeometry( 1, 1 );
		// 		const material = new THREE.ShaderMaterial({
		// 			vertexShader   : `
		// 				varying vec2 vUv;
		// 				void main(){
		// 					vUv = uv;
		// 					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
		// 				}
		// 			`,
		// 			// fragmentShader : FXAA,
		// 			fragmentShader : `
		// 				varying vec2 vUv;
		// 				uniform sampler2D tDiffuse;
		// 				void main() {
		// 					vec4 _tDiffuse = texture2D( tDiffuse, vUv );
		// 					float alpha = smoothstep( 0.0, 1.0, _tDiffuse.a );
		// 					_tDiffuse.a = alpha;
		// 					gl_FragColor = vec4(1.0);
		// 				}
		// 			`,
		// 			uniforms: {
		// 				// alpha: { value: 1 },
		// 				// resolution: { value: { x: 0, y: 0 } },
		// 				tDiffuse : { value: v.world.fbo.texture },
		// 			},
		// 			transparent: true,
		// 		});

		// 		material.blending = THREE.CustomBlending;
		// 		material.blendSrc = THREE.OneFactor;
		// 		material.blendDst = THREE.OneMinusSrcAlphaFactor;
		// 		material.blendEquation = THREE.AddEquation;
		// 		//
		// 		v.screen = new THREE.Mesh( geometry, material );
		// 		v.screen.visible = false;
		// 		BACKGL.sceneFrontSide.add( v.screen );
		// 	}
		// }

		// this.setZIndex();
		// this.ready = true;

		//
		for (let i = 0; i < BACKGL.domElements.length; i++) {
			const dom = BACKGL.domElements[i];
			const key = dom.name;
			const v = this.each[key];
			if( v ){

				if( !v.screen ){

					//
					v.world = new SCENE_ITEM( v.meshes, v.lights, dom.rect.width, dom.rect.height, key );

					//
					const geometry = new THREE.PlaneBufferGeometry( 1, 1 );
					const material = new THREE.ShaderMaterial({
						vertexShader   : `
							varying vec2 vUv;
							void main(){
								vUv = uv;
								gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
							}
						`,
						// fragmentShader : FXAA,
						fragmentShader : `
							varying vec2 vUv;
							uniform sampler2D tDiffuse;
							void main() {
								vec4 _tDiffuse = texture2D( tDiffuse, vUv );
								float alpha = smoothstep( 0.0, 1.0, _tDiffuse.r );
								gl_FragColor   = _tDiffuse;
								gl_FragColor.a = alpha;
							}
						`,
						uniforms: {
							// alpha: { value: 1 },
							// resolution: { value: { x: 0, y: 0 } },
							tDiffuse : { value: v.world.fbo.texture },
						},
						transparent: true,
					});
					material.alphaTest = 0.5;
					material.blending = THREE.CustomBlending;
					material.blendSrc = THREE.OneFactor;
					material.blendDst = THREE.OneMinusSrcAlphaFactor;
					material.blendEquation = THREE.AddEquation;

					//
					v.screen = new THREE.Mesh( geometry, material );
					BACKGL.sceneFrontSide.add( v.screen );

					//
					this.onIniview(dom);

				} else {

				}
			}
		}

		this.setZIndex();
		this.ready = true;

		// //
		// this.isInited = false;
		// for (let i = 0; i < BACKGL.domElements.length; i++) {
		// 	var dom = BACKGL.domElements[i];
		// 	var key = dom.name;
		// 	var v = this.each[key];
		// 	if( v ){
		// 		if( !v.screen ){

		// 			this.isInited = true;

		// 			//
		// 			v.world = new SCENE_ITEM( v.meshes, v.lights, dom.rect.width, dom.rect.height, key );

		// 			//
		// 			const geometry = new THREE.PlaneBufferGeometry( 1, 1 );
		// 			const material = new THREE.ShaderMaterial({
		// 				vertexShader   : `
		// 					varying vec2 vUv;
		// 					void main(){
		// 						vUv = uv;
		// 						gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
		// 					}
		// 				`,
		// 				// fragmentShader : FXAA,
		// 				fragmentShader : `
		// 					varying vec2 vUv;
		// 					uniform sampler2D tDiffuse;
		// 					void main() {

		// 						vec4 _tDiffuse = texture2D( tDiffuse, vUv );
		// 						float alpha = smoothstep( 0.0, 1.0, _tDiffuse.a );
		// 						_tDiffuse.a = alpha;
		// 						gl_FragColor = _tDiffuse;

		// 					}
		// 				`,
		// 				uniforms: {
		// 					// alpha: { value: 1 },
		// 					// resolution: { value: { x: 0, y: 0 } },
		// 					tDiffuse : { value: v.world.fbo.texture },
		// 				},
		// 				transparent: true,
		// 			});
		// 			material.blending = THREE.CustomBlending;
		// 			material.blendSrc = THREE.OneFactor;
		// 			material.blendDst = THREE.OneMinusSrcAlphaFactor;
		// 			material.blendEquation = THREE.AddEquation;

		// 			//
		// 			v.screen = new THREE.Mesh( geometry, material );
		// 			BACKGL.sceneFrontSide.add( v.screen );

		// 		}
		// 	}
		// }

		// if( this.isInited ){
		// 	this.setZIndex();
		// 	this.onResize();
		// 	this.ready = true;
		// }

	},

	onIniview( dom ){

		if( dom ){
			const w = this.each[ dom.name ];
			if( w ){
				if( w.screen && w.world ){

					//
					const tx = -__WW__/2 + dom.rect.width/2 + dom.rect.left;
					w.screen.position.x = tx;

					//
					w.screen.scale.x = dom.rect.width;
					w.screen.scale.y = dom.rect.height;
					w.screen.visible = dom.visible;

					//
					w.world.inview( dom.visible );
					w.world.resize( dom.rect.width, dom.rect.height );

				}
			}
		}

	},

	onReset(){

		//
		for (let i = 0; i < this.keys.length; i++) {
			const key = this.keys[i];
			const v = this.each[ key ];
			if( v.screen && v.world ){
				v.screen.visible = false;
				v.world.inview( false );
			}
		}

	},

	onResize(){
		// //
		// for (let i = 0; i < this.keys.length; i++) {
		// 	var key = this.keys[i];
		// 	var v = this.each[ key ];
		// 	// v.screen.scale.x = this.width;
		// 	// v.screen.scale.y = this.width;
		// }
	},

	onRender(){
		for (let i = 0; i < BACKGL.domElements.length; i++) {
			const dom = BACKGL.domElements[i];
			const key = dom.name;
			const v = this.each[key];
			if( v && dom.visible ){
				if( v.screen ){
					BACKGL.renderer.bg.render.setRenderTarget( v.world.fbo );
					BACKGL.renderer.bg.render.render( v.world.scene, v.world.camera );
				}
			}
		}
	},

	onScroll(){
		for (let i = 0; i < BACKGL.domElements.length; i++) {
			const dom = BACKGL.domElements[i];
			const key = dom.name;
			const v = this.each[key];
			if( v && dom.visible ){
				if( v.screen ){
					const ty =  __WH__/2 - dom.rect.height/2 - dom.rect.top;
					v.screen.position.y = ty + stylePageScroll.body.y;
					v.world.render();
				}
			}
		}
	}

}


