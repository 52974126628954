export default [
	{
		title : 'Awwwards',
		html : 'SOTM &times; 1,&ensp; SOTD &times; 24,&ensp; DOTD &times; 16,&ensp; MOTW &times; 6,&ensp; ME &times; 9',
		list : [
			"SOTM &times; 1",
			"SOTD &times; 24",
			"DOTD &times; 16",
			"MOTW &times; 6",
			"ME &times; 9"
		]
	},
	{
		title : 'FWA',
		list : ['FOTD &times; 17'],
	},
	{
		title : 'CSSDA',
		html : 'WOTM &times; 2,&ensp; WOTD &times; 28',
		list : [
			"WOTM &times; 2",
			"WOTD &times; 28"
		]
	},
	{
		title : 'Dezeen Award',
		html : 'Shortlist &times; 1,&ensp; Longlist &times; 3',
		list : [
			"Shortlist &times; 1",
			"Longlist &times; 3"
		]
	},
	{
		title : 'Digital Impact Awards',
		html : 'GOLD',
		list : [
			"GOLD"
		]
	},
	{
		title : 'Communication Arts',
		html : 'Webpicks &times; 7',
		list : [
			"Webpicks &times; 7"
		]
	},
	{
		title : 'Siteinspire',
		html : 'Selected &times; 1,&ensp; Showcase &times; 6',
		list : [
			"Selected &times; 1",
			"Showcase &times; 6"
		]
	}
]