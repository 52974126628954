/*
██████╗  █████╗  ██████╗██╗  ██╗ ██████╗ ██╗     
██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔════╝ ██║     
██████╔╝███████║██║     █████╔╝ ██║  ███╗██║     
██╔══██╗██╔══██║██║     ██╔═██╗ ██║   ██║██║     
██████╔╝██║  ██║╚██████╗██║  ██╗╚██████╔╝███████╗
╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝ DOM BACKGROUND
*/


const colorFrag = `
	varying vec2 vUv;
	uniform vec3 color;
	void main() {
		gl_FragColor = vec4( color.rgb, 1.0 );
	}
`;


const radiusFrag = `
varying vec2 vUv;
uniform vec3 color;
uniform vec2 resolution;
uniform float borderRadius;
uniform float LT;
uniform float RT;
uniform float RB;
uniform float LB;

void main() {

	//
	float aspect = resolution.x/resolution.y;
	vec2 aspectRatio = vec2( aspect, 1.0 );
	vec2 uv = vUv;
	vec2 bc = vec2( 0.5, 0.5 );
	vec2 bb = vec2( 0.5 - borderRadius, 0.5 - borderRadius * aspect );			
	uv *= aspectRatio;
	bc *= aspectRatio;
	bb *= aspectRatio;

	//
	float edge = borderRadius * aspect;
	float b = length( max(abs(uv - bc) - bb, 0.0) ) - edge;

	//
	float _lt = ( vUv.x < 0.5 && vUv.y > 0.5 ) ? 1.0 : 0.0;
	float _rt = ( vUv.x > 0.5 && vUv.y > 0.5 ) ? 1.0 : 0.0;
	float _rb = ( vUv.x > 0.5 && vUv.y < 0.5 ) ? 1.0 : 0.0;
	float _lb = ( vUv.x < 0.5 && vUv.y < 0.5 ) ? 1.0 : 0.0;

	//
	float round = _lt * LT + _rt * RT + _rb * RB + _lb * LB;

	//
	if( round + b <= 1.0 ){
		gl_FragColor = vec4( color.rgb, 1.0 );
	}

}`;


BACKGL.scenes.dom.onInitBackground = function( dom ){

	//===
	const color = dom.el.querySelector('.color');
	if( color && ROUTE.current.type === 'single' ){
		const style = getComputedStyle( color );
		dom.bgColor = new THREE.Color( style.backgroundColor );
	} else {
		dom.bgColor = COLOR_ORIGIN.black.three;
	}

	//===
	const lt = dom.el.dataset.radiusLt ? Number( dom.el.dataset.radiusLt ) : 0;
	const rt = dom.el.dataset.radiusRt ? Number( dom.el.dataset.radiusRt ) : 0;
	const rb = dom.el.dataset.radiusRb ? Number( dom.el.dataset.radiusRb ) : 0;
	const lb = dom.el.dataset.radiusLb ? Number( dom.el.dataset.radiusLb ) : 0;
	const frag = (lt+rt+rb+lb > 0) ? radiusFrag : colorFrag;

	//
	const geometry = new THREE.PlaneBufferGeometry(1, 1, 1);
	const material = new THREE.ShaderMaterial({
		vertexShader   : `
			varying vec2 vUv;
			void main(){
				vUv = uv;
				gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
			}
		`,
		fragmentShader : frag,
		uniforms: {
			color : { value: dom.bgColor },
			resolution : { value: { x: 0, y: 0 } },
			borderRadius : { value : 0.075 },
			LT : { value : 1 },
			RT : { value : 1 },
			RB : { value : 0 },
			LB : { value : 0 }
		},
		transparent: true
	});
	material.alphaTest = 0.5;
	dom.mesh = new THREE.Mesh( geometry, material );
	dom.mesh.position.z = 2;
	BACKGL.sceneFrontSide.add( dom.mesh );

}

BACKGL.scenes.dom.onInviewBackground = function( dom ){

	const _min     = ( dom.rect.top + dom.rect.height ) - stylePageScroll.body.y;
	const _max     = stylePageScroll.body.y + __WH__;
	const _inview  = ( dom.rect.top < _max && _min > 0 );
	const _visible = dom.visible && _inview;
	dom.mesh.visible = _visible;

	// dom.mesh.scale.x = dom.rect.width;
	// dom.mesh.scale.y = dom.rect.height;
	// dom.mesh.material.uniforms.resolution.value.x = dom.mesh.scale.x;
	// dom.mesh.material.uniforms.resolution.value.y = dom.mesh.scale.y;
	// dom.mesh.material.uniforms.borderRadius.value = __WW__ / dom.mesh.scale.x * BORDER_RADIUS;
	// dom.mesh.position.x = ( -__WW__/2 + dom.mesh.scale.x/2 ) + dom.rect.left;
}


BACKGL.scenes.dom.onResizeBackground = function( dom ){
	dom.mesh.scale.x = dom.rect.width;
	dom.mesh.scale.y = dom.rect.height;
	dom.mesh.material.uniforms.resolution.value.x = dom.mesh.scale.x;
	dom.mesh.material.uniforms.resolution.value.y = dom.mesh.scale.y;
	dom.mesh.material.uniforms.borderRadius.value = __WW__ / dom.mesh.scale.x * BORDER_RADIUS;
	dom.mesh.position.x = ( -__WW__/2 + dom.mesh.scale.x/2 ) + dom.rect.left;
}


BACKGL.scenes.dom.onRenderBackground = function( dom ){

	// console.log('onRenderBackground');
	dom.mesh.position.y = ( __WH__/2 - dom.mesh.scale.y/2 ) - dom.rect.top + stylePageScroll.body.y;

}















