/*
██████╗  █████╗  ██████╗██╗  ██╗ ██████╗ ██╗     
██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔════╝ ██║     
██████╔╝███████║██║     █████╔╝ ██║  ███╗██║     
██╔══██╗██╔══██║██║     ██╔═██╗ ██║   ██║██║     
██████╔╝██║  ██║╚██████╗██║  ██╗╚██████╔╝███████╗
╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝ party
*/


import { SCENE_TEAM_ALL } from '/assets/js/webgl/class/c-team-all.js';
import borderRadiusFrag from '~/assets/js/shader/frag/borderRadius.frag';

BACKGL.scenes.teamAll = {

	visible : true,
	ready : false,
	body : { el : null },
	dom : null, 

	setZIndex(){
		this.screen.position.z = 101;
	},

	onInit( elem ){

		//===
		if( elem ){ this.body.el = elem.querySelector('.Pc') } else { this.body.el = document.querySelector('.Pc'); }
		this.dom = this.body.el.querySelector('.js-gl-dom[data-gl-name="teamall"]');
		if( this.dom && this.world ){
			this.dom.style.height = this.world.ratioHeight + GRID.w*2 + 'px';
		}

	},

	onLoaded(){

		const _this = this;

		this.world = new SCENE_TEAM_ALL( false, true, 22/48, 1 );
		// this.onIniview();

		const geometry = new THREE.PlaneBufferGeometry( 1, 1 );
		const material = new THREE.ShaderMaterial({
			vertexShader   : `
				varying vec2 vUv;
				void main(){
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
				}
			`,
			fragmentShader : borderRadiusFrag,
			uniforms: {
				borderRadius: { value : 0.05 },
				LT: { value : 0 },
				RT: { value : 0 },
				RB: { value : 1 },
				LB: { value : 0 },
				resolution: { value: { x: 0, y: 0 } },
				tDiffuse : { value: this.world.fbo.texture },
			},
			transparent: true,
		});
		material.alphaTest = 0.5;
		this.screen = new THREE.Mesh( geometry, material );
		this.screen.visible = false;	

		// 		this.world.inview( true );
		// 		this.screen.visible = true;	
		// //
		// switch( ROUTE.current.type ){
		// 	case 'about':
		// 		this.world.inview( true );
		// 		this.screen.visible = true;	
		// 	break;
		// 	default:
		// 		this.world.inview( true );
		// 		this.screen.visible = true;
		// 		setTimeout(()=>{
		// 			// 一度描写しないと遷移時に重い
		// 			_this.world.inview( false );
		// 			_this.screen.visible = false;
		// 		}, FIRSTRENDER );
		// 	break;
		// }

		//
		BACKGL.sceneFrontSide.add( this.screen );

		//
		this.onResize();
		this.setZIndex();

		//
		this.ready = true;

	},

	onLoadedEnvMap(){

		if( this.world ) this.world.initEnvMap();

	},

	onResize(){

		//
		this.world.resize();

		//
		this.screen.scale.x = this.world.ratioWidth;
		this.screen.scale.y = this.world.ratioHeight;
		this.screen.material.uniforms.resolution.value.x = this.screen.scale.x;
		this.screen.material.uniforms.resolution.value.y = this.screen.scale.y;
		this.screen.material.uniforms.borderRadius.value = __WW__ / this.world.ratioWidth * BORDER_RADIUS;

		//
		if( this.dom && this.world ){
			this.dom.style.height = this.world.ratioHeight + 'px';
		}

	},

	onIniview( dom ){

		this.screen.visible = dom.visible;
		this.world.inview( dom.visible );

	},

	onReset(){

		if( this.screen ) this.screen.visible = false;
		if( this.world ) this.world.inview( false );

	},

	onRender(){
		if( stylePageScroll.body.y <= __WH__ * 1.2 ){
			BACKGL.renderer.bg.render.setRenderTarget( this.world.fbo );
			BACKGL.renderer.bg.render.render( this.world.scene, this.world.camera );
		}
	},

	onScroll(){
		if( stylePageScroll.body.y <= __WH__ * 1.2 ){
			const tx = -__WW__/2 + this.screen.scale.x/2;
			const ty =  __WH__/2 - this.screen.scale.y/2;
			this.screen.position.x = tx;
			if( !DETECT.device.any ){
				this.screen.position.y = ty + stylePageScroll.body.y;
			} else {
				this.screen.position.y = ty;	
			}
			if( !DETECT.device.any ) this.world.camera.position.y = stylePageScroll.body.y * 0.2 / RESCALE.r;
			this.world.render();
		}
	}

}