import * as a from "./actions";
import * as g from "./getters";
import m from "./mutations";

//
// import profile from "./db-profile.js";
import awards from "./db-awards.js";
import team from "./db-team.js";
import archive from "./db-archive.js";

//
let archiveArray = [];
const archiveMax = 25;
const getRandomArray = function(array, num) {
	var a = array;
	var t = [];
	var r = [];
	var l = a.length;
	var n = num < l ? num : l;
	while (n-- > 0) {
	var i = Math.random() * l | 0;
	r[n] = t[i] || a[i];
	--l;
	t[i] = t[l] || a[l];
	}
	return r;
}
if( archive.length === archiveMax ){

	archiveArray = [...archive];

} else if( archive.length < archiveMax ){

	let less = archiveMax - archive.length;
	let loop = Math.ceil( archiveMax/archive.length );
	for (var n = 0; n < loop; n++) {
		for (var i = 0; i < archive.length; i++) {
			if( archiveArray.length < archiveMax ){
				archiveArray.push({
					title : archive[i].title,
					video : archive[i].video,
					thumb : archive[i].thumb,
					url : archive[i].url
				});
			}
		}
	}

} else {

	//
	// archiveArray = getRandomArray( archive, archiveMax );
	archiveArray = archive.slice(0,archiveMax);
}


//
export const state = () => ({

	// 現在のページ情報
	page : null,
	history  : {
		old : null,
		now : null,
	},

	//
	time : {
		tokyo : {
			h:'00',
			m:'00',
			s:'00'
		},
		copen : {
			h:'00',
			m:'00',
			s:'00'
		},
	},

	//
	projects : [],
	project_selected_slug : null,

	//
	archive : [...archiveArray],
	archive_origin_len : archive.length,

	//
	ui : {
		cursor : null,
		menu : {
			isopened : false,
		}
	},

	//
	awards : awards,

	//
	// profile : profile,

	//
	team : team


})

export const mutations = {
	...m
}

export const actions = {
	...a
}

export const getters = {
	...g
}