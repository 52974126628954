/*
██████╗  █████╗  ██████╗██╗  ██╗ ██████╗ ██╗
██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔════╝ ██║
██████╔╝███████║██║     █████╔╝ ██║  ███╗██║
██╔══██╗██╔══██║██║     ██╔═██╗ ██║   ██║██║
██████╔╝██║  ██║╚██████╗██║  ██╗╚██████╔╝███████╗
╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝ party
*/


// import bokeh from '~/assets/js/shader/frag/bokeh.frag';
import bokeh from '~/assets/js/shader/frag/bokeh.frag';
import { SCENE_PARTY } from '/assets/js/webgl/class/c-party.js';


BACKGL.scenes.partyHome = {

	ready : false,
	progress : {
		el     : null,
		svg    : null,
		per    : 0,
		power  : 0
	},
	slide : {
		tl : null,
		power : 0
	},
	body : { el : null },
	world : null,
	visible : true,

	onMouseDown(){
		if( this.world ) this.world.mousedown();
	},

	onMouseUp(){
		if( this.world ) this.world.mouseup();
	},

	setZIndex(){
		this.screen.position.z = 1;
	},

	onInit( elem ){

		//===
		if( elem ){ this.body.el = elem.querySelector('.Pc') } else { this.body.el = document.querySelector('.Pc'); }

		//===
		this.progress.el  = this.body.el.querySelector('.ui-slide .js-slide-progress');
		this.progress.svg = this.body.el.querySelector('.ui-slide svg');

		//
		// if( this.progress.el ){
		// 	this.onInitSlideProgress();
		// } else {
		// 	if( this.progress.tl ) this.progress.tl.kill();
		// }

		//===
		this.onInitSlide();

		//===
		switch( ROUTE.current.type ){
			case 'index':
			case 'notfound':
			case 'contact':
				this.setFadeIn();
			break;
			default:
				this.setFadeOut();
			break;
		}

		//===
		if( this.world ) this.world.reload();

		//
		this.ready = true;

	},

	onInitSlide(){

		const _this = this;

		//
		if( IS_REDUCED ) return false;
		if( !this.slide.tl ){
			this.slide.tl = gsap.timeline({repeat: -1});
			this.slide.tl.to( this.slide, {
				duration : ANIM.party.progress.interval.s,
				ease     : ANIM.party.progress.interval.e,
				power    : 1,
				onComplete(){
					if( _this.world ){
						_this.world.slideToNext();
					}
				}
			});
			this.slide.tl.pause();
			if( ROUTE.current.type === 'index' || ROUTE.current.type === 'contact' || ROUTE.current.type === 'notfound' ){
				if( this.slide.tl.paused() ) this.slide.tl.play();
			}
		} else {
			this.slide.tl.pause();
			if( ROUTE.current.type === 'index' || ROUTE.current.type === 'contact' || ROUTE.current.type === 'notfound' ){
				if( this.slide.tl.paused() ) this.slide.tl.play();
			}
		}

	},

	onLoaded(){

		//===
		if( __WW__ <= 768 ){
			this.uniforms.blurPower.x = getPxScale(3.25) * 2;
			this.uniforms.blurPower.y = getPxScale(3.25) * 2;
		}

		//===
		this.world   = new SCENE_PARTY( false, true );
		const geometry = new THREE.PlaneBufferGeometry(1, 1, 1);
		const material = new THREE.ShaderMaterial({
			vertexShader   : `
				varying vec2 vUv;
				void main(){
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
				}
			`,
			fragmentShader : bokeh,
			// fragmentShader : `
			// 	varying vec2 vUv;
			// 	void main() {
			// 		gl_FragColor = vec4(1.0,0.0,0.0,1.0);
			// 	}
			// `,
			uniforms: {

				//
				tDiffuse   : { value: this.world.fbo.texture },
				resolution : { value: { x: 0, y: 0 } },

				//
				blurPower  : { value: this.uniforms.blurPower },
				fogColor   : { value: COLOR.homeBlurColor.three },
				stepPower  : { value: this.uniforms.stepPower },

			},
			transparent: true,
		});
		material.alphaTest = 0.5;
		material.defines = {
			USE_BLUR : ( !IS_LOW_SPEC && !IS_REDUCED )
		}

		//
		this.screen = new THREE.Mesh(geometry, material);
		BACKGL.sceneFrontSide.add( this.screen );

		//
		this.onInview();
		this.setZIndex();

	},

	uniforms : {
		blurPower : {
			x : getPxScale(3.25),
			y : getPxScale(3.25)
			// x : getPxScale(3.25),
			// y : getPxScale(3.25)
		},
		stepPower : 0.5,
		fogColor  : '#f00'
	},

	// onInitGui(){

	// 	const _this = this;

	// 	this.folder = pane.addFolder({
	// 		title: 'Home',
	// 	});

	// 	this.folder.addInput( this.uniforms.blurPower, 'x', {
	// 		min: 0.0,
	// 		max: 10.0,
	// 	});
	// 	this.folder.addInput( this.uniforms.blurPower, 'y', {
	// 		min: 0.0,
	// 		max: 10.0,
	// 	});

	// 	this.folder.addInput( this.uniforms, 'stepPower', {
	// 		min: 0.000,
	// 		max: 1.000,
	// 	});

	// 	this.folder.addInput( this.uniforms, 'fogColor', {
	// 		view: 'color'
	// 	});

	// 	this.folder.on('change', function(ev) {

	// 		// _this.screen.material.uniforms.blurPower.value.x = getPxScale(_this.uniforms.blurPower.x);
	// 		// _this.screen.material.uniforms.blurPower.value.y = getPxScale(_this.uniforms.blurPower.y);
	// 		// _this.screen.material.uniforms.stepPower.value   = _this.uniforms.stepPower;

	// 		//
	// 		const fogColor = new THREE.Color( _this.uniforms.fogColor );
	// 		_this.screen.material.uniforms.fogColor.value = fogColor;

	// 	});

	// },

	onResize(){

		//
		this.screen.scale.x = BACKGL.width;
		this.screen.scale.y = BACKGL.height;
		this.screen.material.uniforms.resolution.value.x = this.screen.scale.x;
		this.screen.material.uniforms.resolution.value.y = this.screen.scale.y;

		//
		this.world.resize();

	},

	setFadeIn(){
		this.screen.visible = true;
		this.world.inview( true );
	},

	setFadeOut(){
		this.screen.visible = false;
		this.world.inview( false );
	},

	onInview( visible ){
		this.world.inview( visible );
	},

	onSlideTo( index ){
		this.world.slideTo( index );
	},

	onRender(){
		BACKGL.renderer.bg.render.setRenderTarget( this.world.fbo );
		BACKGL.renderer.bg.render.render( this.world.scene, this.world.camera );
	},

	onScroll(){
		this.world.render();
	}

}


