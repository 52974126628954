/*
██╗███╗   ███╗██████╗  ██████╗ ██████╗ ████████╗
██║████╗ ████║██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝
██║██╔████╔██║██████╔╝██║   ██║██████╔╝   ██║   
██║██║╚██╔╝██║██╔═══╝ ██║   ██║██╔══██╗   ██║   
██║██║ ╚═╝ ██║██║     ╚██████╔╝██║  ██║   ██║   
╚═╝╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝   
*/



//===============
// THREE
//===============
import * as THREE from      '~/_modules/three-r133/build/three.module.js';
import { DRACOLoader } from '~/_modules/three-r133/examples/jsm/loaders/DRACOLoader.js';
import { GLTFLoader } from  '~/_modules/three-r133/examples/jsm/loaders/GLTFLoader.js';
import * as BufferGeometryUtils from '~/_modules/three-r133/examples/jsm/utils/BufferGeometryUtils.js';


//===============
// GSAP
//===============
import { gsap } from "gsap/dist/gsap";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { DrawSVGPlugin } from "gsap/dist/DrawSVGPlugin";

//===============
// THREE
//===============
// import * as CANNON from '~/_modules/cannon/cannon.min.js';
// import * as CANNON from 'cannon-es';
import {
	World,
	NaiveBroadphase,
	Vec3,
	Plane,
	Quaternion,
	Body,
	Sphere,
	Spring,
	Box,
	ConeTwistConstraint
} from 'cannon-es'
// } from '~/_modules/cannon-es/dist/cannon-es'



if( process.browser ) {

	//
	window.EASESCROLL = true;
	window.WEBGL = true;
	window.FIRSTRENDER = 500;

	//====================================
	// GSAP
	//====================================
	window.gsap = gsap;
	gsap.registerPlugin( ScrollToPlugin );
	gsap.registerPlugin( DrawSVGPlugin );
	gsap.config({ nullTargetWarn: false });


	//====================================
	// CANNON
	//====================================
	window.CANNON = {
		World : World,
		NaiveBroadphase : NaiveBroadphase,
		Vec3 : Vec3,
		Plane : Plane,
		Quaternion : Quaternion,
		Body : Body,
		Sphere : Sphere,
		Spring : Spring,
		Box : Box,
		ConeTwistConstraint : ConeTwistConstraint
	};

	//====================================
	// THREE
	//====================================
	window.THREE = THREE;
	window.GLTFLoader = GLTFLoader;
	window.BufferGeometryUtils = BufferGeometryUtils;
	window.dracoLoader = new DRACOLoader();
	dracoLoader.setDecoderPath( 'https://cdn.jsdelivr.net/npm/three@0.137.0/examples/js/libs/draco/' );

	//============
	// POLY
	//============
	// require('intersection-observer');

	//
	require('../assets/js/0.var.js');
	require('../assets/js/0.resource.js');

	//============
	// DOM
	//============
	require('../assets/js/dom/0.init.js');
	require('../assets/js/dom/1.function.js');
	require('../assets/js/dom/2-1.mousemove.js');
	// require('../assets/js/dom/2-2.mousedrag.js');
	require('../assets/js/dom/3-1.transition-init.js');
	require('../assets/js/dom/3-2.transition-color.js');
	require('../assets/js/dom/4-1.page-scroll.js');
	require('../assets/js/dom/4-2.cases-scroll.js');
	require('../assets/js/dom/4-3.archive-scroll.js');
	// require('../assets/js/dom/5-1.drag-slider.js');
	require('../assets/js/dom/6.pwa.js');
	require('../assets/js/dom/7.ui.js');


	//============
	// BACK
	//============
	require('../assets/js/webgl/back/0.b-init.js');
	require('../assets/js/webgl/back/1-1.b-party-home.js');
	require('../assets/js/webgl/back/1-2.b-party-single.js');
	require('../assets/js/webgl/back/2.b-ground.js');
	// require('../assets/js/webgl/back/3.b-thumb.js');
	require('../assets/js/webgl/back/4.b-item.js');
	require('../assets/js/webgl/back/5-1.b-team-all.js');
	require('../assets/js/webgl/back/5-2.b-team-single.js');
	require('../assets/js/webgl/back/6.b-office.js');
	// require('../assets/js/webgl/back/7.b-print.js');
	// require('../assets/js/webgl/back/7.b-404.js');

	//
	require('../assets/js/webgl/back/domgl/4-1.b-dom-init.js');
	// require('../assets/js/webgl/back/domgl/4-2.b-dom-text.js');
	require('../assets/js/webgl/back/domgl/4-3.b-dom-background.js');
	require('../assets/js/webgl/back/domgl/4-5.b-dom-archive.js');
	require('../assets/js/webgl/back/domgl/4-9.b-dom-content.js');

	//============
	// FRONT
	//============
	// require('../assets/js/webgl/front/0-1.f-init.js');


	//============
	// GUI
	//============
	// require('../assets/js/webgl/99.gui.js');

	// //===
	// require('../assets/js/webgl/3-1.head-init.js');
	// require('../assets/js/webgl/3-2.head-light.js');

}