/*
██████╗  ██████╗ ███╗   ███╗
██╔══██╗██╔═══██╗████╗ ████║
██║  ██║██║   ██║██╔████╔██║
██║  ██║██║   ██║██║╚██╔╝██║
██████╔╝╚██████╔╝██║ ╚═╝ ██║
╚═════╝  ╚═════╝ ╚═╝     ╚═╝ page scroll
*/

//
import Smooth from '/assets/js/dom/class/1.c-smooth-scroll.js'

//
const EDOM = Math.min( getParam('edom') ? Number( getParam('edom') ) : 0.15, 1 );
const EGL  = EDOM * 1.5;

//
window.stylePageScroll = {

	ready : false,

	smooth : null,

	body : {
		el   : null,
		y    : 0,
		p    : 0,
		ease : EDOM,
		top  : 0
	},

	webgl : {
		y    : 0,
		p    : 0,
		ease : EGL,
		top  : 0
	},

	$scroll : null,

	//
	dom : [],

	//
	totalHeight  : 0,
	headerHeight : 0,

	//
	scrollTop : 0,

	//
	onScroll(){
		if( styleMenu.isopen ) styleMenu.onClose();
	},

	onScrollTo(e){
		if( this.ready ){

			if( DETECT.device.any || IS_REDUCED ){

				if( this.isOvFV ){
					gsap.to( $window, {
						duration : 1,
						ease : 'power2.out',
						scrollTo : this.body.y + __WH__,
						autoKill: false
					});
				} else {
					gsap.to( $window, {
						duration : 1,
						ease : 'power2.out',
						scrollTo : 0,
						autoKill: false
					});
				}

			} else {

				if( this.smooth ){
					if( !this.isOvFt ){
						this.smooth.scroller.scrollTop += __WH__;
					} else {
						this.smooth.scroller.scrollTop = 0;
					}
				}

			}

		}
	},

	onAnchorToTop(){
		if( this.ready ){
			if( DETECT.device.any ){
				gsap.to( $window, {
					duration : 1,
					ease : 'power2.out',
					scrollTo : 0
				});
			} else {
				if( this.smooth ) this.smooth.scroll.top = 0;
			}
		}
	},

	header : {
		el : null,
		rect : null
	},

	once(){

		// const _this = this;

		//
		this.$height = document.querySelector('.js-sh');

		//
		if( DETECT.os.win ){
			this.body.ease  = 0.3;
		}

		//
		this.header.el = document.querySelectorAll('.js-hd');
		this.header.rect = this.header.el[0].getBoundingClientRect();

	},

	onDestroy(){

		// const _this = this;

		//
		if( this.smooth ) this.smooth.destroy(); this.smooth = null;

		//
		this.body.el = null;
		this.ready = false;

		//
		if( DETECT.device.any || IS_REDUCED ){
			$window.scrollTop = 0;
		}

		//
		this.onResetTweens();
		this.speed.tween = gsap.to( this.speed, {
			duration : this.speed.duration,
			pow1 : 0
		});

		//===
		window.scrollTo(0,0);
		this.body.y = 0;
		this.body.p = 0;
		__WT__ = 0;

		//===
		this.isOvFV = true;
		$html.dataset.ovFv = 0;	
		this.isOvFt = false;
		$html.dataset.ovFt = 0;

	},

	onInit( elem ){

		//===
		this.onDestroy();

		//===
		if( elem ){ this.body.el = elem.querySelector('.Pc') } else { this.body.el = document.querySelector('.Pc'); }

		//===
		this.type = this.body.el.dataset.scrollType;
		if( this.type === 'page' || this.type === 'single' ){

			//===
			if( !DETECT.device.any && !IS_REDUCED ){
				this.smooth = new Smooth({
					scroller  : '.s-scroller',  // Scroll イベントを発火させる要素
					container : '.s-container', // 完成をつけた scrollTop を設定する要素
					elem      : '.s-body',      // 実際の高さを取得する要素
					ease      : this.body.ease
				})
				window.removeEventListener('wheel',onWheel );
			}

			//===
			this.onLoadContents( this.body.el );

		}


	},


	onLoadContents( elem ){

		console.log('stylePageScroll.onLoadContents');
		// const _this = this;

		//===
		this.contents = [];
		this.dom = [];
		// this.$scroll = elem.querySelectorAll('.js-sc');

		//===
		this.$inview = elem ? elem.querySelectorAll('.js-iv') : document.querySelectorAll('.js-iv');
		this.$scroll = elem ? elem.querySelectorAll('.js-sc') : document.querySelectorAll('.js-sc');

		//===
		this.$scroll.forEach( (el,i) =>{

			//
			let rect = el.getBoundingClientRect();

			//
			const $pivot = el.querySelector('.js-sc-pivot');
			if( $pivot ){
				rect = $pivot.getBoundingClientRect();
			}

			//
			const $bg = el.querySelector('.St-bg');
			let color = $bg ? 'black' : el.dataset.sectionCol;

			//
			if( $bg ){
				const colorCode = $bg.dataset.colorCode;
				if( colorCode ){
					const colorRGB = new THREE.Color( colorCode );
					const colorNoudo = (colorRGB.r*255+colorRGB.g*255+colorRGB.b*255)/3;
					if( colorNoudo > 200 ){
						color = 'white';
					}
				}
			}

			//
			const type = el.dataset.type;
			const powX = Number(el.dataset.powX);
			const powY = Number(el.dataset.powY);

			//
			const thumb = el.querySelector('.thumb');
			const thumb_rect = thumb ? thumb.getBoundingClientRect() : null;

			//
			this.dom.push({
				name    : el.dataset.name,
				i       : i,
				el      : el,
				pivot   : $pivot,
				x       : 0,
				y       : 0,
				visible : false,
				bg      : color,
				rect    : rect,
				pow     : {
					x   : powX,
					y   : powY,
				},
				thumb : {
					el : thumb,
					rect : thumb_rect,
					img : null,
				}
			});

		});

		//===
		this.onResize();
		this.ready = true;

	},

	onResize(){

		console.log('onResize');

		//===
		if( !this.body.el ) return false;

		//===
		if( this.smooth ) this.smooth.update();

		//===
		$window.scrollTop = 0;
		window.scrollTo(0,0);
		this.body.y = 0;
		this.body.p = 0;
		__WT__ = 0;

		//
		let rect = this.body.el.getBoundingClientRect();
		this.totalHeight = this.body.el.clientHeight;
		// this.$height.style.height = this.totalHeight + 'px';

		//===
		this.header.rect = this.header.el[0].getBoundingClientRect();

	},

	history : {
		y    : [],
		down : 0,
		up   : 0,
		to   : 0,
	},

	speed  : {
		delta  : 0,
		hitory : [],
		max    : 4,
		tween  : null,
		pow0   : 0,
		pow1   : 0,
		pow2   : 0,
		dir    : 1,
		duration : 2.5,
	},

	getSpeed( ratio = 1, y ){

		//===
		if( this.speed.hitory.length > 2 ){
			this.speed.hitory.shift();
			this.speed.hitory.push( y );
		} else {
			this.speed.hitory.push( y );
		}
		if( this.speed.hitory.length > 2 ){
			this.speed.pow0 = Math.min( Math.abs( (this.speed.hitory[0] - this.speed.hitory[2]) * ratio ) , this.speed.max );
			this.speed.dir = (this.speed.hitory[0] - this.speed.hitory[2]) < 0 ? -1 : 1;
			this.speed.tween = gsap.to( this.speed, {
				duration : this.speed.duration,
				pow1 : this.speed.pow0 * this.speed.dir,
			});
		}

	},

	onResetTweens(){
		if( this.speed.tween ){
			this.speed.tween.kill();
		}
	},

	isOvFV : false,
	isOvHd : false,
	isOvFt : false,
	ovHd : 0,
	ovFt : 0,

	onScroll(){

		if( !this.ready && !this.smooth ) return false;

		//===
		if( this.smooth ) this.smooth.onUpdate();

		//===
		if( DETECT.device.any || IS_REDUCED ){
			this.body.top = this.body.y = __WT__;
			this.body.p = this.body.y / ( this.totalHeight - __WH__);
		} else {
			this.body.top = this.smooth.scroll.top;
			this.body.y = this.smooth.scroll.y;
			this.body.p += ( ( this.body.y / ( this.totalHeight -__WH__) ) - this.body.p ) * this.body.ease;
		}

	},

	onUpdate(){

		//===
		this.ovHd = 0;
		this.ovFt = 0;
		for (let i = 0; i < this.dom.length; i++) {
			const v = this.dom[i];
			const _visible = parseInt( v.el.dataset.visible );
			const _min = ( v.rect.top + v.rect.height ) - this.body.y;
			const _max = this.body.y + __WH__;
			//===
			if( ( v.rect.top < _max && _min > 0 ) || _visible === 1 ){
				v.y = Math.round(this.body.y);
				v.visible = true;
			} else {
				v.visible = false;
			}
			this.onHandleHF( v );
		}
		$html.dataset.ovHb = this.ovHd;
		$html.dataset.ovFb = this.ovFt;

		//===
		const Fv = DETECT.device.any ? __WH__ * 0.5 : __WH__ * 3;
		if( this.body.y >= Fv ){
			this.isOvFV = false;
			$html.dataset.ovFv = 1;
		} else {
			this.isOvFV = true;
			$html.dataset.ovFv = 0;
		}

		if( this.totalHeight - this.body.y - __WH__*2 <= 0 ){
			this.isOvFt = true;
			$html.dataset.ovFt = 1;
		} else {
			this.isOvFt = false;
			$html.dataset.ovFt = 0;
		}

		//===
		if( !IS_LOW_SPEC ) styleUI.onProgress( 1, this.body.p );

		//===
		this.getSpeed( 0.0125, this.body.top );

		//===
		BACKGL.onScroll( this.type, this.speed );

	},

	onHandleHF( v ){
		const __min = ( v.rect.top - this.body.y - this.header.rect.height/2 );
		const __max = ( v.rect.top - this.body.y - this.header.rect.height/2 ) + v.rect.height;
		const isOvHd  = ( __min < 0 && __max > 0 );
		const isOvFt  = ( __min < __WH__ - this.header.rect.height && __max > __WH__ - this.header.rect.height * 2 );
		switch( v.bg ){
			case 'black':
				if( isOvHd ) this.ovHd = 1;
				if( isOvFt ) this.ovFt = 1;
			break;
			case 'footer':
				if( isOvHd ) this.ovHd = 2;
				if( isOvFt ) this.ovFt = 1;
			break;
		}
	}

}







































































































