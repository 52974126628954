export default [
	{
		title : 'KODO NISHIMURA',
		category : 'Portfolio',
		thumb : {
			mediaType:"image",
			poster : "/kodonishimura@poster.jpg",
			src : {
				"d2x" : "/kodonishimura@2x.jpg",
				"d1x" : "/kodonishimura@1x.jpg",
				"mob" : "/kodonishimura@1x.jpg"
			}
		},
		url : "https://www.kodonishimura.com/"
	},
	{
		title : 'Dig The Tea',
		category : 'Media',
		thumb : {
			mediaType:"image",
			poster : "/digthetea@poster.jpg",
			src : {
				"d2x" : "/digthetea@2x.jpg",
				"d1x" : "/digthetea@1x.jpg",
				"mob" : "/digthetea@1x.jpg"
			}
		},
		url : "https://digthetea.com/"
	},
	{
		title : 'Yagi Lab',
		category : 'Portfolio',
		thumb : {
			mediaType:"image",
			poster : "/yagilab@poster.jpg",
			src : {
				"d2x" : "/yagilab@2x.jpg",
				"d1x" : "/yagilab@1x.jpg",
				"mob" : "/yagilab@1x.jpg"
			}
		},
		url : "https://www.yagi.iis.u-tokyo.ac.jp/"
	},
	{
		title : 'Aethra',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/aethra@poster.jpg",
			src : {
				"d2x" : "/aethra@2x.jpg",
				"d1x" : "/aethra@1x.jpg",
				"mob" : "/aethra@1x.jpg"
			}
		},
		url : "https://www.aethra.xyz/"
	},
	{
		title : 'The Park',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/thepark@poster.jpg",
			src : {
				"d2x" : "/thepark@2x.jpg",
				"d1x" : "/thepark@1x.jpg",
				"mob" : "/thepark@1x.jpg"
			}
		},
		url : "https://theparksm.com/"
	},
	{
		title : 'White',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/white@poster.jpg",
			src : {
				"d2x" : "/white@2x.jpg",
				"d1x" : "/white@1x.jpg",
				"mob" : "/white@1x.jpg"
			}
		},
		url : "https://wht.co.jp"
	},
	{
		title : 'molten MFK',
		category : 'Website',
		thumb : {
			mediaType:"image",
			poster : "/molten@poster.jpg",
			src : {
				"d2x" : "/molten@2x.jpg",
				"d1x" : "/molten@1x.jpg",
				"mob" : "/molten@1x.jpg"
			}
		},
		url : "https://myfootballkit.jp/"
	},
	{
		title : 'IDENTITY',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/identity@poster.jpg",
			src : {
				"d2x" : "/identity@2x.jpg",
				"d1x" : "/identity@1x.jpg",
				"mob" : "/identity@1x.jpg"
			}
		},
		url : "https://identity.city/"
	},
	{
		title : 'kiminitou',
		category : 'Media',
		thumb : {
			mediaType:"image",
			poster : "/kiminitou@poster.jpg",
			src : {
				"d2x" : "/kiminitou@2x.jpg",
				"d1x" : "/kiminitou@1x.jpg",
				"mob" : "/kiminitou@1x.jpg"
			}
		},
		url : "http://kiminitou.com"
	},
	{
		title : 'TSUBAKI fm',
		category : 'Media',
		thumb : {
			mediaType:"image",
			poster : "/tsubakifm@poster.jpg",
			src : {
				"d2x" : "/tsubakifm@2x.jpg",
				"d1x" : "/tsubakifm@1x.jpg",
				"mob" : "/tsubakifm@1x.jpg"
			}
		},
		url : "https://tsubakifm.com/"
	},
	{
		title : 'Hishiiwa',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/hishiiwa@poster.jpg",
			src : {
				"d2x" : "/hishiiwa@2x.jpg",
				"d1x" : "/hishiiwa@1x.jpg",
				"mob" : "/hishiiwa@1x.jpg"
			}
		},
		url : "http://hishiiwa.com/"
	},
	{
		title : 'Awwwards Conf. TOKYO',
		category : 'Event',
		thumb : {
			mediaType:"image",
			poster : "/awwwardsconf@poster.jpg",
			src : {
				"d2x" : "/awwwardsconf@2x.jpg",
				"d1x" : "/awwwardsconf@1x.jpg",
				"mob" : "/awwwardsconf@1x.jpg"
			}
		},
		url : "https://conference.awwwards.com/tokyo/"
	},
	{
		title : 'Stone and Style',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/stone-and-style@poster.jpg",
			src : {
				"d2x" : "/stone-and-style@2x.jpg",
				"d1x" : "/stone-and-style@1x.jpg",
				"mob" : "/stone-and-style@1x.jpg"
			}
		},
		url : "https://stonestyle.co.th/"
	},
	{
		title : 'ORF2019',
		category : 'Event',
		thumb : {
			mediaType:"image",
			poster : "/orf2019@poster.jpg",
			src : {
				"d2x" : "/orf2019@2x.jpg",
				"d1x" : "/orf2019@1x.jpg",
				"mob" : "/orf2019@1x.jpg"
			}
		},
		url : "https://orf.sfc.keio.ac.jp/2019/"
	},
	{
		title : 'MU inc.',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/mu@poster.jpg",
			src : {
				"d2x" : "/mu@2x.jpg",
				"d1x" : "/mu@1x.jpg",
				"mob" : "/mu@1x.jpg"
			}
		},
		url : "https://mu-inc.net/"
	},
	{
		title : 'DRIFT',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/drift@poster.jpg",
			src : {
				"d2x" : "/drift@2x.jpg",
				"d1x" : "/drift@1x.jpg",
				"mob" : "/drift@1x.jpg"
			}
		},
		url : "http://drift.cc/"
	},
	{
		title : 'soar',
		category : 'Media',
		thumb : {
			mediaType:"image",
			poster : "/soar@poster.jpg",
			src : {
				"d2x" : "/soar@2x.jpg",
				"d1x" : "/soar@1x.jpg",
				"mob" : "/soar@1x.jpg"
			}
		},
		url : "https://soar-world.com/"
	},
	{
		title : '聖林公司 SEILIN',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/seilin@poster.jpg",
			src : {
				"d2x" : "/seilin@2x.jpg",
				"d1x" : "/seilin@1x.jpg",
				"mob" : "/seilin@1x.jpg"
			}
		},
		url : "https://www.hrm.co.jp/"
	},
	{
		title : 'Karimoku Cat',
		category : 'Brand',
		thumb : {
			mediaType:"image",
			poster : "/karimokucat@poster.jpg",
			src : {
				"d2x" : "/karimokucat@2x.jpg",
				"d1x" : "/karimokucat@1x.jpg",
				"mob" : "/karimokucat@1x.jpg"
			}
		},
		url : "https://karimoku-cat.jp/"
	},
	{
		title : 'ACRO',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/acro@poster.jpg",
			src : {
				"d2x" : "/acro@2x.jpg",
				"d1x" : "/acro@1x.jpg",
				"mob" : "/acro@1x.jpg"
			}
		},
		url : "https://www.acro-inc.co.jp/"
	},
	{
		title : 'Unleash',
		category : 'Media',
		thumb : {
			mediaType:"image",
			poster : "/unleash@poster.jpg",
			src : {
				"d2x" : "/unleash@2x.jpg",
				"d1x" : "/unleash@1x.jpg",
				"mob" : "/unleash@1x.jpg"
			}
		},
		url : "https://unleashmag.com/"
	},
	{
		title : 'UDATSU',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/udatsu@poster.jpg",
			src : {
				"d2x" : "/udatsu@2x.jpg",
				"d1x" : "/udatsu@1x.jpg",
				"mob" : "/udatsu@1x.jpg"
			}
		},
		url : "https://udatsu.co.jp/"
	},
	{
		title : 'Helixies',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/helixes@poster.jpg",
			src : {
				"d2x" : "/helixes@2x.jpg",
				"d1x" : "/helixes@1x.jpg",
				"mob" : "/helixes@1x.jpg"
			}
		},
		url : "https://helixes.co/"
	},
	{
		title : 'Newhattan',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/newhattan@poster.jpg",
			src : {
				"d2x" : "/newhattan@2x.jpg",
				"d1x" : "/newhattan@1x.jpg",
				"mob" : "/newhattan@1x.jpg"
			}
		},
		url : "https://newhattan.jp/"
	},
	{
		title : 'RINN',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/rinn@poster.jpg",
			src : {
				"d2x" : "/rinn@2x.jpg",
				"d1x" : "/rinn@1x.jpg",
				"mob" : "/rinn@1x.jpg"
			}
		},
		url : "https://rinn.co.jp/"
	},
	{
		title : 'MEIHOU',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/meihou@poster.jpg",
			src : {
				"d2x" : "/meihou@2x.jpg",
				"d1x" : "/meihou@1x.jpg",
				"mob" : "/meihou@1x.jpg"
			}
		},
		url : "https://www.meihou-ac.co.jp/"
	},
	{
		title : 'fabriq',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/fabriq@poster.jpg",
			src : {
				"d2x" : "/fabriq@2x.jpg",
				"d1x" : "/fabriq@1x.jpg",
				"mob" : "/fabriq@1x.jpg"
			}
		},
		url : "https://fabriq.co.jp/"
	}
]