/*
 ██████╗██╗      █████╗ ███████╗███████╗
██╔════╝██║     ██╔══██╗██╔════╝██╔════╝
██║     ██║     ███████║███████╗███████╗
██║     ██║     ██╔══██║╚════██║╚════██║
╚██████╗███████╗██║  ██║███████║███████║
 ╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝ items
*/


export class SCENE_ITEM {

	constructor( meshes, lights, width, height, name ){

		//
		this.name = name;

		//
		this.stage = new THREE.Group();

		this._RES = MID_RES;

		//
		this.width  = width * this._RES;
		this.height = height * this._RES;

		//
		this.aspect = this.width/this.height;

		//
		this.scene = new THREE.Scene();
		this.camera = new THREE.OrthographicCamera( -this.height * this.aspect/2, this.height * this.aspect/2, this.height/2, -this.height/2, -this.width * 3, this.width * 3 );
		this.camera.position.z = 1;
		this.scene.add( this.camera );
		// this.scene.background = COLOR.red.three;

		//
		this.fbo = new THREE.WebGLRenderTarget(
			this.width  * this._RES,
			this.height * this._RES,
			{ minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter }
		);

		//
		this.meshes = meshes;
		this.lights = lights;

		//
		this.initMesh();
		this.initLight();
		this.resize();

		//
		this.scene.add( this.stage );

	}

	initMesh(){

		//
		Object.keys( this.meshes ).forEach( ( k ) => {

			const target = RESOURCE.items[ k ];
			const w = this.meshes[k];

			if( target.mergeGeometry ){

				const material = new THREE.MeshPhysicalMaterial({
					side : THREE.DoubleSide
				});
				if( w.uniforms.color ){
					material.color  = w.uniforms.color.three;
				} else {
					material.color  = target.material.color;
				}

				if( w.uniforms.emissive ){
					material.emissive  = w.uniforms.emissive.three;
				} else {
					material.emissive  = COLOR_ORIGIN.black.three;
				}
				if( w.uniforms.roughness ) material.roughness = w.uniforms.roughness;
				if( w.uniforms.metalness ) material.metalness = w.uniforms.metalness;
				if( w.uniforms.clearcoat ) material.clearcoat = w.uniforms.clearcoat;
				if( w.uniforms.clearcoatRoughness ) material.clearcoatRoughness = w.uniforms.clearcoatRoughness;
				if( w.uniforms.reflectivity ) material.reflectivity = w.uniforms.reflectivity;

				//
				const mesh = new THREE.Mesh( target.geometry, material );
				mesh.visible = true;
				w.mesh.push( mesh );
				this.stage.add( mesh );

			} else {

				//
				for (let i = 0; i < target.meshes.length; i++) {

					//===
					const clone = target.meshes[i].clone();
					if( w.uniforms.roughness ) clone.material.roughness = w.uniforms.roughness;
					if( w.uniforms.metalness ) clone.material.metalness = w.uniforms.metalness;
					if( w.uniforms.clearcoat ) clone.material.clearcoat = w.uniforms.clearcoat;
					if( w.uniforms.clearcoatRoughness ) clone.material.clearcoatRoughness = w.uniforms.clearcoatRoughness;
					if( w.uniforms.reflectivity ) clone.material.reflectivity = w.uniforms.reflectivity;

					//===
					w.mesh.push( clone );
					clone.visible = true;
					this.stage.add( clone );

				}

			}

		});

	}

	initLight(){

		//
		for (let i = 0; i < this.lights.length; i++) {
			//
			const l = this.lights[i];
			switch( l.type ){
				case 'point':

					//
					l.mesh = new THREE.PointLight( l.color.three );
					l.mesh.intensity  = l.intensity;

					// 20240710
					l.mesh.castShadow = false;

					l.mesh.distance   = this.width * l.distance;
					l.mesh.position.x = this.width * l.position.x;
					l.mesh.position.y = this.width * l.position.y;
					l.mesh.position.z = this.width * l.position.z;
					this.scene.add( l.mesh );
			
				break;
				case 'dir':

					//
					l.mesh = new THREE.DirectionalLight( l.color.three );
					l.mesh.intensity  = l.intensity;

					// 20240710
					l.mesh.castShadow = false;

					l.mesh.position.x = this.width * l.position.x;
					l.mesh.position.y = this.width * l.position.y;
					l.mesh.position.z = this.width * l.position.z;
					this.scene.add( l.mesh );
			
				break;
			}
		}

	}

	resize( width, height ){

		//
		this.width  = width * this._RES;
		this.height = height * this._RES;

		//
		Object.keys( this.meshes ).forEach( ( k ) => {
			const w = this.meshes[k];
			switch( this.name ){
				case 'eyeGroup':
					for (let i = 0; i < w.mesh.length; i++) {
						w.mesh[i].scale.x = w.scale.x * this.width;
						w.mesh[i].scale.y = w.scale.y * this.width;
						w.mesh[i].scale.z = w.scale.z * this.width;
					}
				break;
				default:
					for (let i = 0; i < w.mesh.length; i++) {
						w.mesh[i].scale.x = w.scale.x * this.width * (1/w.boundingSphere.radius) * 0.5;
						w.mesh[i].scale.y = w.scale.y * this.width * (1/w.boundingSphere.radius) * 0.5;
						w.mesh[i].scale.z = w.scale.z * this.width * (1/w.boundingSphere.radius) * 0.5;
					}			
				break;
			}
		});

	}

	inview( visible ){

		//
		this.stage.visible = visible;

		//
		for (let i = 0; i < this.lights.length; i++) {
			const l = this.lights[i];
			if( l.mesh ) l.mesh.visible = visible;
		}

	}

	render(){
		this.stage.rotation.y = BACKGL.rad.normal.val;
		// switch( this.name ){
		// 	case 'eyeGroup':
		// 		//
		// 		const eye_x = mouseEvent.anims.normal.sx * this.width * 0.025;
		// 		const eye_y = mouseEvent.anims.normal.sy * this.width * 0.025;

		// 		//
		// 		this.stage.rotation.x = -mouseEvent.anims.normal.sy * 0.05;
		// 		this.stage.rotation.y =  mouseEvent.anims.normal.sx * 0.05;

		// 		//
		// 		for (let i = 0; i < this.meshes['eyes'].mesh.length; i++) {
		// 			const eye = this.meshes['eyes'].mesh[i];
		// 			switch( eye.name ){
		// 				case 'black':
		// 					eye.position.x = eye_x * 2;
		// 					eye.position.y = eye_y * 2;
		// 				break;
		// 				case 'white':
		// 					eye.position.x = eye_x;
		// 					eye.position.y = eye_y;
		// 				break;
		// 			}
		// 		}
		// 	break;
		// 	default:
		// 		this.stage.rotation.y = BACKGL.rad.normal.val;
		// 	break;
		// }
	}

	blink(){

		// const w = this.meshes['eyes'];
		// for (let i = 0; i < w.mesh.length; i++) {
		// 	var eye = w.mesh[i];
		// 	var tl = gsap.timeline({ repeat:1 });
		// 	tl.to( eye.scale, {
		// 		duration : .1,
		// 		z : 0,
		// 		ease : 'power2.inOut',
		// 	});
		// 	tl.to( eye.scale, {
		// 		duration : .2,
		// 		z : w.scale * RESCALE.r * 2,
		// 		ease : 'power2.inOut',
		// 	});
		// }

	}

}





























