/*
██████╗  ██████╗ ███╗   ███╗
██╔══██╗██╔═══██╗████╗ ████║
██║  ██║██║   ██║██╔████╔██║
██║  ██║██║   ██║██║╚██╔╝██║
██████╔╝╚██████╔╝██║ ╚═╝ ██║
╚═════╝  ╚═════╝ ╚═╝     ╚═╝ ui
*/



window.styleMenu = {

	isopen : false,

	onToggle(){
		if( this.isopen ){
			this.onClose();
		} else {
			this.onOpen();
		}
	},

	onClose(){
		$html.classList.remove('is-menu-opened');
		this.isopen = false;
	},

	onOpen(){
		$html.classList.add('is-menu-opened');
		this.isopen = true;
	}

}



window.styleUI = {

	progress : {
		el : null,
		p : 0,
	},

	once(){

		//
		this.progress.el = document.querySelector('.js-sc-progress');
		// gsap.set( this.progress.el, {
		// 	drawSVG : '0% 0%'
		// });

	},

	onReset(){
		gsap.set( this.progress.el, {
			drawSVG : '0% 0%'
		});
	},

	onProgress( loop, p ){
		if( loop%2 === 0 ){
			gsap.set( this.progress.el, {
				drawSVG : (p*100)+'% 100%'
			});
		} else {
			gsap.set( this.progress.el, {
				drawSVG : '0% '+ (p*100) +'%'
			});
		}
	},

	onMouseEnterA(e){
		const $btn = e.target.classList.contains('ui-btn') ? e.target : e.target.querySelector('.ui-btn');
		if( $btn && $btn.dataset.btnType ){

			//
			const type = $btn.dataset.btnType;
			const rect = $btn.getBoundingClientRect();
			const centerX = rect.left + rect.width/2;
			const centerY = rect.top  + rect.height/2;
			const distX = Math.floor(getClamp( (centerX - e.clientX)/rect.width * 2, -1, 1 ) * 50 );
			const distY = Math.floor(getClamp( (centerY - e.clientY)/rect.height * 2, -1, 1 ) * 50 );
			const $bg = $btn.querySelector('.bg');
			if( $bg ){
				$bg.style.transform = 'translate3d('+ -distX +'%,'+ -distY +'%,0)';
			}

		}

	},

	onMouseLeaveA(e){
		const $btn = e.target.classList.contains('ui-btn') ? e.target : e.target.querySelector('.ui-btn');
		if( $btn && $btn.dataset.btnType ){
			//
			const type = $btn.dataset.btnType;
			const rect = $btn.getBoundingClientRect();
			const centerX = rect.left + rect.width/2;
			const centerY = rect.top  + rect.height/2;
			const distX = Math.floor(getClamp( (centerX - e.clientX)/rect.width * 2, -1, 1 ) * 50 );
			const distY = Math.floor(getClamp( (centerY - e.clientY)/rect.height * 2, -1, 1 ) * 50 );
			const $bg = $btn.querySelector('.bg');
			if( $bg ){
				$bg.style.transform = 'translate3d('+ -distX +'%,'+ -distY +'%,0)';
			}
		}
	}

}



















