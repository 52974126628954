import Vue from 'vue';

Vue.filter('addZero', function (val) {
	const num = Number(val);
	const zero = ( '00' + num ).slice( -2 );
	return zero;
});

Vue.filter('addMax', function (val) {
	const num = Math.max( Number(val), 25 );
	return num;
});

Vue.filter('addAbs', function (val) {
	const num = Math.abs( Number(val) );
	return num;
});