/*
██████╗  █████╗  ██████╗██╗  ██╗ ██████╗ ██╗     
██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔════╝ ██║     
██████╔╝███████║██║     █████╔╝ ██║  ███╗██║     
██╔══██╗██╔══██║██║     ██╔═██╗ ██║   ██║██║     
██████╔╝██║  ██║╚██████╗██║  ██╗╚██████╔╝███████╗
╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝ office
*/


import { SCENE_OFFICE } from '/assets/js/webgl/class/c-office.js';
import borderRadiusFrag from '~/assets/js/shader/frag/borderRadius.frag';

BACKGL.scenes.office = {

	ready : false,

	setZIndex(){

		this.screen.position.z = 300;

	},

	onLoaded(){

		this.world = new SCENE_OFFICE( 1, 1.25490196 );
		// this.onIniview();

		const geometry = new THREE.PlaneBufferGeometry( 1, 1 );
		const material = new THREE.ShaderMaterial({
			vertexShader   : `
				varying vec2 vUv;
				void main(){
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
				}
			`,
			fragmentShader : borderRadiusFrag,
			uniforms: {
				borderRadius: { value : 0.05 },
				LT: { value : 1 },
				RT: { value : 1 },
				RB: { value : 1 },
				LB: { value : 1 },
				resolution: { value: { x: 0, y: 0 } },
				tDiffuse : { value: this.world.fbo.texture },
			},
			transparent: true,
		});
		material.alphaTest = 0.5;
		this.screen = new THREE.Mesh( geometry, material );
		this.screen.visible = false;
		BACKGL.sceneFrontSide.add( this.screen );

		//
		this.onResize();
		this.setZIndex();

		//
		this.ready = true;

	},

	onIniview( dom ){
		if( this.screen && this.world ){
			this.screen.visible = dom.visible;
			this.world.inview( dom.visible );
		}
	},

	onReset(){
		if( this.screen ) this.screen.visible = false;
		if( this.world ) this.world.inview( false );
	},

	onResize(){
		for (let i = 0; i < BACKGL.domElements.length; i++) {
			const dom = BACKGL.domElements[i];
			if( dom.name === 'office' ){
				this.world.resize();
				this.screen.scale.x = dom.rect.width;
				this.screen.scale.y = dom.rect.height;
				this.screen.material.uniforms.resolution.value.x = this.screen.scale.x;
				this.screen.material.uniforms.resolution.value.y = this.screen.scale.y;
				this.screen.material.uniforms.borderRadius.value = __WW__ / dom.rect.width * BORDER_RADIUS;
				this.screen.position.x = -__WW__/2 + this.screen.scale.x/2 + dom.rect.left;
				this.screen.position.y = __WH__*2;
			}
		}
	},

	onRender(){
		BACKGL.renderer.bg.render.setRenderTarget( this.world.fbo );
		BACKGL.renderer.bg.render.render( this.world.scene, this.world.camera );
	},

	onScroll(){
		for (let i = 0; i < BACKGL.domElements.length; i++) {
			const dom = BACKGL.domElements[i];
			if( dom.name === 'office' && dom.visible ){
				const ty = __WH__/2 - dom.rect.height/2 - dom.rect.top;
				this.screen.position.y = ty + stylePageScroll.body.y;
				this.world.render( (ty + stylePageScroll.body.y)/__WH__ );
			}
		}
	}

}