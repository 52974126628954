window.PATH = {
	env     : '/assets/tex/envMap-assets',
	models  : '/assets/models/blender',
	tex     : '/assets/tex',
	archive : '/assets/archives-assets' 
};


// window.BORDER_RADIUS = 1.5/48;
window.BORDER_RADIUS = 3/48;


//
window.ANIM = {
	dom : {
		leave : {
			s : 0.3,
			e : 'power2.in',
			d : 0,
		},
		enter : {
			s : 0.6,
			e : 'power2.in',
			d : 0.1,
		}
	},
	webgl : {
		leave : {
			s : 0.3,
			e : 'power2.out',
			d : 0,
		},
		enter : {
			s : 2.0,
			e : 'power2.out',
			d : 0.1,
		}
	},
	ground : {
		in : {
			s : .1,
			e : 'power2.out'
		},
		out : {
			s : .1,
			e : 'power2.out'
		}
	},
	contents : {
		fade : {
			s : 0.6,
			e : 'power2.inOut',
			d : 0.05,
		},
		scale : {
			s : 2.4,
			e : 'power2.out',
		}
	},
	party : {
		twist : {
			hide : {
				s : 0.8,
				e : 'power2.in',
				d : 0.1
			},
			show : {
				// s : 2.6,
				s : 2.6,
				// e : 'power2.out',
				e : 'elastic.out( 0.5, 0.3 )',
				// s : 5,
				// e : back.out(1)',
				d : 0.05
			}
		},
		progress : {
			interval : {
				s : 10,
				e : 'power0.none'
			},
			leave : {
				s : 2,
				e : 'power2.inOut'
			},
			rotate : {
				s : 2,
				e : 'power2.inOut'
			}
		}
	},
	once(){
		if( IS_REDUCED && DETECT.device.any ){
			this.dom.leave.s = this.dom.enter.s = this.webgl.leave.s = this.webgl.enter.s = this.ground.in.s = this.ground.out.s = this.contents.fade.s = this.contents.scale.s = 0;
		}
	}
}


// #dbd6d0
window.COLOR = {

	background : {
		css   : '#dbd6d0',
		three : new THREE.Color('#dbd6d0'),
	},
	ground: {
		three : new THREE.Color('rgb(3,3,3)'),
	},

	groundNoShadow : {
		three : new THREE.Color('rgb(1,1,1)'),
	},

	// PARTY
	partyBg : {
		css   : '#dbd6d0', // dbd6d0
		three : new THREE.Color('#dbd6d0'),
	},
	partyUpLight : {
		three : new THREE.Color('#f59a75') // 薄いオレンジ
	},
	partyBackLight : {
		three : new THREE.Color('#ff9256') // オレンジ
	},
	partyFrontLight : {
		three : new THREE.Color('#de5e18') // 濃いオレンジ
	},
	partyModelColor : {
		three : new THREE.Color('rgb(0,1,1)'),
	},
	partyModelEmissive : {
		three : new THREE.Color('#504943'),
	},

	//
	officeFog : {
		three : new THREE.Color('#d5c8b9'),
	},
	officePointLight : {
		three : new THREE.Color('#b97e47'),
	},

	//
	homeBlurColor : {
		three : new THREE.Color('#f00'),
	}

}

window.COLOR_ORIGIN = {

	red : {
		css   : '#f00',
		three : new THREE.Color('#f00'),
	},

	//===
	white : {
		css : '#c2c2c2',
		three : new THREE.Color('#c2c2c2'),
	},

	//===
	black : {
		css : 'rgb(30,31,31)',
		three : new THREE.Color('rgb(30,31,31)'),
	},
	black2 : {
		css : 'rgb(0,1,1)',
		three : new THREE.Color('rgb(0,1,1)'),
	},
	black3 : {
		css : 'rgb(18,19,19)',
		three : new THREE.Color('rgb(18,19,19)'),
	},
	black4 : {
		three : new THREE.Color('rgb(51,51,51)'),
	},
	black5 : {
		three : new THREE.Color('rgb(120,120,120)'),
	},

	//===
	lightBrown : {
		css   : '#e7e2db', // dbd6d0
		three : new THREE.Color('#e7e2db'),
	},
	brown : {
		css : '#d5c8b9',
		three : new THREE.Color('#d5c8b9'),
	},
	deepBrown : {
		css : '#504943',
		three : new THREE.Color('#504943'),
	},

	//===
	lightOrange : {
		three : new THREE.Color('#f59a75') // 薄いオレンジ
	},
	orange : {
		three : new THREE.Color('#ff6e1e') // オレンジ
	},
	deepOrange : {
		three : new THREE.Color('#fe6222') // 濃いオレンジ
	}

}




//
window.RESCALE = {
	r : 0,
	onResize(){
		this.r = window.innerWidth * 0.1;
	}
}
RESCALE.onResize();



//
window.ACTION = {
	any : true,
}
