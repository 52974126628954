/*
██████╗  ██████╗ ███╗   ███╗
██╔══██╗██╔═══██╗████╗ ████║
██║  ██║██║   ██║██╔████╔██║
██║  ██║██║   ██║██║╚██╔╝██║
██████╔╝╚██████╔╝██║ ╚═╝ ██║
╚═════╝  ╚═════╝ ╚═╝     ╚═╝ mouse
*/

/*
███╗   ███╗ ██████╗ ██╗   ██╗███████╗███████╗    ███████╗██╗   ██╗███████╗███╗   ██╗████████╗
████╗ ████║██╔═══██╗██║   ██║██╔════╝██╔════╝    ██╔════╝██║   ██║██╔════╝████╗  ██║╚══██╔══╝
██╔████╔██║██║   ██║██║   ██║███████╗█████╗      █████╗  ██║   ██║█████╗  ██╔██╗ ██║   ██║   
██║╚██╔╝██║██║   ██║██║   ██║╚════██║██╔══╝      ██╔══╝  ╚██╗ ██╔╝██╔══╝  ██║╚██╗██║   ██║   
██║ ╚═╝ ██║╚██████╔╝╚██████╔╝███████║███████╗    ███████╗ ╚████╔╝ ███████╗██║ ╚████║   ██║   
╚═╝     ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝    ╚══════╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝   ╚═╝   
*/

//
window.mouseEvent = {

	x  : 0,
	y  : 0,
	cx : 0,
	cy : 0,
	ax : 0, // 絶対位置
	ay : 0, // 絶対位置

	start : {
		x  : 0,
		y  : 0,
	},

	ing : {
		x  : 0,
		y  : 0,
	},

	dist : {
		x  : 0,
		y  : 0,
	},

	onStart( e ){
		let x = e.clientX;
		let y = e.clientY;
		this.start.x = x;
		this.start.y = y;
		this.dist.x  = 0;
		this.dist.y  = 0;
		this.active = true;
	},

	cursor : {
		el : null,
		bg : null,
		ing : false,
		enter : null,
		leave : null,
	},

	once(){

		const _this = this;

		//
		this.cursor.el = document.querySelector('.ui-cursor');
		// this.cursor.bg = this.cursor.el.querySelector('.bg');
		// this.cursor.bg.addEventListener('transitionstart', function(){
		// 	if( $app.dataset.cursorType === 'view' ){
		// 		$app.dataset.cursorIng = 1;
		// 		_this.cursor.ing = true;
		// 	} else {
		// 		$app.dataset.cursorIng = 2;
		// 	}
		// });
		// this.cursor.bg.addEventListener('transitionend', function(){
		// 	if( $app.dataset.cursorType != 'view' ){
		// 		$app.dataset.cursorIng = 3;
		// 		_this.cursor.ing = false;
		// 	} else {
		// 		$app.dataset.cursorIng = 4;
		// 	}
		// });


		//
		this.x  = window.innerWidth/2;
		this.y  = window.innerHeight/2;
		this.cx = (this.x - window.innerWidth/2) / window.innerWidth;
		this.cy = (this.y - window.innerHeight/2) / window.innerHeight;

		//
		Object.keys( this.anims ).forEach( (key) => {
			this.array.push( key );
		});

		//
		this.getSpeed();

	},

	onEnterCursor(type){
		const _this = this;
		if( this.cursor.leave ) this.cursor.leave.kill();
		this.cursor.enter = gsap.to( this.cursor.anim, {
			duration : .3,
			scale : 1,
			onStart(){
				_this.cursor.ing = true;
			},
			onComplete(){

			}
		});
	},
	onLeaveCursor(type){
		const _this = this;
		if( this.cursor.enter ) this.cursor.enter.kill();
		this.cursor.leave = gsap.to( this.cursor.anim, {
			duration : .3,
			scale : 0,
			onStart(){

			},
			onComplete(){
				_this.cursor.ing = false;
			}
		});
	},

	onMove( e ){
		const _this = this;

		//
		this.x  = e.clientX;
		this.y  = e.clientY;

		//
		this.ing.x  = this.x;
		this.ing.y  = this.y;
		this.dist.x = this.start.x - this.x;
		this.dist.y = this.start.y - this.y;

		//
		this.cx = (this.x - window.innerWidth/2) /  window.innerWidth;
		this.cy = (this.y - window.innerHeight/2) / window.innerHeight;
		if( this.cx < 0 ){ this.ax = -1 } else if( this.cx > 0 ){ this.ax = 1 } else { this.ax = 0 }
		if( this.cy < 0 ){ this.ay = 1 } else if( this.cx > 0 ){ this.ay = -1 } else { this.ay = 0 }
		this.speed.currentEvent = e;

	},

	onEnd(){
		this.dist.x = 0;
		this.dist.y = 0;
		this.active = false;
	},

	speed : {
		prevEvent    : null,
		currentEvent : null,
		x     : 0,
		y     : 0,
		xy    : 0,
		delay : 50,
	},

	camera : { x: 0, y: 0, },

	array  : [],

	anims : {
		normal : {
			x  : 0,
			y  : 0,
			sx : 0, // shader
			sy : 0, // shader
			cx : 0, 
			cy : 0, 
			center : { x  : 0, y  : 0, ease : 0.05, },
			speed : { x  : 0, y  : 0, xy : 0, ease : 0.1, },
			delta : { x : 0, y : 0, ease : 0.05, },
			// rotaion : { x : 0, y : 0, z : 0, },
		},
		slow : {
			x  : 0,
			y  : 0,
			sx : 0, // shader
			sy : 0, // shader
			cx : 0, 
			cy : 0, 
			center : { x  : 0, y  : 0, ease : 0.025/2, },
			speed : { x  : 0, y  : 0, xy : 0, ease : 0.025/2, },
			delta : { x : 0, y : 0, ease : 0.05/2, },
			rotaion : { x : 0, y : 0, z : 0, },
		},
		cursor : {
			x  : 0,
			y  : 0,
			sx : 0, // shader
			sy : 0, // shader
			cx : 0, 
			cy : 0, 
			center : { x  : 0, y  : 0, ease : 0.15, },
			speed : { x  : 0, y  : 0, xy : 0, ease : 0.05, },
			delta : { x : 0, y : 0, ease : 0.075, },
			// camera : { x : 0, y : 0, },
			// rotaion : { x : 0, y : 0, z : 0, },
		}
	},

	getSpeed(){

		setInterval(() => {
			if( this.speed.prevEvent && this.speed.currentEvent){
				const x = this.speed.currentEvent.screenX - this.speed.prevEvent.screenX;
				const y = this.speed.currentEvent.screenY - this.speed.prevEvent.screenY;
				const ax = Math.abs( x );
				const ay = Math.abs( y );
				this.speed.x  = x;
				this.speed.y  = y;
				this.speed.xy = Math.sqrt( x * x + y * y );
			}
			this.speed.prevEvent = this.speed.currentEvent;
		}, this.speed.delay );

	},

	onUpdate(){

		for (let i = 0; i < this.array.length; i++) {

			const key = this.array[i];
			const v   = this.anims[key];

			//===
			v.speed.x  += ( this.speed.x - v.speed.x ) * v.speed.ease;
			v.speed.y  += ( this.speed.y - v.speed.y ) * v.speed.ease;
			v.speed.xy += ( this.speed.xy - v.speed.xy ) * v.speed.ease;
			v.center.x += ( this.cx - v.center.x ) * v.center.ease;
			v.center.y += ( this.cy - v.center.y ) * v.center.ease;
			v.delta.x  += ( this.x - v.delta.x + v.speed.x ) * v.delta.ease;
			v.delta.y  += ( this.y - v.delta.y + v.speed.y ) * v.delta.ease;

			//===
			v.x  = v.delta.x;
			v.y  = v.delta.y;
			v.sx = getClamp( v.delta.x/__WW__, 0, 1 );
			v.sy = getClamp( 1 - v.delta.y/__WH__, 0, 1);
			v.cx = (v.x -  __WW__/2) /  __WW__;
			v.cy = (v.y - __WH__/2) / __WH__;

			if( v.rotaion ){
				// v.rotaion.x = v.cx * 60 + v.speed.x * 0.2;
				// v.rotaion.y = v.cy * 120 + v.speed.y * 0.2;
				// v.rotaion.z = v.speed.x * 0.05;
				gsap.to( v.rotaion, {
					z : v.speed.x,
					duration : 2,
					ease : 'back.out(4)',
				});
			}

		}

		// cursorEvent.onUpdate();
		// styleBudge.onUpdate();

		this.cursor.el.style.transform = "translate3d("+ this.anims.cursor.x +"px, "+ this.anims.cursor.y +"px, 0)";

	}

};



/*
████████╗ ██████╗ ██╗   ██╗ ██████╗██╗  ██╗    ███████╗██╗   ██╗███████╗███╗   ██╗████████╗
╚══██╔══╝██╔═══██╗██║   ██║██╔════╝██║  ██║    ██╔════╝██║   ██║██╔════╝████╗  ██║╚══██╔══╝
   ██║   ██║   ██║██║   ██║██║     ███████║    █████╗  ██║   ██║█████╗  ██╔██╗ ██║   ██║   
   ██║   ██║   ██║██║   ██║██║     ██╔══██║    ██╔══╝  ╚██╗ ██╔╝██╔══╝  ██║╚██╗██║   ██║   
   ██║   ╚██████╔╝╚██████╔╝╚██████╗██║  ██║    ███████╗ ╚████╔╝ ███████╗██║ ╚████║   ██║   
   ╚═╝    ╚═════╝  ╚═════╝  ╚═════╝╚═╝  ╚═╝    ╚══════╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝   ╚═╝   
*/


//
window.touchEvent = {

	dir : 0,

	active : false,

	start : {
		x  : 0,
		y  : 0,
		cx : 0,
		cy : 0,
	},

	dist : {
		x  : 0,
		y  : 0,
	},

	ing : {
		x  : 0,
		y  : 0,
	},

	onStart(e){
		let x = e.clientX;
		let y = e.clientY;
		if (e.touches && e.touches[0]) {
			x = e.touches[0].clientX;
			y = e.touches[0].clientY;
		}
		this.start.x = x;
		this.start.y = y;
		this.start.cx = ( x -  __WW__/2 ) /  __WW__;
		this.start.cy = ( y - __WH__/2 ) / __WH__;
		this.dist.x  = 0;
		this.dist.y  = 0;
		// if ( e.touches.length > 1) {
		// 	e.preventDefault();
		// }
		this.active = true;
	},

	onMove(e){
		let x = e.clientX;
		let y = e.clientY;
		if (e.touches && e.touches[0]) {
			x = e.touches[0].clientX;
			y = e.touches[0].clientY;
		} else if ( e.originalEvent && e.originalEvent.changedTouches[0] ) {
			x = e.originalEvent.changedTouches[0].clientX;
			y = e.originalEvent.changedTouches[0].clientY;
		}
		this.ing.x  = x;
		this.ing.y  = y;
		this.dist.x = this.start.x - x;
		this.dist.y = this.start.y - y;
	},

	onEnd(e){
		this.dist.x  = 0;
		this.dist.y  = 0;
		this.active = false;
	}

}








