/*
 ██████╗██╗      █████╗ ███████╗███████╗
██╔════╝██║     ██╔══██╗██╔════╝██╔════╝
██║     ██║     ███████║███████╗███████╗
██║     ██║     ██╔══██║╚════██║╚════██║
╚██████╗███████╗██║  ██║███████║███████║
 ╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝ party
*/
import vert from '~/assets/js/shader/vert/animals.vert';
// import { SCENE_PARTY_PHYSICS_TEXT } from './c-party-physics-text.js';
import { SCENE_PARTY_PHYSICS } from './c-party-physics.js';

export class SCENE_PARTY {

	constructor( useScreen = false, useFbo = false ){

		this.twistPower = 3;

		this.ANIMALS = {
			lizard: {
				visible: false,
				position: { x: 0, y: 0, z: 0, },
				rotation: { x: 0, y: 0, z: 0, },
				scale: 12
			},
			raccoon: {
				visible: true,
				position: { x: 0, y: 0, z: 0, },
				rotation: { x: 0, y: 0, z: 0, },
				scale: 7
			},
			rabbit: {
				visible: false,
				position: { x: 0, y: 0, z: 0, },
				rotation: { x: 0, y: 0, z: 0, },
				scale: 10.5
			},
			cat: {
				visible: false,
				position: { x: 0, y: 0, z: 0, },
				rotation: { x: 0, y: 0, z: 0, },
				scale: 8.7
			},
			fox: {
				visible: false,
				position: { x: 0, y: 0, z: 0, },
				rotation: { x: 0, y: 0, z: 0, },
				scale: 6
			},
			goat: {
				visible: false,
				position: { x: 0, y: 0, z: 0, },
				rotation: { x: 0, y: 0, z: 0, },
				scale: 5.8
			},
			koala:{
				visible: false,
				position: { x: 0, y: 0, z: 0, },
				rotation: { x: 0, y: 0, z: 0, },
				scale: 9.2
			}
		}

		this.LIGHTS = [
			{ // 上から
				color: {
					name : 'partyUpLight',
				},
				position: { x: -4, y: 12, z: 6 },
				intensity: 25,
				distance: 90,
				decay: 0.1,
				mesh : null,
			},
			{ // 正面から 色を乗せる
				color: {
					name : 'partyFrontLight',
				},
				position: { x: 2, y: -2, z: 10 },
				intensity: 12,
				distance: 80,
				decay: 0.1,
				mesh : null,
			},
			// { // 下から
			// 	color: {
			// 		css : '#f78c62',
			// 		three : new THREE.Color('#f78c62')
			// 	},
			// 	position: { x: 0, y: -10, z: -12},
			// 	intensity: 10,
			// 	distance: 80,
			// 	decay: 0.1,
			// 	mesh : null,
			// },
			{ // 逆光 輪郭を際立たせる
				color: {
					name : 'partyBackLight',
				},
				position: { x: 0, y: 0, z: -12},
				intensity: 80,
				distance: 100,
				decay: 0.1,
				mesh : null,
			},
		]

		this.PARTY_SCALE = 0.1;
		this.POSITIONS = [
			{x: -2400, y: -100,  z: -1300 }, //1
			{x: -2100, y: -1000, z: -1700 }, //2
			{x: -2000, y: -180,  z: -1500 }, //3
			{x: -1300, y: -1000, z: -1400 }, //4
			{x: -1127, y: -700, z: -1500 }, //5
			{x: -800,  y: -400, z: -1200 }, // * 6
			{x: -650,  y: -100, z: -1700 }, //7
			{x: -450,  y: -650, z: -1400 }, //8
			{x: 50,    y: -350, z: -1900}, //9
			{x: 450,   y: 350,  z: -1700 }, // * 10
			{x: 900,   y: 500,  z: -1600 }, //11
			{x: 1100,  y: 900, z: -1800 }, //12
			{x: 1300,  y: -100, z: -1500 }, //13
			{x: 2000,  y: 1100, z: -1500 }, //14
			{x: 2030,  y: 250,  z: -1300 }, //15
			{x: 2300,  y: 500,  z: -1800 }, //16
		];

		//
		this.ROTATIONS = [
			{x: getRad(-30), y: getRad( 0),  z: getRad(45)},//1
			{x: getRad(-90), y: getRad(20),  z: getRad( 0)},//2
			{x: getRad(100), y: getRad(10),  z: getRad(30)},//3
			{x: getRad(45),  y: getRad(-40), z: getRad(-60)},//4
			{x: getRad(30),  y: getRad(30),  z: getRad(90)},//5
			{x: getRad(-90), y: getRad(-10), z: getRad(0)}, // * 6
			{x: getRad(-40), y: getRad(-40), z: getRad( 0)},//7
			{x: getRad(-90),  y: getRad(10), z: getRad(-10)},//8
			{x: getRad(80), y: getRad(-30), z: getRad(10)},//9
			{x: getRad(100), y: getRad(-30), z: getRad(-30)}, // * 10
			{x: getRad(-30), y: getRad(-30), z: getRad(45)},//11
			{x: getRad(80),  y: getRad(-80), z: getRad(-90)},//12
			{x: getRad( 0),  y: getRad(90),  z: getRad(20)},//13
			{x: getRad(-90), y: getRad(10),  z: getRad( 0)},//14
			{x: getRad(30),  y: getRad(-80), z: getRad(-20)},//15
			{x: getRad(-30), y: getRad(-20), z: getRad(40)},//16
		]

		//
		this.rescale = RESCALE.r;
		this.eachLength = this.POSITIONS.length;

		//
		this.eachGroup = new THREE.Group();
		this.each = { ... this.ANIMALS }

		//
		this.lightGroup = new THREE.Group();
		this.lights = [ ... this.LIGHTS ];

		//
		this.key = Object.keys( RESOURCE.animals );

		//==================================================
		// #Numberでの動物指定有効化
		//==================================================
		const hash = Number( location.hash.replace('#','') );
		let currentAnimalIndex = Math.floor( Math.random() * this.key.length );
		if( hash != 0 && hash <= this.key.length ){
			currentAnimalIndex = hash - 1;
		}

		//
		this.slider = {
			dotted : null,
			progress : 0,
			go : false,
			power : 0,
			prev : {
				index : 0,
				key : null
			},
			now  : {
				index : currentAnimalIndex,
				key   : this.key[ currentAnimalIndex ]
			}
		}

		//
		for (let i = 0; i < this.key.length; i++) {
			const k = this.key[i];
			const each = this.each[ k ];
			if( i === this.slider.now.index ){
				each.visible = true;
			} else {
				each.visible = false;
			}
		}

		//
		this.useScreen = useScreen;
		this.useFbo = useFbo;

		//
		this.width  = window.innerWidth;
		this.height = window.innerHeight;

		//
		this._RES = MID_RES;

		//
		if( useFbo ){
			this.fbo = new THREE.WebGLRenderTarget(
				this.width *  this._RES,
				this.height * this._RES,
				{ minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter }
			);
		}

		//
		this.scene = new THREE.Scene();

		//
		this.ratio = 1 / ( (this.width/this.height) / 1.8 );
		this.fog = {
			color : COLOR.partyBg.three,
			near  : 0,
			far   : ( !DETECT.device.any ) ? 4.8 * this.ratio : 2 * this.ratio,
		};
		this.scene.fog = new THREE.Fog( this.fog.color, 0, getPxReScale( this.fog.far * 1440 ) );
		this.scene.background = this.fog.color;

		//
		this.fov = 45;
		this.camera = new THREE.PerspectiveCamera( this.fov, this.width / this.height, 1, getPxReScale( this.fog.far * 1440 ) );
		this.scene.add( this.camera );

		//==================================================
		// ディバイス間でのサイズ調整
		//==================================================
		this.eachPositions = [];
		for (let i = 0; i < this.POSITIONS.length; i++) {
			if( DETECT.device.tablet && !GRID.landscape ){
				this.eachPositions.push({
					x : getPxReScale( this.POSITIONS[i].x * 1.2 ),
					y : getPxReScale( this.POSITIONS[i].y ),
					z : getPxReScale( this.POSITIONS[i].z * 1.2 )
				});
			} else if( DETECT.device.mobile ){
				this.eachPositions.push({
					x : getPxReScale( this.POSITIONS[i].x * 0.7 * 1.2 ),
					y : getPxReScale( this.POSITIONS[i].y * 0.7 * 1.2 ),
					z : getPxReScale( this.POSITIONS[i].z * 0.9 * 1.2 )
				});
			} else {
				this.eachPositions.push({
					x : getPxReScale( this.POSITIONS[i].x ),
					y : getPxReScale( this.POSITIONS[i].y ),
					z : getPxReScale( this.POSITIONS[i].z )
				});
			}
		}

		//
		if( DETECT.device.tablet && !GRID.landscape ){
			this.PARTY_SCALE = this.PARTY_SCALE * 0.75;
		} else if( DETECT.device.mobile ){
			this.PARTY_SCALE = this.PARTY_SCALE * 0.8;
		}
		//==================================================
		// ディバイス間でのサイズ調整
		//==================================================

		//
		// this.PARTY_SCALE = this.PARTY_SCALE * 1/this.ratio;

		//
		this.uniform = {
			envMap : 'c3',
			color : {
				three : COLOR.partyModelColor.three,
			},
			emissive : {
				three : COLOR.partyModelEmissive.three
			},
			roughness          : 1.0,
			metalness          : 0.0,
			reflectivity       : 1.0,

			specularIntensity  : 0.8,
			clearcoat          : 0.5,
			clearcoatRoughness : 1.0,

			// specularIntensity  : 1,
			// clearcoat          : 0,
			// clearcoatRoughness : .39,

			opacity            : 1,
			twistPower         : 0,
			twistHeight        : 1.0,
			twistAngle         : 180.0,
			wavyPower          : 0.2,
			wavyRadius         : 0.75,
			wavyAngle          : 0.1,
		};

		this.array = [];
		this.activeMeshs = [];

		//
		this.init();
		this.initMesh();
		this.initLight();
		// this.intGui();
		if( this.useScreen ) this.initScreen();

		//
		if(!IS_REDUCED){
			this.physics = new SCENE_PARTY_PHYSICS( this, this.each, this.eachLength, this.eachPositions, this.ROTATIONS, this.PARTY_SCALE );
		} else {
			this.physics = null;
		}
		// this.physics.initText();

		//
		this.resize();

	}

	mousedown() { if( this.physics ) this.physics.mousedown() }
	mouseup()   { if( this.physics ) this.physics.mouseup() }

	// intGui(){

	// 	const _this = this;

	// 	this.folder = pane.addFolder({
	// 		title: 'Party',
	// 	});

	// 	this.folder.addInput( this.uniform, 'roughness', {
	// 		min: 0.0,
	// 		max: 1.0,
	// 	});
	// 	this.folder.addInput( this.uniform, 'metalness', {
	// 		min: 0.0,
	// 		max: 1.0,
	// 	});
	// 	this.folder.addInput( this.uniform, 'reflectivity', {
	// 		min: 0.0,
	// 		max: 1.0,
	// 	});
	// 	this.folder.addInput( this.uniform, 'specularIntensity', {
	// 		min: 0.0,
	// 		max: 1.0,
	// 	});
	// 	this.folder.addInput( this.uniform, 'clearcoat', {
	// 		min: 0.0,
	// 		max: 1.0,
	// 	});
	// 	this.folder.addInput( this.uniform, 'clearcoatRoughness', {
	// 		min: 0.0,
	// 		max: 1.0,
	// 	});

	// 	//
	// 	this.folder.on('change', function(ev) {

	// 		for (let i = 0; i < _this.array.length; i++) {
	// 			console.log( _this.array[i] );
	// 			const v = _this.array[i];
	// 			v.material.roughness = _this.uniform.roughness;
	// 			v.material.metalness = _this.uniform.metalness;
	// 			v.material.reflectivity = _this.uniform.reflectivity;
	// 			v.material.specularIntensity = _this.uniform.specularIntensity;
	// 			v.material.clearcoat = _this.uniform.clearcoat;
	// 			v.material.clearcoatRoughness = _this.uniform.clearcoatRoughness;
	// 		}

	// 	});
	// }

	init(){

		//============
		for (let i = 0; i < this.key.length; i++) {
			const k = this.key[i];
			const target = RESOURCE.animals[ k ];
			for (let n = 0; n < this.eachLength; n++) {

				//
				const material = new THREE.MeshPhysicalMaterial({
					// side               : THREE.DoubleSide,
					color              : this.uniform.color.three,
					emissive           : this.uniform.emissive.three,
					roughness          : this.uniform.roughness,
					metalness          : this.uniform.metalness,
					clearcoat          : this.uniform.clearcoat,
					clearcoatRoughness : this.uniform.clearcoatRoughness,
					reflectivity       : this.uniform.reflectivity,
					envMap             : RESOURCE.envMaps[ this.uniform.envMap ].tex,
					// envMap          : null,
					map                : null
				});

				//
				material.uniformsNeedUpdate = true;
				material.needsUpdate = true;
				material.onBeforeCompile = (shader) => {
					shader.uniforms.twistPower  = { value : this.uniform.twistPower };
					shader.uniforms.twistHeight = { value : this.uniform.twistHeight };
					shader.uniforms.twistAngle  = { value : this.uniform.twistAngle };
					shader.uniforms.wavyPower   = { value : this.uniform.wavyPower };
					shader.uniforms.wavyRadius  = { value : this.uniform.wavyRadius };
					shader.uniforms.wavyAngle   = { value : this.uniform.wavyAngle };
					shader.vertexShader = vert;
					material.userData.shader = shader;
				};

				const origin = {
					geometry   : {
						position : {
							array: null
						}
					},
					deg : Math.random() * 360,
					position : { x: 0, y: 0, z: 0 },
					scale : {
						x: 1,
						y: 1,
						z: 1,
						rand : Math.random()/2 + 1.0
					},
					force : 1,
					delay : n,
					power : 0,
					twistPower : 0,
					wavyAngle  : 0,
				};

				this.array.push({
					anim : {
						reset : [],
						hide  : [],
						show  : []
					},
					mesh     : null,
					geometry : target.geometry.clone(),
					material : material,
					map      : target.map,
					origin   : origin,
					name     : 'party',
				});

			}

		}

	}

	initMesh(){

		//
		this.array.forEach( (v,i) =>{

			const name = v.geometry.name;
			const each = this.each[ name ];

			//==========================================
			v.mesh = new THREE.Mesh( v.geometry, v.material );
			v.mesh.visible = each.visible;

			//==========================================
			// 向き変更（軸を変更）
			// v.mesh.rotation.x = Math.random() * 360 * (Math.PI / 180);
			// v.mesh.rotation.y = Math.random() * 360 * (Math.PI / 180);
			// v.mesh.rotation.z = Math.random() * 360 * (Math.PI / 180);
			const rot = this.ROTATIONS[i%this.eachLength];
			v.mesh.rotation.x = rot.x;
			v.mesh.rotation.y = rot.y;
			v.mesh.rotation.z = rot.z;

			//==========================================
			// 座標
			const n = i % this.eachLength;
			v.mesh.position.x = this.eachPositions[n].x;
			v.mesh.position.y = this.eachPositions[n].y;
			v.mesh.position.z = this.eachPositions[n].z;

			// ==========================================
			// polygonの初期コピー
			// const new_arr = v.mesh.geometry.attributes.position.array.slice( 0, v.geometry.attributes.position.array.length );
			// v.origin.geometry.position.array = new_arr;

			//
			this.eachGroup.add( v.mesh );

			// for physics
			if( name === this.slider.now.key ){
				this.activeMeshs.push(v);
			}

		});

		//
		this.scene.add( this.eachGroup );

		//
		this.setHide( this.slider.now.key );
		this.onSplash( this.slider.now.key, 1 );

	}

	initLight(){

		this.lights.forEach( (v,i) =>{
			v.mesh = new THREE.PointLight( COLOR[ v.color.name ].three );

			// 20240710
			v.mesh.castShadow = false;

			v.mesh.intensity = v.intensity;
			v.mesh.decay = v.decay;
			v.mesh.origin = {
				name : v.color.name,
				intensity : v.intensity,
				decay : v.decay
			}
			this.lightGroup.add(v.mesh);
		});
		this.scene.add( this.lightGroup );

	}

	initScreen(){

		const geometry = new THREE.PlaneBufferGeometry(1, 1, 1);
		const material = new THREE.ShaderMaterial({
			vertexShader   : `
				varying vec2 vUv;
				void main(){
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
				}
			`,
			fragmentShader : `
				varying vec2 vUv;
				uniform vec2 resolution;
				uniform vec2 scale;
				uniform sampler2D tDiffuse;
				void main() {
					vec4 tA = texture2D( tDiffuse, vUv );
					gl_FragColor = tA;
				}
			`,
			uniforms: {
				scale: { value: { x: 1, y: 1 } },
				resolution: { value: { x: 0, y: 0 } },
				tDiffuse : { value: this.fbo.texture },
			},
			transparent: false,
		});
		material.uniforms.resolution.value.x = this.width;
		material.uniforms.resolution.value.y = this.height;
		this.screen = new THREE.Mesh(geometry, material);
		this.screen.scale.x = this.width;
		this.screen.scale.y = this.height;

	}

	resize(){

		//
		this.width  = window.innerWidth;
		this.height = window.innerHeight;

		//
		if( this.camera ){
			this.camera.aspect = this.width / this.height;
			this.camera.position.z = getPxReScale(1440) * 1.2;
			this.camera.far = getPxReScale( this.fog.far * 1440 );
			// if( !DETECT.device.any ){
			// 	this.camera.position.z = getPxReScale(1440)*1.2;
			// 	this.camera.far = getPxReScale( this.fog.far * 1440 );
			// } else {
			// 	this.camera.position.z = this.width * 1.2;
			// 	this.camera.far        = this.fog.far * this.width;
			// }
			this.camera.updateProjectionMatrix();
		}

		//
		if( this.scene.fog ){
			this.scene.fog.near = getPxReScale( this.fog.near * 1440 );
			this.scene.fog.far  = getPxReScale( this.fog.far * 1440 );
			// if( !DETECT.device.any ){
			// 	this.scene.fog.near = getPxReScale( this.fog.near * 1440 );
			// 	this.scene.fog.far  = getPxReScale( this.fog.far * 1440 );
			// } else {
			// 	this.scene.fog.near = 0;
			// 	this.scene.fog.far  = this.fog.far * this.width;
			// }
		}

		//
		if( this.screen ){
			this.screen.scale.x = this.width;
			this.screen.scale.y = this.height;
			this.screen.material.uniforms.resolution.value.x = this.width;
			this.screen.material.uniforms.resolution.value.y = this.height;
		}

		//
		this.lights.forEach( (v,i) =>{
			if( v.mesh ){
				v.mesh.position.x = v.position.x;
				v.mesh.position.y = v.position.y;
				v.mesh.position.z = v.position.z;
				v.mesh.distance   = v.distance;
			}
		});

		// if( this.physics ){
		// 	this.physics.resizeTextArea();
		// }

	}

	inview( visible ){

		//
		this.lights.forEach( (v,i) =>{
			if( v.mesh ){
				v.mesh.visible = visible;
			}
		});

		//
		this.array.forEach( (v,i) =>{
			const name = v.geometry.name;
			const each = this.each[ name ];
			v.mesh.visible = ( visible && each.visible );
		});

	}

	update(){

		for (let i = 0; i < this.array.length; i++) {
			const v = this.array[i];
			const name = v.geometry.name;
			const each = this.each[ name ];
			if( v.mesh.visible ){
				const shader = v.mesh.material.userData.shader;
				// v.mesh.scale.x = each.scale * this.scale * v.origin.scale.rand * v.origin.scale.x * RESCALE.r * 2;
				// v.mesh.scale.y = each.scale * this.scale * v.origin.scale.rand * v.origin.scale.y * RESCALE.r * 2;
				// v.mesh.scale.z = each.scale * this.scale * v.origin.scale.rand * v.origin.scale.z * RESCALE.r * 2;

				v.mesh.scale.x = each.scale * this.PARTY_SCALE * v.origin.scale.x * 2;
				v.mesh.scale.y = each.scale * this.PARTY_SCALE * v.origin.scale.y * 2;
				v.mesh.scale.z = each.scale * this.PARTY_SCALE * v.origin.scale.z * 2;

				if( shader ){
					shader.uniforms.wavyAngle.value = v.origin.wavyAngle + BACKGL.rad.fast.val;
					// shader.uniforms.twistPower.value = v.origin.twistPower + tp;
					shader.uniforms.twistPower.value = v.origin.twistPower;
				}
			}
		}

		//==================================================
		// DEVICE DETECT
		//==================================================
		// if( DETECT.device.mobile ){
		// 	// this.eachGroup.position.y = stylePageScroll.body.y * 1.2;
		// } else if( DETECT.device.tablet ){
		// 	this.eachGroup.position.y = stylePageScroll.body.y * 1.5;
		// } else {
		// 	this.eachGroup.position.y = stylePageScroll.body.y * 3;
		// }
		if( !DETECT.device.any ){
			this.eachGroup.position.y = getPxReScale(stylePageScroll.body.y * 3);
		}


		this.setcolor();

	}

	reload(){
		if( this.physics ) this.physics.reload();
	}

	render(){

		//
		switch( ROUTE.current.type ){
			case 'index':
			case 'contact':
				this.update();
				if( this.physics ){
					this.physics.updatePhysics();
				}
			break;
			case 'notfound':
				this.update();
				if( this.physics ){
					this.physics.updatePhysics();
					// this.physics.updateTextArea();
				}
			break;
		}

	}

	setcolor(){

		if( this.scene.fog ){

			//
			this.scene.fog.color.r  = COLOR.partyBg.three.r;
			this.scene.fog.color.g  = COLOR.partyBg.three.g;
			this.scene.fog.color.b  = COLOR.partyBg.three.b;

			if( this.scene.background ){
				this.scene.background.r = COLOR.partyBg.three.r;
				this.scene.background.g = COLOR.partyBg.three.g;
				this.scene.background.b = COLOR.partyBg.three.b;
			}

			//
			for (let i = 0; i < this.lightGroup.children.length; i++) {
				let mesh = this.lightGroup.children[i];
				mesh.color.r = COLOR[ mesh.origin.name ].three.r;
				mesh.color.g = COLOR[ mesh.origin.name ].three.g;
				mesh.color.b = COLOR[ mesh.origin.name ].three.b;
				mesh.intensity = (mesh.origin.intensity * ROUTE.color.white);
				// mesh.intensity = (mesh.origin.intensity * ROUTE.pages.index.power) + (mesh.origin.intensity * ROUTE.pages.notfound.power);
			}

			//
			for (let i = 0; i < this.array.length; i++) {
				let mesh = this.array[i].mesh;
				mesh.material.color.r    = COLOR.partyModelColor.three.r;
				mesh.material.color.g    = COLOR.partyModelColor.three.g;
				mesh.material.color.b    = COLOR.partyModelColor.three.b;
				mesh.material.emissive.r = COLOR.partyModelEmissive.three.r;
				mesh.material.emissive.g = COLOR.partyModelEmissive.three.g;
				mesh.material.emissive.b = COLOR.partyModelEmissive.three.b;
				mesh.material.envMapIntensity = ( ROUTE.color.black );
			}

		}

	}

	resetTweens( v ){
		killTweens( v.anim.reset );
		killTweens( v.anim.show );
		killTweens( v.anim.hide );
	}

	slideToNext(){
		this.slider.prev.index = this.slider.now.index;
		this.slider.prev.key = this.key[ this.slider.prev.index ];
		if( this.slider.now.index < this.key.length-1 ){
			this.slider.now.index++;
		} else {
			this.slider.now.index = 0;
		}
		this.slider.now.key = this.key[ this.slider.now.index ];
		this.twist();
	}

	slideToPrev(){

		console.log('slideToPrev');

		//
		this.slider.prev.index = this.slider.now.index;
		this.slider.prev.key = this.key[ this.slider.prev.index ];

		//
		if( this.slider.now.index > 0 ){
			this.slider.now.index--;
		} else {
			this.slider.now.index = this.key.length - 1;
		}

		//
		this.slider.now.key = this.key[ this.slider.now.index ];
		this.twist();

	}

	twist(){

		// console.log('twist');

		// const _this = this;

		// physics
		// this.nextMeshs = [];

		//
		// var p = Math.min( Math.abs( cursorEvent.drag.progress/100 ), 3 );
		// var tp = cursorEvent.drag.progress > 0 ? p*-1 : p * 1;
		this.slider.progress = 0;
		// this.slider.progress = tp;

		//
		this.toToggle();

		// physics
		// setTimeout(function(){

		// _this.activeMeshs = _this.nextMeshs;
		// if(_this.activatePhysics){
		// 	_this.physics.switch( _this.activeMeshs );
		// }

		// },500);

	}

	toToggle(){

		console.log('SCENE_PARTY.toToggle()');

		const _this = this;
		let hide_index = 0;
		let show_index = 0;
		let hide  = [];
		let show  = [];

		//
		this.physics.showBodySetting();

		//
		this.array.forEach( ( v ) =>{

			const name = v.geometry.name;
			const each = this.each[ name ];

			//
			// var delay = v.origin.delay * 0.1;
			const twistPower = this.slider.progress < 0 ? this.twistPower : this.twistPower*-1;
			// const twistPower = this.twistPower;

			//
			this.resetTweens( v );

			//
			switch( v.geometry.name ){
				case this.slider.prev.key:
					// this.toHide( v, twistPower, -1, index );
					hide.push({
						v          : v,
						twistPower : twistPower,
						dir        : 1,
						index      : hide_index
					});
					hide_index++;
				break;
				case this.slider.now.key:
					// this.nextMeshs.push( v );
					// this.toShow( v, twistPower, -1, show_index );
					show.push({
						v          : v,
						twistPower : twistPower,
						dir        : -1,
						index      : hide_index
					});
					show_index++;
				break;
				default:
					v.mesh.visible = false;
					each.visible = false;
				break;
			}

		});

		show.forEach( ( _show , i ) =>{

			const _hide = hide[i];
			const _hide_name  = _hide.v.geometry.name;
			const _hide_each  = this.each[ _hide_name ];
			const _show_name  = _show.v.geometry.name;
			const _show_each  = this.each[ _show_name ];
			const delay = _hide.v.origin.delay * ANIM.party.twist.hide.d;

			//
			_hide.v.mesh.visible = true;
			_hide_each.visible = true;
			//
			_show.v.origin.scale.x = 0;
			_show.v.origin.scale.y = 0;
			_show.v.origin.scale.z = 0;
			const storeX = _show.v.mesh.position.x;
			_show.v.mesh.position.x = -100;
			_show.v.mesh.visible = true;
			_show_each.visible = true;

			//===============
			// TWIST
			//===============
			_hide.v.origin.twistPower = this.slider.progress;
			const tl1 = gsap.timeline();
			tl1.to( _hide.v.origin, {
				duration : ANIM.party.twist.hide.s,
				ease     : ANIM.party.twist.hide.e,
				twistPower : _hide.twistPower * _hide.dir * 0.25,
				delay : delay
			});
			tl1.set( _show.v.origin, {
				twistPower : _hide.twistPower * _show.dir * 0.5,
			});
			tl1.to( _show.v.origin, {
				duration : ANIM.party.twist.show.s,
				ease     : ANIM.party.twist.show.e,
				twistPower : 0,
			});
			_hide.v.anim.hide.push(tl1);

			//===============
			// FORCE
			//===============
			_hide.v.origin.twistPower = this.slider.progress;
			const tl2 = gsap.timeline();
			// tl.to( _hide.v.origin, {
			// 	duration : ANIM.party.twist.hide.s * 0.25,
			// 	ease : ANIM.party.twist.hide.e,
			// 	force : 0,
			// 	delay : delay
			// });
			tl2.set( _hide.v.origin, {
				force : 0,
			});
			tl2.set( _show.v.origin, {
				force : 0,
			});
			tl2.to( _show.v.origin, {
				duration : ANIM.party.twist.show.s * 2,
				ease     : ANIM.party.twist.show.e,
				force    : 1,
				delay    : delay
			});
			_hide.v.anim.hide.push(tl2);

			//===============
			// SCALE
			//===============
			const tl3 = gsap.timeline();
			tl3.to( _hide.v.origin.scale, {
				duration : ANIM.party.twist.hide.s,
				ease     : ANIM.party.twist.hide.e,
				x : 0, y : 0, z : 0,
				delay : delay,
				onComplete(){
					if( _this.physics ) _this.physics.switchByIndex( show[i].v, i );
					_hide.v.mesh.visible = false;
					_hide_each.visible = false;
					//
					_show.v.mesh.position.x = storeX;
				}
			});
			tl3.to( _show.v.origin.scale, {
				duration : ANIM.party.twist.show.s * 2,
				ease     : ANIM.party.twist.show.e,
				x : 1, y : 1, z : 1,
			});
			_hide.v.anim.hide.push(tl3);

		});

	}

	setHide( name = this.slider.now.key ){

		console.log('SCENE_PARTY.setHide()');

		//
		let hide_index = 0;
		let hide  = [];
		this.array.forEach( ( v ) =>{
			this.resetTweens( v );
			switch( v.geometry.name ){
				case name:
					hide.push({
						v          : v,
						twistPower : this.twistPower * -0.125,
						dir        : 1,
						index      : hide_index
					});
					hide_index++;
				break;
			}
		});

		//
		hide.forEach( ( _hide , i ) =>{
			gsap.set( _hide.v.origin.scale, {
				x : 0,
				y : 0,
				z : 0
			});
			gsap.set( _hide.v.origin, {
				twistPower : _hide.twistPower
			});
		});

	}

	setShow( name = this.slider.now.key ){

		console.log('SCENE_PARTY.setShow()');

		//
		let show_index = 0;
		let show  = [];
		this.array.forEach( ( v ) =>{
			this.resetTweens( v );
			switch( v.geometry.name ){
				case name:
					show.push({
						v          : v,
						twistPower : 0,
						dir        : 1,
						index      : show_index
					});
					show_index++;
				break;
			}
		});

		//
		show.forEach( ( _show , i ) =>{
			gsap.set( _show.v.origin.scale, {
				x : 1,
				y : 1,
				z : 1
			});
			gsap.set( _show.v.origin, {
				twistPower : _show.twistPower
			});
		});

	}

	onSplash( name = this.slider.now.key, _delay = 1 ){

		console.log('SCENE_PARTY.onSplash()');

		//
		let show_index = 0;
		let show  = [];
		this.array.forEach( ( v ) =>{
			switch( v.geometry.name ){
				case name:
					show.push({
						v          : v,
						twistPower : 0,
						dir        : 1,
						index      : show_index
					});
					show_index++;
				break;
			}
		});

		//
		show.forEach( ( _show , i ) =>{
			const delay = _show.v.origin.delay * ANIM.party.twist.hide.d;
			const tl1 = gsap.to( _show.v.origin.scale, {
				x : 1,
				y : 1,
				z : 1,
				duration : ANIM.party.twist.show.s * 2,
				ease     : ANIM.party.twist.show.e,
				delay    : delay + _delay
			});
			_show.v.anim.show.push(tl1);
			const tl2 = gsap.to( _show.v.origin, {
				twistPower : _show.twistPower,
				duration : ANIM.party.twist.show.s * 2,
				ease     : ANIM.party.twist.show.e,
				delay    : delay + _delay
			});
			_show.v.anim.show.push(tl2);
		});

	}

	toShow( name = this.slider.now.key ){

		console.log('SCENE_PARTY.toShow()');

		//
		let show_index = 0;
		let show  = [];
		this.array.forEach( ( v ) =>{
			switch( v.geometry.name ){
				case name:
					show.push({
						v          : v,
						twistPower : 0,
						dir        : 1,
						index      : show_index
					});
					show_index++;
				break;
			}
		});

		//
		show.forEach( ( _show , i ) =>{
			const delay = _show.v.origin.delay * ANIM.party.twist.hide.d;
			const tl1 = gsap.to( _show.v.origin.scale, {
				x : 1,
				y : 1,
				z : 1,
				duration : ANIM.party.twist.show.s * 2,
				ease     : ANIM.party.twist.show.e,
				delay    : delay
			});
			_show.v.anim.show.push(tl1);
			const tl2 = gsap.to( _show.v.origin, {
				twistPower : _show.twistPower,
				duration : ANIM.party.twist.show.s * 2,
				ease     : ANIM.party.twist.show.e,
				delay    : delay
			});
			_show.v.anim.show.push(tl2);
		});

	}

	// toHide( v, twistPower, dir ){

	// 	//===
	// 	var name = v.geometry.name;
	// 	var each = this.each[ name ];
	// 	v.mesh.visible = true;
	// 	each.visible = true;

	// 	//
	// 	const delay = v.origin.delay * ANIM.party.twist.hide.d;

	// 	//
	// 	v.origin.twistPower = this.slider.progress;
	// 	var tl = gsap.to( v.origin, {
	// 		duration : ANIM.party.twist.hide.s,
	// 		ease     : ANIM.party.twist.hide.e,
	// 		twistPower : twistPower * dir * 0.25,
	// 		delay : delay
	// 	});
	// 	v.anim.hide.push(tl);

	// 	//
	// 	var tl = gsap.to( v.origin.scale, {
	// 		duration : ANIM.party.twist.hide.s,
	// 		ease     : ANIM.party.twist.hide.e,
	// 		x : 0, y : 0, z : 0,
	// 		delay : delay,
	// 		onComplete(){
	// 			v.mesh.visible = false;
	// 			each.visible = false;
	// 		}
	// 	});
	// 	v.anim.hide.push(tl);

	// }

	// toShow( v, twistPower, dir, index ){

	// 	const _this = this;

	// 	//===
	// 	var name = v.geometry.name;
	// 	var each = this.each[ name ];
	// 	v.mesh.visible = true;
	// 	each.visible = true;

	// 	//
	// 	const delay = v.origin.delay * ANIM.party.twist.show.d;

	// 	//
	// 	v.origin.twistPower = twistPower * -0.25 * dir;
	// 	var tl = gsap.to( v.origin, {
	// 		duration : ANIM.party.twist.show.s,
	// 		ease     : ANIM.party.twist.show.e,
	// 		twistPower : 0,
	// 		delay : delay
	// 	});
	// 	v.anim.show.push(tl);

	// 	//
	// 	v.origin.scale.x = 0;
	// 	v.origin.scale.y = 0;
	// 	v.origin.scale.z = 0;
	// 	var tl = gsap.to( v.origin.scale, {
	// 		duration : ANIM.party.twist.show.s,
	// 		ease     : ANIM.party.twist.show.e,
	// 		x : 1, y : 1, z : 1,
	// 		delay : delay,
	// 		onStart(){
	// 			_this.physics.switchByIndex( v, index );
	// 		}
	// 	});
	// 	v.anim.show.push(tl);

	// }



}



