export default [
	{
		name : 'Hiroki Noma',
		post : 'Chief Executive Officer',
		animal : 'lizard',
		count : 0,
		link : "https://twitter.com/HirokiNOMA"
	},
	{
		name : 'Kenta Toshikura',
		post : 'Lead Designer / Developer',
		animal : 'rabbit',
		count : 0,
		link : "https://twitter.com/silkhat_7"
	},
	{
		name : 'Misato Daikuhara',
		post : 'Designer / Developer',
		animal : 'raccoon',
		count : 0,
		link : "https://twitter.com/misatodaiq"
	},
	{
		name : 'Mikiko Kikuoka',
		post : 'Designer / Developer',
		animal : 'cat',
		count : 0,
		link : "https://twitter.com/mikikokikuoka"
	},
	{
		name : 'Nobuaki Honma',
		post : 'Designer / Developer',
		animal : 'fox',
		count : 0,
		link : "https://twitter.com/NobuakiHonma"
	},
	{
		name : 'Natsuko Sakai',
		post : 'Project Manager',
		animal : 'goat',
		count : 0,
		link : "https://twitter.com/natsukosakai"
	},
	{
		name : 'Meguru Muramoto',
		post : 'Designer / Developer',
		animal : 'koala',
		count : 0,
		link : "https://twitter.com/_____1345726"
	}
]