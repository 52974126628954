/*
██████╗  ██████╗ ███╗   ███╗
██╔══██╗██╔═══██╗████╗ ████║
██║  ██║██║   ██║██╔████╔██║
██║  ██║██║   ██║██║╚██╔╝██║
██████╔╝╚██████╔╝██║ ╚═╝ ██║
╚═════╝  ╚═════╝ ╚═╝     ╚═╝ transition color
*/


const colors = [];
window.toColor = function( from, to, s, e ){
	if( DETECT.device.any && IS_REDUCED ) s = 0;
	if( from.r === to.r && from.g === to.g && from.b === to.b ) return false;
	if( s === 0 ){
		from.r = to.r;
		from.g = to.g;
		from.b = to.b;
	} else {
		const tl = gsap.to( from, {
			duration : s,
			ease : e,
			r : to.r,
			g : to.g,
			b : to.b,
		});
		colors.push( tl );
	}
}


ROUTE.onChangeGLColor = function( _s = ANIM.dom.leave.s, _e = ANIM.dom.leave.e ){
	killTweens( colors );
	switch( this.color.name ){
		case 'white':
			toColor( COLOR.background.three, COLOR_ORIGIN.lightBrown.three, _s, _e );
			toColor( COLOR.partyBg.three, COLOR_ORIGIN.lightBrown.three, _s, _e );
			toColor( COLOR.partyUpLight.three, COLOR_ORIGIN.lightOrange.three, _s, _e );
			toColor( COLOR.partyFrontLight.three, COLOR_ORIGIN.orange.three, _s, _e );
			toColor( COLOR.partyBackLight.three, COLOR_ORIGIN.deepOrange.three, _s, _e );
			toColor( COLOR.partyModelColor.three, COLOR_ORIGIN.black2.three, _s, _e );
			toColor( COLOR.partyModelEmissive.three, COLOR_ORIGIN.deepBrown.three, _s, _e );	
			toColor( COLOR.homeBlurColor.three, COLOR_ORIGIN.red.three, _s, _e );
		break;
		default:
			toColor( COLOR.background.three, COLOR_ORIGIN.black.three, _s, _e );
			toColor( COLOR.partyBg.three, COLOR_ORIGIN.black.three, _s, _e );
			toColor( COLOR.partyUpLight.three, COLOR_ORIGIN.black.three, _s, _e );
			toColor( COLOR.partyFrontLight.three, COLOR_ORIGIN.black.three, _s, _e );
			toColor( COLOR.partyBackLight.three, COLOR_ORIGIN.black.three, _s, _e );
			toColor( COLOR.partyModelColor.three, COLOR_ORIGIN.black2.three, _s, _e );
			toColor( COLOR.partyModelEmissive.three, COLOR_ORIGIN.black3.three, _s, _e );
			toColor( COLOR.homeBlurColor.three, COLOR_ORIGIN.white.three, _s, _e );
		break;
	}
}









